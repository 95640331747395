import "./input.scss";
import classnames from "classnames";
import { ReactComponent as IconEye } from "../../assets/IconEye.svg";
import { ReactComponent as IconClose } from "../../assets/close.svg";
import { ReactComponent as IconAlert } from "../../assets/IconAlert.svg";
import ReactTooltip from "react-tooltip";
import { useContext, useEffect, useRef, useState } from "react";
import MaskedInput from "react-text-mask";
import {
  CurrencyTypes,
  LocalizationContext,
  LocalizationTypes,
} from "../../context/localization";
import { strings } from "../../localizedStrings";
import { ReactComponent as SvgInfo } from "../../assets/IconInfo.svg";
import { ServicesContext } from "../../context/services";
import { IGender } from "../../services/v2/orders/orders.interface";
import { copyToClipboard } from "../../helpers/utils";
import { Notification } from "rsuite";
import { compact } from "lodash";

export interface IInputProps {
  label?: string;
  type?: string;
  value?: string;
  value_en?: string;
  multiLanguage?: boolean;
  customClass?: string;
  valid?: { valid: boolean; msg: string };
  validCheck?: boolean;
  multiline?: boolean;
  required?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  placeholder?: string;
  select?: boolean;
  preText?: string;
  selectOption?: string[];
  onChange?: (
    val: string,
    valid: { valid: boolean; msg: string },
    lang: "en" | "local",
    gender: IGender
  ) => void;
  errorType?: "tooltip" | "message";
  validate?: (val: string) => { valid: boolean; msg: string };
  showInfo?: boolean;
  infoText?: string;
  localizationDefault?: "en" | "local";
  limitLetters?: boolean;
  limit?: number;
  offset?: number;
  copy?: boolean;
  showGender?: boolean;
  currency?: CurrencyTypes;
  clean?: boolean;
  discountType?: "percentage" | "absolute";
  width?: string;
  errorBorder?: boolean;
  onSelectOptionChange?: (value: string, index: number) => void;
  userLanguage?: LocalizationTypes;
  onBlur?: () => void;
}

const Input = ({
  label = "",
  type = "text",
  autoComplete = "",
  preText = "",
  offset,
  autoFocus = false,
  placeholder,
  required = false,
  multiline = false,
  multiLanguage = false,
  select = false,
  selectOption = [],
  onSelectOptionChange = () => {},
  onChange = () => {},
  validate = () => ({ valid: true, msg: "" }),
  validCheck = true,
  value = "",
  value_en = "",
  valid = { valid: true, msg: "" },
  showInfo = false,
  infoText = "",
  errorType = "tooltip",
  customClass,
  localizationDefault = "local",
  limitLetters = false,
  limit = 0,
  errorBorder = false,
  copy = false,
  showGender = false,
  currency,
  clean = false,
  discountType = "percentage",
  width,
  userLanguage,
  onBlur = () => {},
}: IInputProps) => {
  const [validObj, setValid] = useState({ valid: true, msg: "" });
  const [localization, setLang] = useState<"en" | "local">(localizationDefault);
  const { configService } = useContext(ServicesContext);
  const { country } = useContext(LocalizationContext);

  const [gender, setGender] = useState<IGender>("male");
  const [currSelectOption, setCurrSelectOption] = useState(
    selectOption[0] ? selectOption[0] : ""
  );
  const [showPassword, setShowPassword] = useState(false);
  const { isRTL } = useContext(LocalizationContext);
  const htmlElRef = useRef(null);

  const innerOnChange = (
    val: string,
    valid: { valid: boolean; msg: string },
    lang: "en" | "local",
    gender: IGender
  ) => {
    if (limitLetters) {
      if (val.length > limit) {
        return;
      } else {
        onChange(val, valid, lang, gender);
      }
    } else {
      onChange(val, valid, lang, gender);
    }
  };

  useEffect(() => {
    if (autoFocus) {
      htmlElRef.current && (htmlElRef!.current as any)!.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    if (validCheck) {
      setValid(valid);
    }
  }, [valid, validCheck]);

  useEffect(() => {
    if (!valid.valid && htmlElRef.current) {
      htmlElRef.current &&
        (htmlElRef!.current as any)!.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      setTimeout(() => {
        window.scrollBy(0, -200);
      }, 500);
    }
  }, [valid]);

  const Required = ({ required }: { required: boolean }) => {
    return required ? <div className="label-required">{"*"}</div> : <></>;
  };

  const CopyLabel = ({ show }: { show: boolean }) => {
    return show ? (
      <div
        onClick={() => {
          copyToClipboard(preText + value);
          Notification.open({
            title: "",
            duration: 1000,
            placement: isRTL ? "bottomEnd" : "bottomStart",
            description: <div>{strings.linkCopy}</div>,
          });
        }}
        className={classnames("copy-container", { ltr: !isRTL })}
      >
        {strings.copyLink}
      </div>
    ) : (
      <></>
    );
  };

  const MultiLanguage = ({ show }: { show: boolean }) => {
    if (!show) {
      return <></>;
    }
    return (
      <div className={classnames("lang-container", { ltr: !isRTL })}>
        <div
          className={classnames("option", {
            active: localization === "en",
          })}
          onClick={() => {
            setLang("en");
          }}
        >
          {strings.english}
        </div>
        <div
          className={classnames("option", {
            active: localization !== "en",
          })}
          onClick={() => {
            setLang("local");
          }}
        >
          {configService.getLocalLanguage((userLanguage as any) || country)}
        </div>
        <div className="routes-mark-container">
          {true ? (
            <div
              className="mark"
              style={{
                marginLeft: isRTL
                  ? 8 + (localization === "en" ? 0 : 1) * (offset ? offset : 50)
                  : 0,
                marginRight: !isRTL
                  ? 10 +
                    (localization === "en" ? 1 : 0) * (offset ? offset : 65)
                  : 0,
              }}
            ></div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const Gender = ({ show }: { show: boolean }) => {
    if (!show) {
      return <></>;
    }

    return (
      <div className={classnames("lang-container", { ltr: !isRTL })}>
        <div
          className={classnames("option", {
            active: gender === "female",
          })}
          onBlur={onBlur}
          onClick={() => {
            setGender("female");
            innerOnChange(value, valid, localization, "female");
          }}
        >
          {strings.female}
        </div>
        <div
          className={classnames("option", {
            active: gender === "male",
          })}
          onBlur={onBlur}
          onClick={() => {
            setGender("male");
            innerOnChange(value, valid, localization, "male");
          }}
        >
          {strings.male}
        </div>
        <div className="routes-mark-container">
          {true ? (
            <div
              className="mark"
              style={{
                marginLeft: isRTL
                  ? 8 + (gender === "female" ? 0 : 1) * (offset ? offset : 50)
                  : 0,
                marginRight: !isRTL
                  ? 12 + (gender === "female" ? 1 : 0) * (offset ? offset : 59)
                  : 0,
              }}
            ></div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const Select = ({ show }: { show: boolean }) => {
    if (!show) {
      return <></>;
    }

    return (
      <div className={classnames("lang-container", { ltr: !isRTL })}>
        <div
          className={classnames("option", {
            active: selectOption[0] === currSelectOption,
          })}
          onClick={() => {
            setCurrSelectOption(selectOption[0]);
            onSelectOptionChange(selectOption[0], 0);
          }}
        >
          {selectOption[0]}
        </div>
        <div
          className={classnames("option", {
            active: selectOption[1] === currSelectOption,
          })}
          onClick={() => {
            setCurrSelectOption(selectOption[1]);
            onSelectOptionChange(selectOption[1], 1);
          }}
        >
          {selectOption[1]}
        </div>
        <div className="routes-mark-container">
          {true ? (
            <div
              className="mark"
              style={{
                marginLeft: isRTL
                  ? 8 +
                    (currSelectOption === selectOption[0] ? 0 : 1) *
                      (offset ? offset : 65)
                  : 0,
                marginRight: !isRTL
                  ? 10 +
                    (currSelectOption === selectOption[1] ? 0 : 1) *
                      (offset ? offset : 60)
                  : 0,
              }}
            ></div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={classnames("input-container", customClass, {
        ltr: !isRTL,
        error: !validObj.valid && errorType === "message",
      })}
    >
      <div className="label-container">
        {!isRTL && <Required required={required} />}
        <label htmlFor={autoComplete} className="label">
          {label}
        </label>
        {isRTL && <Required required={required} />}
        <CopyLabel show={type === "url" && copy} />
        <MultiLanguage show={multiLanguage && userLanguage !== "en"} />
        <Gender show={type === "name" && showGender} />
        <Select show={select && !!selectOption[0] && !!selectOption[1]} />
      </div>
      <div
        className={classnames("input", {
          error: !validObj.valid,
          "error-border": !validObj.valid && errorBorder,
        })}
      >
        {type === "month_number" ? (
          <MaskedInput
            style={{ direction: "ltr" }}
            mask={[/\d/, /\d/]}
            value={value}
            guide={false}
            id={autoComplete}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            name={autoComplete}
            onBlur={onBlur}
            placeholder={placeholder}
            onChange={(event: { target: { value: string } }) => {
              const valid = validate(event.target.value);
              setValid(valid);
              innerOnChange(event.target.value, valid, localization, gender);
            }}
            type={type}
          />
        ) : type === "cvv" ? (
          <MaskedInput
            style={{ direction: "ltr" }}
            mask={[/\d/, /\d/, /\d/]}
            value={value}
            id={"cc-csc"}
            autoFocus={autoFocus}
            guide={false}
            autoComplete="cc-csc"
            name={"cc-csc"}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={(event: { target: { value: string } }) => {
              const valid = validate(event.target.value);
              setValid(valid);
              innerOnChange(event.target.value, valid, localization, gender);
            }}
            type={type}
          />
        ) : type === "birth-date" ? (
          <MaskedInput
            style={{ direction: "ltr" }}
            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            value={value}
            guide={false}
            autoFocus={autoFocus}
            id={autoComplete}
            autoComplete={autoComplete}
            name={autoComplete}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={(event: { target: { value: string } }) => {
              const valid = validate(event.target.value);
              setValid(valid);
              innerOnChange(event.target.value, valid, localization, gender);
            }}
            type={type}
          />
        ) : type === "year" ? (
          <MaskedInput
            style={{ direction: "ltr" }}
            mask={[/\d/, /\d/]}
            value={value}
            guide={false}
            autoFocus={autoFocus}
            id={autoComplete}
            autoComplete={autoComplete}
            name={autoComplete}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={(event: { target: { value: string } }) => {
              const valid = validate(event.target.value);
              setValid(valid);
              innerOnChange(event.target.value, valid, localization, gender);
            }}
            type={type}
          />
        ) : type === "id_number" ? (
          <MaskedInput
            style={{ direction: "ltr" }}
            mask={[/\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            value={value}
            autoFocus={autoFocus}
            guide={false}
            id={autoComplete}
            autoComplete={autoComplete}
            name={autoComplete}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={(event: { target: { value: string } }) => {
              const valid = validate(event.target.value);
              setValid(valid);
              innerOnChange(event.target.value, valid, localization, gender);
            }}
            type={type}
          />
        ) : type === "credit" ? (
          <MaskedInput
            style={{ direction: "ltr" }}
            mask={[
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            value={value}
            autoComplete="cc-number"
            name={"cc-number"}
            guide={false}
            autoFocus={autoFocus}
            id={"cc-number"}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={(event: { target: { value: string } }) => {
              const valid = validate(event.target.value);
              setValid(valid);
              innerOnChange(event.target.value, valid, localization, gender);
            }}
            type={type}
          />
        ) : type === "url" ? (
          <div className="url-container">
            <div className="url-pre-text">{preText}</div>
            <input
              value={`${localization === "en" ? value_en : value}`}
              placeholder={placeholder}
              autoComplete={autoComplete}
              name={autoComplete}
              id={autoComplete}
              ref={htmlElRef}
              autoFocus={autoFocus}
              style={{ width: width }}
              onBlur={onBlur}
              onChange={(event) => {
                const valid = validate(event.target.value);
                setValid(valid);
                innerOnChange(event.target.value, valid, localization, gender);
              }}
              type={"text"}
            />
          </div>
        ) : type === "phone" ? (
          <input
            style={{ direction: "ltr" }}
            value={localization === "en" ? value_en : value}
            placeholder={placeholder}
            autoComplete={autoComplete}
            name={autoComplete}
            autoFocus={autoFocus}
            id={autoComplete}
            onBlur={onBlur}
            onChange={(event: { target: { value: string } }) => {
              const valid = validate(event.target.value);
              setValid(valid);
              innerOnChange(event.target.value, valid, localization, gender);
            }}
            type={type}
          />
        ) : type === "price" ? (
          <>
            <input
              type="number"
              value={localization === "en" ? value_en : value}
              placeholder={placeholder}
              autoComplete={autoComplete}
              name={autoComplete}
              className={"price no-spin"}
              autoFocus={autoFocus}
              ref={htmlElRef}
              id={autoComplete}
              onBlur={onBlur}
              style={{
                width: "100%",
                direction: "ltr",
              }}
              onChange={(event: { target: { value: string } }) => {
                const valid = validate(event.target.value);
                setValid(valid);
                innerOnChange(event.target.value, valid, localization, gender);
              }}
              min={1}
            />
            {currency ? (
              <div className="price-symbol">
                {configService.getCurrencySign(currency)}
              </div>
            ) : (
              <></>
            )}
          </>
        ) : type === "discount" ? (
          <>
            <input
              type="number"
              value={localization === "en" ? value_en : value}
              placeholder={placeholder}
              autoComplete={autoComplete}
              name={autoComplete}
              className={"price no-spin"}
              autoFocus={autoFocus}
              ref={htmlElRef}
              onBlur={onBlur}
              id={autoComplete}
              style={{
                width: "100%",
                direction: "ltr",
              }}
              onChange={(event: { target: { value: string } }) => {
                const valid = validate(event.target.value);
                setValid(valid);
                innerOnChange(event.target.value, valid, localization, gender);
              }}
              min={1}
            />
            {currency ? (
              <div className="price-symbol">
                {discountType === "absolute"
                  ? configService.getCurrencySign(currency)
                  : "%"}
              </div>
            ) : (
              <></>
            )}
          </>
        ) : !multiline ? (
          <>
            <input
              value={`${localization === "en" ? value_en : value}`}
              placeholder={placeholder}
              autoComplete={autoComplete}
              name={autoComplete}
              id={autoComplete}
              ref={htmlElRef}
              autoFocus={autoFocus}
              onBlur={onBlur}
              style={{
                width: type === "password" ? "85%" : showInfo ? "80%" : "100%",
              }}
              onChange={(event) => {
                const valid = validate(event.target.value);
                setValid(valid);
                innerOnChange(event.target.value, valid, localization, gender);
              }}
              type={showPassword && type === "password" ? "text" : type}
            />
          </>
        ) : (
          <textarea
            value={localization === "en" ? value_en : value}
            placeholder={placeholder}
            autoComplete={autoComplete}
            id={autoComplete}
            autoFocus
            onBlur={onBlur}
            name={autoComplete}
            onChange={(event) => {
              const valid = validate(event.target.value);
              setValid(valid);
              innerOnChange(event.target.value, valid, localization, gender);
            }}
          />
        )}

        <div
          className="error-container-inline"
          style={{
            width:
              compact([
                !validObj.valid,
                showInfo,
                type === "password",
                clean && (value.length || value_en.length),
              ]).length *
                28 +
              "px",
          }}
        >
          <ReactTooltip
            place="bottom"
            arrowColor={"transparent"}
            backgroundColor="#D1CECE"
            textColor="#222222"
            className="tooltip"
          ></ReactTooltip>
          <IconAlert
            className={classnames(isRTL ? "alert" : "alertLtr", {
              show: !validObj.valid,
            })}
            data-tip={validObj.msg}
            width="26px"
            height="26px"
            color="#d1cece"
          />
          <SvgInfo
            className={classnames(isRTL ? "alert" : "alertLtr", {
              show: showInfo,
            })}
            data-tip={infoText}
            width="26px"
            height="26px"
            color="#d1cece"
          />
          <IconEye
            className={classnames(isRTL ? "alert" : "alertLtr", {
              show: type === "password",
            })}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            width="26px"
            height="26px"
            color="#d1cece"
          />
          <IconClose
            className={classnames(isRTL ? "alert" : "alertLtr", {
              show: clean && (value.length || value_en.length),
            })}
            onBlur={onBlur}
            onClick={() => {
              innerOnChange("", valid, localization, gender);
            }}
            width="14px"
            height="14px"
            color="#d1cece"
          />
        </div>
        {limitLetters ? (
          <div
            className={classnames("letter-limit-container", {
              ltr: !isRTL,
              "in-line": !multiline,
            })}
          >{`${
            (localization === "en" ? value_en : value)?.length || 0
          }/${limit}`}</div>
        ) : (
          <></>
        )}
      </div>
      <div className="error-container">
        {!validObj.valid && errorType === "message" ? (
          <label className="error-info">{`* ${validObj.msg}`}</label>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Input;
