import { strings } from "../../localizedStrings";
import * as CompanyEmailValidator from "company-email-validator";

import AuthenticationFirebaseService from "../../services/v1/authentication.firebase";
import { ModeType } from "./authentication.interface";

export const validEmail = (value: string) => {
  if (!value.length) {
    return { valid: true, msg: "" };
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return {
    valid: re.test(String(value).toLowerCase()),
    msg: strings.emailMsg,
  };
};

export const validBusinessEmail = (value: string) => {
  if (!value.length) {
    return { valid: true, msg: "" };
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (
    re.test(String(value).toLowerCase()) &&
    !CompanyEmailValidator.isCompanyEmail(value)
  ) {
    return {
      valid: false,
      msg: strings.emailBusinessMsg,
    };
  }

  return {
    valid: re.test(String(value).toLowerCase()),
    msg: strings.emailMsg,
  };
};

export const validName = (value: string) => {
  if (value && !value.length && value.trim().length) {
    return { valid: true, msg: "" };
  }
  return {
    valid: !value || value.length > 1,
    msg: strings.nameMsg,
  };
};

export const validUserName = (value: string) => {
  if (value && !value.length && value.trim().length) {
    return { valid: true, msg: "" };
  }
  return {
    valid: !value || /^[a-z][a-z0-9]*$/i.test(value),
    msg: strings.usernameMsg,
  };
};

export const validUrl = (value: string) => {
  return { valid: true, msg: "" };
};

export const validPassword = (value: string) => {
  if (!value.length) {
    return {
      valid: true,
      msg: "",
    };
  }

  return {
    valid: value.length > 5,
    msg: strings.passwordNotValid,
  };
};

export const submitSocial = async (
  authenticationService: AuthenticationFirebaseService,
  provider: string
) => {
  await authenticationService.socialSignUp(provider);
  return true;
};

export const submitEmail = async (
  authenticationService: AuthenticationFirebaseService,
  form: {
    [input: string]: { value: string; valid: { valid: boolean; msg: string } };
  },
  mode: ModeType
) => {
  const formValues = Object.keys(form).reduce((formValues, key) => {
    formValues[key] = form[key].value;
    return formValues;
  }, {} as { [input: string]: string });
  if (mode === "signUp") {
    const response = await authenticationService.signUp(
      formValues as { email: string; password: string; name?: string }
    );
    if (response.code === "auth/email-already-in-use") {
      return {
        form: {
          ...form,
          email: {
            value: form.email.value,
            valid: { valid: false, msg: strings.userExistError },
          },
        },
        afterSuccess: "userExist",
        value: response,
      };
    }
    return {
      form: form,
      afterSuccess: "emailConfig",
      value: response,
    };
  } else {
    const response = await authenticationService.signIn(
      formValues as { email: string; password: string; name: string }
    );    
    if (response.code === "auth/user-not-found") {
      return {
        form: {
          ...form,
          email: {
            value: form.email.value,
            valid: { valid: false, msg: strings.userNotExist },
          },
        },
        afterSuccess: "userNotFound",
        value: response,
      };
    } else if (response.code === "auth/wrong-password") {
      return {
        form: {
          ...form,
          password: {
            value: form.password.value,
            valid: { valid: false, msg: strings.wrongPassword },
          },
        },
        afterSuccess: "userNotFound",
        value: response,
      };
    } else if (response?.user) {
      return {
        form: form,
        afterSuccess: "login",
        value: response,
      };
    }
    return { form: form, afterSuccess: "", value: {} };
  }
};
