export const awsConfig = {
  Auth: {
    userPoolId: "eu-west-1_sRnNCPz06",
    region: "eu-west-1",
    identityPoolId: "eu-west-1:62068308-b5fb-4a2b-8b34-ee5038d2046f",
    userPoolWebClientId: "4lq9jvd9sqqiesj0mtmp2tprk2",
    mandatorySignIn: false,

    oauth: {
      domain: "letstok-dev.auth.eu-west-1.amazoncognito.com",
      // scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: "https://www.letstok.com",
      redirectSignOut: "https://www.letstok.com",
      responseType: "token",
    },
  },
  social: {
    FB: "1007292433120621",
  },
  Storage: {
    AWSS3: {
      bucket: "letstok-social-media-dev",
      region: "eu-west-1",
    },
  },
};
