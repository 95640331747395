import "./dotMenu.scss";
import { ReactComponent as IconDot } from "../../assets/IconDot.svg";
import { cloneElement, useRef, useState } from "react";
import classNames from "classnames";
import { useOnClickOutside } from "../../helpers/clickOutside";

const DotMenu = ({
  options = [],
}: {
  options: { name: string; icon: any; onClick: () => void }[];
}) => {
  const [dropDownClass, setDropDownClass] = useState<string>("mount");
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside([ref], () => {
    if (dropDownClass === "open") {
      setDropDownClass("close");
    }
  });

  return (
    <div
      className="dot-menu"
      ref={ref}
      onClick={(e) => {
        setDropDownClass(dropDownClass !== "open" ? "open" : "close");
        e.stopPropagation();
      }}
    >
      <IconDot className="dot" />
      <div className={classNames("side-panel", dropDownClass)}>
        {options.map((item) => {
          return (
            <div className="option" onClick={item.onClick}>
              {cloneElement(item.icon)}
              <div className="option-title">{item.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DotMenu;
