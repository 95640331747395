import classnames from "classnames";
import { FC, useState } from "react";
import SearchInput from "../../../../components/SearchInput/searchInput";
import TalentsOrderTable from "../../../../components/TalentsOrderTable/talentsOrderTable";
import "./postsPage.scss";
import { ReactComponent as ListSvg } from "../../../../assets/IconList2.svg";
import { ReactComponent as GridSvg } from "../../../../assets/IconGrid.svg";


export interface IPostsPageProps {
  show: boolean;
}

const PostsPage: FC<IPostsPageProps> = ({ show }) => {
  const [mode, setMode] = useState<'list' | 'grid'>('list')

  return (
    <>

      <div className="top-container">
        <SearchInput
          onChange={(input) => {
            // setInput(input);
          }}
          placeholder={''}
        />
        <div className="top-container-right" onClick={() => {
          setMode(mode === 'list' ? 'grid' : 'list')
        }}>

          {mode === 'list' ? <GridSvg width={"32px"} /> : <ListSvg width={"32px"} />}
        </div>
      </div>
      <div
        className={classnames("posts-page-container", {
          display: show,
        })}
      >
        {mode === 'list' ? <TalentsOrderTable order={[]} /> :<></>}
      </div>
    </>
  );
};

export default PostsPage;
