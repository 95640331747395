import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import LogRocket from "logrocket";
import { initTalents } from "../services/v1/talents";
import axios from "axios";
import { ServicesContext } from "./services";
import { compact, flatten, isEmpty, uniqBy } from "lodash";
import { LocalizationContext } from "./localization";
import {
  IBanners,
  ICategory,
  IHomeResponse,
} from "../services/v2/newsFeed/newsFeed.interfaces";
import { IProfile, UserRole } from "../services/v2/users/users.interface";

export interface IApplicationContext {
  profile: IProfile | {};
  categories: ICategory[];
  setCategories: (categories: ICategory[]) => any;
  talentSignUpModel: boolean;
  devEnvironment: boolean;
  setTalentSignUpModel: (val: boolean) => void;
  setProfile: (profile: IProfile) => void;
  homeTalents: IHomeResponse[];
  fetchMoreToCategories: (id: number) => Promise<void>;
  loading: boolean;
  setLoading: (state: boolean) => void;
  banners: IBanners[];
  notificationCount: number;
  setNotificationCount: (val: number) => void;
  mode: UserRole;
  setMode: (mode: UserRole) => void;
  categoriesAll: ICategory[];
  setAllCategories: (categories: ICategory[]) => any;
}

export const ApplicationContext = createContext<IApplicationContext>({
  profile: {},
  setProfile: () => {},
  devEnvironment: false,
  talentSignUpModel: false,
  setTalentSignUpModel: () => {},
  categories: [],
  categoriesAll: [],
  setCategories: () => {},
  setAllCategories: () => {},

  homeTalents: [],
  fetchMoreToCategories: async () => {},
  loading: false,
  setLoading: () => {},
  banners: [],
  notificationCount: 0,
  setNotificationCount: () => {},
  mode: "user",
  setMode: () => {},
});

export interface IApplicationContextProps {
  children: any;
}

export const ApplicationProvider = ({ children }: IApplicationContextProps) => {
  const { newsFeedService } = useContext(ServicesContext);
  const { localization, country, currency } = useContext(LocalizationContext);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [mode, setMode] = useState<UserRole>("user");
  const [talentSignUpModel, setTalentSignUpModel] = useState<boolean>(false);
  const [devEnvironment] = useState<boolean>(true);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [categoriesAll, setAllCategories] = useState<ICategory[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<IProfile | {}>({});
  const [homeTalents, setHomeTalent] = useState<IHomeResponse[]>(initTalents());
  const [innerTalents, setInnerTalent] = useState<IHomeResponse[]>([]);
  const [banners] = useState<IBanners[]>([]);
  const [pagingPerCategory, setPagingPerCategory] = useState<{
    [key: string]: { pages: number[]; page: number; hasNext: boolean };
  }>({});

  const increaseCategoriesPaging = useCallback(
    (page: { [key: number]: number }, result: IHomeResponse[]) => {
      setPagingPerCategory(
        categories.reduce((categoryReq, category) => {
          if (page[category.id]) {
            categoryReq[category.id] = {
              hasNext:
                result.find((item) => item.category_id === category.id)
                  ?.hasNext || false,
              page: page[category.id],
              pages: [
                ...(categoryReq[category.id]?.pages || []),
                page[category.id],
              ],
            };
          }

          return categoryReq;
        }, {} as { [key: string]: { pages: number[]; page: number; hasNext: boolean } })
      );
    },
    [categories]
  );

  // useEffect(() => {
  //   const cancelToken = axios.CancelToken.source();
  //   if (!isEmpty(categories)) {
  //     setPagingPerCategory({});
  //     setHomeTalent(initTalents());
  //     Promise.all([
  //       newsFeedService.homePage(
  //         {
  //           language: localization,
  //           country,
  //           currency,
  //         },
  //         categories.reduce((categoryReq, category) => {
  //           categoryReq[category.id] = { size: 5, page: 1 };
  //           return categoryReq;
  //         }, {} as { [key: string]: { size: number; page: number } }),
  //         categories,
  //         cancelToken
  //       ),
  //       newsFeedService.homePage(
  //         {
  //           language: localization,
  //           country,
  //           currency,
  //         },
  //         categories.reduce((categoryReq, category) => {
  //           categoryReq[category.id] = { size: 5, page: 2 };
  //           return categoryReq;
  //         }, {} as { [key: string]: { size: number; page: number } }),
  //         categories,
  //         cancelToken
  //       ),
  //     ]).then((result) => {
  //       const flattenResult = flatten(result).reduce((categories, category) => {
  //         const index = categories.findIndex(
  //           (item) => item.category_id === category.category_id
  //         );
  //         if (index === -1) {
  //           categories.push(category);
  //         } else {
  //           categories[index].talents = categories[index].talents.concat(
  //             category.talents
  //           );
  //         }
  //         return categories;
  //       }, [] as IHomeResponse[]);
  //       increaseCategoriesPaging(
  //         (categories || []).reduce((paging, category) => {
  //           paging[category.id] = 1;
  //           return paging;
  //         }, {} as { [key: number]: number }),
  //         flattenResult
  //       );
  //       setInnerTalent(flattenResult);
  //     });
  //   }
  //   return () => {
  //     cancelToken.cancel();
  //   };
  // }, [
  //   categories,
  //   country,
  //   currency,
  //   increaseCategoriesPaging,
  //   localization,
  //   newsFeedService,
  // ]);

  const fetchMoreToCategories = useCallback(
    async (id: number) => {
      const cancelToken = axios.CancelToken.source();
      if (pagingPerCategory[id] && pagingPerCategory[id].hasNext) {
        newsFeedService
          .homePage(
            {
              language: localization,
              country,
              currency,
            },
            { [id]: { size: 10, page: pagingPerCategory[id].page + 1 } },
            categories,
            cancelToken
          )
          .then((result) => {
            const categorisesIds = result.map(
              (category) => category.category_id
            );
            const mergeTalents = categorisesIds.reduce(
              (talentsPerCategories, categoryId) => {
                const indexOfCategory = talentsPerCategories.findIndex(
                  (category) => category?.category_id === categoryId
                );
                const indexOfCategoryResult = result.findIndex(
                  (category) => category?.category_id === categoryId
                );
                talentsPerCategories[categoryId] = {
                  ...result[indexOfCategoryResult],
                  talents: talentsPerCategories[indexOfCategory]
                    ? talentsPerCategories[indexOfCategory].talents.concat(
                        result[indexOfCategoryResult].talents
                      )
                    : result[indexOfCategoryResult].talents,
                };
                return talentsPerCategories;
              },
              innerTalents
            );
            increaseCategoriesPaging(
              { [id]: pagingPerCategory[id].page + 1 },
              result
            );
            setInnerTalent(compact([...mergeTalents]));
          });
      }
    },
    [
      categories,
      country,
      currency,
      increaseCategoriesPaging,
      innerTalents,
      localization,
      newsFeedService,
      pagingPerCategory,
    ]
  );

  useEffect(() => {
    if (innerTalents) {
      const favorites = ((profile as IProfile).favorites || []).map(
        (favorites: IProfile) => {
          return favorites.userId;
        }
      );
      newsFeedService.orderHomePage(innerTalents, favorites).then((talents) => {
        if (!isEmpty(talents)) {
          setHomeTalent(
            uniqBy(talents, "category_id").sort((a, b) => {
              return (
                (parseInt(a.category_order as any) || 999) -
                (parseInt(b.category_order as any) || 999)
              );
            })
          );
        }
      });
    }
  }, [innerTalents, newsFeedService, profile]);

  useEffect(() => {
    if (profile && (profile as IProfile)!.userId) {
      LogRocket.identify((profile as IProfile)!.userId, {
        name: (profile as IProfile)!.fullName,
        email: (profile as IProfile)!.username,
      });
    }
  }, [profile]);

  return (
    <ApplicationContext.Provider
      value={{
        categoriesAll,
        setAllCategories,
        setMode,
        setNotificationCount,
        notificationCount,
        devEnvironment,
        homeTalents,
        profile,
        setProfile: (obj:any) => {
          setProfile({ ...obj ,phone:obj.phoneNumber});
        },
        setCategories,
        categories,
        talentSignUpModel,
        setTalentSignUpModel,
        fetchMoreToCategories,
        loading,
        setLoading,
        banners,
        mode,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
