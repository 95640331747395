import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ApplicationContext } from "./application";
import { LocalizationContext } from "./localization";
import { ServicesContext } from "./services";

export interface IAuthenticationContext {
  isLogin: boolean;
  showLoginModal: boolean;
  setShowLoginModal: (state: boolean) => void;
  onLogin: () => Promise<void>;
  logout: () => void;
  firebaseInfo: any;
  setTalentSignUp: (state: boolean) => void;
  businessSignUp: { show: boolean; businessName: string };
  setBusinessSignUp: (state: {
    businessName: string;
    phone: string | null;
    show: boolean;
  }) => void;
}

export const AuthenticationContext = createContext<IAuthenticationContext>({
  isLogin: false,
  showLoginModal: false,
  setShowLoginModal: () => {},
  onLogin: async () => {},
  logout: () => {},
  firebaseInfo: {},
  setTalentSignUp: () => {},
  businessSignUp: { businessName: "", show: false },
  setBusinessSignUp: () => {},
});

export interface IAuthenticationContextProps {
  children: any;
}

export const AuthenticationProvider = ({
  children,
}: IAuthenticationContextProps) => {
  const {
    authenticationFirebaseService,
    usersService,
    organizationsService,
    setToken: setServiceToken,
  } = useContext(ServicesContext);
  const { setProfile, setTalentSignUpModel } = useContext(ApplicationContext);
  const { setError } = useContext(ServicesContext);
  const [isLogin, setLogin] = useState(false);
  const { localization, currency, country } = useContext(LocalizationContext);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [session, setSession] = useState<any | null>(null);
  const [info, setInfo] = useState<any | null>(null);
  const [token, setToken] = useState<string>("");
  const [errorLogin, setErrorLogin] = useState(false);
  const [talentSignUp, setTalentSignUp] = useState<boolean>(false);
  const [businessSignUp, setBusinessSignUp] = useState<{
    show: boolean;
    businessName: string;
    phone: string | null;
  }>({
    show: false,
    businessName: "",
    phone: null,
  });

  const [firebaseInfo] = useState<any>();

  useEffect(() => {
    authenticationFirebaseService.init();
  }, [authenticationFirebaseService]);

  useEffect(() => {
    if (!isLogin) {
      setTalentSignUp(false);
    }
  }, [isLogin]);

  authenticationFirebaseService.setAuthChanged(async (payload) => {
    if (payload?.uid) {
      const session = payload;
      const [info] = payload.providerData;
      setInfo({
        ...info,
        uid: payload.uid,
        email: info?.email || payload.email,
      });
      setSession(session);
    }
  });

  const logout = useCallback(async () => {
    await authenticationFirebaseService.auth().signOut();
    setTimeout(() => {
      setServiceToken("");
      setSession(null);
      setInfo(null);
      setToken("");
      setProfile({} as any);
      setLogin(false);
      window.location.reload();
    }, 5);
  }, [authenticationFirebaseService, setProfile, setServiceToken]);

  const onLogin = async () => {
    const session = authenticationFirebaseService.auth().currentUser;
    if (session && session?.providerData) {
      setInfo({
        ...session.providerData[0],
        email: info?.email || session.email,
        uid: session.uid,
      });
      setSession(session);
    }
  };

  useEffect(() => {
    (async () => {
      const session = authenticationFirebaseService.auth().currentUser;
      if (session && session?.providerData) {
        setInfo({
          ...session.providerData[0],
          email: session.providerData[0]?.email || session.email,
          uid: session.uid,
        });
        setSession(session);
      }
    })();
  }, [authenticationFirebaseService]);

  useEffect(() => {
    (async () => {
      if (session && session.getIdToken()) {
        const tokenRes = await session?.getIdToken().catch((e: any) => {
          console.log(e);
        });
        if (tokenRes) {
          setToken(tokenRes.toString());
        }
      }
    })();
  }, [session]);

  useEffect(() => {
    setServiceToken(token);
    localStorage.setItem("token", token);
  }, [setServiceToken, token]);

  useEffect(() => {
    (async () => {
      if (errorLogin) {
        return;
      }
      if (token) {
        setServiceToken(token);
        const signInReq = await usersService.sso(
          {
            language: localization,
            currency: currency,
            country: country,
          },
          talentSignUp ? "talent" : "talent"
        );
        if (businessSignUp.show) {
          await organizationsService.createOrganization(
            {
              language: localization,
              currency: currency,
              country: country,
            },
            businessSignUp.businessName
          );
          if (businessSignUp.phone) {
            await usersService.updateProfile(
              {
                language: localization,
                currency: currency,
                country: country,
              },
              { phone: businessSignUp.phone },
              null as any
            );
          }
          setBusinessSignUp({ show: false, businessName: "", phone: null });
        }
        if (signInReq.ok && signInReq.profile) {
          setProfile({ ...signInReq.profile, ...signInReq.user });
          setLogin(true);
          if (talentSignUp) {
            setTalentSignUpModel(false);
          }
        } else {
          setErrorLogin(true);
          setError({
            show: true,
            title: "Login Error",
            message: "Error in Login/SignIn to account",
            error: JSON.stringify({ signInReq }),
            onClose: () => {
              setErrorLogin(false);
              logout();
            },
          });
        }
      }
    })();
  }, [token]);

  return (
    <AuthenticationContext.Provider
      value={{
        firebaseInfo,
        isLogin,
        showLoginModal,
        setShowLoginModal,
        onLogin,
        logout,
        setTalentSignUp,
        setBusinessSignUp,
        businessSignUp,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
