import { useState, useEffect, useContext } from "react";
import "./fileDropzoneInput.scss";
import { ReactComponent as SvgFile } from "../../assets/IconFile.svg";
import "react-circular-progressbar/dist/styles.css";
import { SpinnerCircular } from "spinners-react";
import { isEmpty } from "lodash";
import { v4 } from "uuid";
import CloseButton from "../CloseButton/closeButton";
import { ServicesContext } from "../../context/services";
import React from "react";

const FileDropzoneInput = ({
  onChange = () => {},
  value = [],
  multiple = true,
  pathS3 = "",
  customPlaceHolder,
}: {
  onChange?: (value: string[]) => void;
  multiple?: boolean;
  value?: any[];
  pathS3: string;
  customPlaceHolder?: any;
}) => {
  const { configService } = useContext(ServicesContext);
  const [files, setFiles] = useState<any[]>(value);

  useEffect(() => {
    (async () => {
      const filesToUpload = (files || []).filter((file) => !file.upload);
      const fileToKey: { [key: string]: string } = {};
      for (const fileToUpload of filesToUpload) {
        const file = await configService.uploadFileToS3(
          fileToUpload.file,
          fileToUpload.file.name.split(".").pop(),
          pathS3
        );
        fileToKey[fileToUpload.key] = file.key as string;
      }

      if (!isEmpty(filesToUpload)) {
        setFiles(
          files.map((file) => {
            return {
              ...file,
              upload: true,
              url: fileToKey[file.key] ? fileToKey[file.key] : file.url,
            };
          })
        );
      }
    })();
  }, [configService, files]);

  useEffect(() => {
    onChange(
      (files || []).filter((file) => {
        return file.upload && file.url;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleFileInput = (e: any) => {
    setFiles((files) => {
      const newFiles = [...e.target.files].map((item) => {
        return {
          file: item,
          key: v4(),
          url: "",
          upload: item.upload || false,
        };
      });
      return multiple ? [...files, ...newFiles] : newFiles;
    });
  };

  return (
    <div className="file-drop-zone">
      {isEmpty(files) ? (
        !customPlaceHolder ? (
          <div className="info-text">Drag here or click to select</div>
        ) : (
          React.createElement(customPlaceHolder)
        )
      ) : (
        <></>
      )}
      <input
        onChange={handleFileInput}
        className="file-input"
        multiple={multiple}
        type="file"
      ></input>
      {!isEmpty(files) ? (
        <div className="files-preview">
          {files.map((item) => {
            return (
              <div className="file-preview">
                {item.upload ? (
                  <CloseButton
                    onClick={() => {
                      setFiles(files.filter((file) => file.key !== item.key));
                    }}
                  />
                ) : (
                  <></>
                )}
                {!item.upload ? (
                  <SpinnerCircular
                    size={24}
                    thickness={100}
                    speed={100}
                    color="rgb(242, 68, 98)"
                    secondaryColor="rgba(0, 0, 0, 0.44)"
                  />
                ) : (
                  <Thumbnail file={item.file} />
                )}
                <div className="file-name">{item?.file?.name}</div>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const Thumbnail = ({ file }: { file: File }) => {
  const fileType = file && file["type"];
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  if (validImageTypes.includes(fileType)) {
    return <img src={URL.createObjectURL(file)} alt={file?.name} />;
  }
  return <SvgFile />;
};

export default FileDropzoneInput;
