import { useContext, useEffect, useState } from "react";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import "./card.scss";
import Heart from "./Heart/heart";
import ContentLoader from "react-content-loader";
import "react-lazy-load-image-component/src/effects/blur.css";
import { isEmpty, lowerCase, uniq } from "lodash";
import { useMediaQuery } from "@react-hook/media-query";
import { ApplicationContext } from "../../context/application";
import { ServicesContext } from "../../context/services";
import { LocalizationContext } from "../../context/localization";
import { ReactComponent as LogoSvg } from "../../assets/AppLogo.svg";
import React from "react";
import { AuthenticationContext } from "../../context/authentication";
import { IProfile } from "../../services/v2/users/users.interface";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ISocialMedia } from "../../services/v2/talents/talents.interface";
import { CountryCode } from "libphonenumber-js";
import SocialTag from "../SocialTag/socialTag";

export interface ICardProps {
  title: string;
  price: number;
  description: string;
  favorite: boolean;
  imageUrl: string;
  review: number;
  donation: boolean;
  id: string;
  username: string;
  social: ISocialMedia[];
  country?: CountryCode | string;
}

const Card = React.memo(
  ({
    title,
    price,
    description,
    favorite,
    imageUrl,
    id,
    review,
    donation,
    username,
    country: talentCountry,
    social = [],
  }: ICardProps) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [show] = useState(true);
    const [isFavorite, setFavorite] = useState(favorite);
    const mobileView = useMediaQuery("(max-width: 1025px)") as boolean;
    const [currUsername, setUsername] = useState(username);
    const { isRTL, localization, country, currency } =
      useContext(LocalizationContext);
    const { profile, setProfile } = useContext(ApplicationContext);
    const { setShowLoginModal } = useContext(AuthenticationContext);
    const { usersService, configService } = useContext(ServicesContext);

    useEffect(() => {
      if (!isEmpty(username)) {
        setUsername(username);
      }
    }, [username]);
    useEffect(() => {
      setFavorite(favorite);
    }, [favorite]);

    // useEffect(() => {
    //   let img: HTMLImageElement = new Image();
    //   img.src = imageUrl;
    //   img.onload = () => setSource(imageUrl);
    // }, [imageUrl]);

    if (!show) {
      return <></>;
    }
    if (isEmpty(imageUrl)) {
      return <CardLoader />;
    }
    return (
      <div
        className={classnames("card-container", {
          hover: mobileView || !((review && review > 0) || donation),
          loading: loading,
          mobile: mobileView,
          ltr: !isRTL,
        })}
        onClick={() => {
          history.push(`/talent/${isEmpty(currUsername) ? id : currUsername}`);
        }}
      >
        {mobileView ? (
          <LazyLoadImage
            alt={title}
            effect="blur"
            afterLoad={() => {
              setLoading(false);
            }}
            className={"image"}
            placeholder={<CardLoader />}
            src={imageUrl}
          />
        ) : (
          <img
            alt={title}
            className={"image"}
            loading={"lazy"}
            src={imageUrl}
          />
        )}
        <div className="loading-container">
          <LogoSvg width={"100%"} height={"100%"} />
        </div>
        <div className={classnames("card-top-row", { show: favorite })}>
          <Heart
            checked={isFavorite}
            onCheck={async (state: boolean) => {
              if (!isEmpty(profile)) {
                setFavorite(state);
                if (state) {
                  setProfile({
                    ...profile,
                    favorites: uniq([
                      ...((profile as IProfile).favorites || []),
                      { userId: id },
                    ]),
                  } as IProfile);
                  await usersService.addFavorite(
                    {
                      language: localization,
                      country: country,
                      currency: currency,
                    },
                    id
                  );
                } else {
                  setProfile({
                    ...profile,
                    favorites: (profile as IProfile).favorites.filter(
                      (favorite: IProfile) => favorite.userId !== id
                    ),
                  } as IProfile);
                  await usersService.deleteUserFavorites(
                    {
                      language: localization,
                      country: country,
                      currency: currency,
                    },
                    id
                  );
                }
              } else {
                setShowLoginModal(true);
              }
            }}
          />
        </div>
        <div className={"card-bottom-row"}>
          <div className={"card-header"}>
            <div className={"card-header-title"}>{title}</div>

            {false ? (
              <div className={"card-header-sub-title"}>{`${price || 0} ₪`}</div>
            ) : (
              <></>
            )}
          </div>

          <div className={"card-description"}>
            <p
              className={classnames("card-description-text", {
                "social-exists": false,
              })}
            >
              {description && description.length > 40
                ? description.slice(0, 40) + "..."
                : description}
            </p>
            <div className={classnames("socials-continer", { ltr: !isRTL })}>
              {social
                .filter((item) => item.followers)
                .slice(0, 2)
                .map((socialItem) => {
                  return (
                    <SocialTag
                      platform={socialItem.platform}
                      followers={socialItem.followers}
                    />
                  );
                })}
            </div>

            <div className="country-container">
              {configService
                .getSupportedCountry()
                .includes(talentCountry || "") ? (
                <img
                  src={`https://flagcdn.com/w80/${lowerCase(
                    talentCountry
                  )}.png`}
                  alt={talentCountry}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const CardLoader = () => {
  const mobileView = useMediaQuery("(max-width: 1025px)") as boolean;
  if (mobileView) {
    return (
      <div className={"card-container-loader"} style={{}}>
        <ContentLoader
          speed={1}
          width={163}
          height={256}
          viewBox={`0 0 163 256`}
          backgroundColor="#222222"
          foregroundColor="#1B1B1B"
        >
          <rect x="10" y="195" rx="2" ry="2" width="40" height="18" />
          <rect x="70" y="195" rx="2" ry="2" width="85" height="18" />
          <rect x="10" y="220" rx="2" ry="2" width="145" height="14" />
        </ContentLoader>
      </div>
    );
  }
  return (
    <div className={"card-container-loader"} style={{}}>
      <ContentLoader
        speed={1}
        width={200}
        height={256}
        viewBox={`0 0 200 256`}
        backgroundColor="#222222"
        foregroundColor="#1B1B1B"
      >
        <rect x="15" y="185" rx="2" ry="2" width="50" height="22" />
        <rect x="105" y="185" rx="2" ry="2" width="80" height="22" />
        <rect x="15" y="220" rx="2" ry="2" width="170" height="16" />
      </ContentLoader>
    </div>
  );
};

export default Card;
