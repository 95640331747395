import LocalizedStrings from "localized-strings";

export const strings = new LocalizedStrings({
  en: {
    contactTitle:"Let's talk and get your campaign going in no time",
    campaignOffers:"Start getting campaign offers now!",
    notDeleteAccount:"Don't disable the account",
    deleteAccount: "Deactivate my account",
    deleteAccountContent:
      "A disabled creator account is not displayed on the site and does not receive offers. All active bids will become inactive and account details will not be accessible until reactivated. If you disable the account",
    teamContactMsg: "Our team will contact you soon!",
    askCampaign: "Start now!",
    withdrawalMoneyTooltip: "The minimum withdrawal is one dollar",
    promotionBrand: "Promote your brand with",
    created: "Created",
    for: "For",
    amount: "Amount",
    transaction: "Transaction",
    caseStudies: "Case Studies",
    dashboard: "Dashboard",
    myPosts: "My Posts",
    brandSetting: "Brand Setting",
    twitch: "Twitch",
    discord: "Discord",
    linkedIn: "LinkedIn",
    contactMePayment: "Contact me for payment",
    contactMe: "Contact me",
    paymentDetails: "Payment Details",
    ref: "Referral Link",
    name: "Name",
    companyRole: "Company Role",
    accountName: "Account Name",
    accountEmail: "Account Email",
    accountNumber: "Account Number",
    owner: "Owner",
    manager: "Manager",
    accountUsers: "Account Users",
    accountDetails: "Account Details",
    billing: "Billing",
    messages: "Messages",
    approveTerms: "I approve the ",
    aboutUs: "About Us",
    campaigns: "Campaigns",
    creator: "I'm a Creator",
    advertiser: "I'm an Advertiser",
    orderLink: "Link",
    userNotExist: "User not exist",
    emailMsg: "Invalid email address",
    emailBusinessMsg: "Email is not defined as a business email",

    nameMsg: "Full name should contain at least two characters",
    passwordNotValid: "Password must be at least six characters long",
    videoOrMeeting: "Meeting \\ Video",
    addService: "Add a Service",
    newSocial: "Add another social network",
    followers: "Followers",
    link: "link",
    social: "Social network",
    hideProfile: "Do not show my profile in the category lists",
    chooseService: "Choose a service type",
    talentBuyService:
      "You can create a regular account from different email and then try again",
    talentBuyServiceTitle:
      "Purchasing service is not available for talents at the moment",
    paymentInfo: "",
    searchResult: "Search result",
    updatePassword: "Update new password",
    businessProduct: "Business",
    codeCoupon: "Promo code",
    yes: "Yes",
    copyLink: "Copy link to clipboard",
    newCoupon: "New promo code",
    singleUse: "A one-time use",
    showMore: "Show More",
    productSelect: "Choose a product",
    datesCoupon: "Dates",
    expired: "Expired",
    discount: "Discount",
    percentage: "percent",
    timeZoneLabel: "Time Zone",
    otherTalents: "More for you",
    videos: "Videos",
    dollarUSD: "US Dollars",
    dollarHKD: "Hong Kong Dollars",
    usa: "United States",
    israel: "Israel",
    philipines: "International",
    hongkong: "Hong Kong",
    currencyLabel: "Currency",
    linkCopy: "Link copied",
    showError: "Show Error",
    notFound: "no results found",
    toBusiness: "Business",
    male: "Male",
    female: "Female",
    helpPersonalVideo: "I want a personal video",
    helpText1:
      "Fill out the video invitation on the talent page - the more specific you are in your description, the easier it will be for the talent to fulfill your request. If you would like him to greet someone, read a passage, answer a question or give a tip, just write.",
    helpText2: "The payment will be made at the time of booking",
    helpText3:
      "The talent has up to 7 days to send the video to you (it usually takes less time).",
    helpText4:
      "You will receive the video on your activity page on the site, in the app, and via email when it is ready. You can download the video, share it with friends, and upload it to social networks.",
    helpText5: "I want a video session",
    helpText6:
      "On the talent page, click the meeting button. Fill in the invitation with some personal details and a brief description of what you would like to hear from the talent in the meeting (not mandatory but can help him / her arrive more prepared).",
    helpText7:
      "Choose a date and time that works for you and pay (up to 12 payments can be divided without interest). You will receive an approval or a rejection within 48 hours.",
    helpText8:
      "A reminder will be sent to you about 24 hours before the event as well as about half an hour before the event",
    helpText9: "Visit our ",
    helpText10: "FAQ",
    helpText11: " page for more information",
    helpText12: "And most importantly, enjoy!",
    signUpTalent: "Continue to talent's registration",
    backToSignUp: "Back to user registration",
    myProfile: "My profile page",
    otherSettings: "More settings",
    doneStepTitle: "So fun you joined us!",
    doneStepSubTitle:
      "Your profile page created and now you can share it with your followers and make a profit from your content!",
    doneStepDescription:
      "Your profile page will check with LetsTok marketing crew for shown on LetsTok home page.",
    doneStepDescription2:
      "We recommend you to improve your profile settings to encourage businesses and followers to purchase your content products. You should set additional products, dates for meetings, links to your activities on social networks, discount promo code for promotion and more.",
    productStepSubTitle:
      "Your content products for private or business. More products can be added later",
    productStep: "Your first products",
    simples: "Examples",
    mediaStep: "Profile picture and introductory video",
    mediaStepSubTitle:
      "A profile with an attractive photo and video X5 times more sales",
    mustFill: "This field is required",

    experiences: "Experiences",
    leaders: "Talents",
    infoShortBio:
      "Your description for the home page. For example: Youtuber, Lifestyle, Gamer, Coach, exc..",
    infoBio:
      "for example: Hey, I'm Shira, I am a fashion and lifestyle influencer, I'm here to talk with you about self confidence… waiting to meet you!",
    errorTitle: "An error occurred",
    paymentErrorMsg:
      "There was an error charging the card, please try again later",
    orderErrorMsg: "An error occurred while ordering. Please try again later",
    couponText:
      "You can create your own code for holidays, events or to provide a personal discount to the costumer.",
    mettingsDate: "Date set",
    chooseDateText: "The selected date will be displayed as",
    saveCoupon: "Save promo code",
    deleteCoupon: "Delete promo code",
    addCoupon: "New promo code",
    editCoupon: "Editing a promo code",
    displayStep: "Details for display",
    displayStepSubTitle:
      "Please fill in your details and we will contact you shortly",
    continue: "Continue",
    urlInfo: "your URL link for share",
    skip: "Skip",
    url: "URL link",
    activityAccept: "I confirm get messages about my activity on LetsTok",
    bioLabel: "Tell us about yourself",
    shortBioLabel: "Short bio",
    categoryLabel: "Choose category (Maximum 3)",
    countryLabel: "Country",
    numberLabel: "Phone Number",
    ordersRejected: "Rejected",
    ordersEnd: "Submitted",
    paddingVideo: "Accepted",
    paddingMeetings: "Scheduled meetings",
    waitToApprove: "New offers",
    shekelILS: "Shekels (NIS)",
    currency: "Currency",
    language: "Language",
    save: "Save",
    country: "State",
    instagramLink: "Instagram",
    facebook: "Facebook",
    twitter: "Twitter",
    tikTokLink: "TikTok",
    youtube: "YouTube",
    usernameMsg: "Only English and digits characters",
    userExistError: "userExistError",
    username: "Username",
    orderNow: "Order Now",
    inCome: "income",
    outCome: "withdrawal",
    helpAndInfo: "Contact Us",
    addProductHeaderText:
      "Create content which your clients followers will order",
    addProductHeaderTextExample: `For example: personalized video, online meeting, tutorial`,
    toOrder: "Order Now",
    wantDonation: "I want to donate part of my income",
    donationProfileInfo: `Enabling this option will indicate "some of the revenue donated" in your public profile`,
    shortDescription: "Tagline (one sentence)",
    shortBio: "Short bio",
    promoVideo: "Promo video",
    uploadVideo2: "Upload a video",
    type: "Type",
    displayName: "Name",
    allTalents: "All Talents",
    recommended: "Recommended",
    influencers: "Influencers",
    realityStars: "Reality Stars",
    tikTok: "TikTok",
    actors: "Actors",
    music: "Music",
    beautyFashion: "Beauty & Fashion",
    sport: "Sport",
    comedians: "Comedians",
    experts: "Experts",
    date: "Date",
    action: "action",
    sum: "Sum",
    sendWithdrawal: "Send a withdrawal request",
    activityFrom: "activity from",
    bankTransfer: "Bank transfer",
    withdrawalMoney: "Money Withdrawal ",
    withdrawalMoneyMsg:
      "We have sent you an email with withdrawal instructions. If you have questions, please contact us at Finance@letstok.com",
    availableWithdrawal: "Available for withdrawal",
    totalWithdrawal: "total withdrawal",
    letstokCommission: "After deducting LetsTok commission *",
    withdrawalMethod: "Withdrawal Method",
    totalMoney: "Total income",
    startOfActivity: "Overall Income",
    last30Day: "Last 30 Days",
    currYear: "Current Year",
    lastYear: "Last year",
    coupon: "My promo code",
    personalInfo: "Personal details (for internal use)",
    displayInfo: "Display details",
    donationInfo: "Charity (optional)",
    donationInfoText: "You can donate automatically part of your income",
    availabilityPageText:
      "set your availability for meeting with your clients / followers",
    custom: "Custom",
    allDay: "Available all day",
    allDayNot: "Unavailable all day",
    availabilityInDay: "Day availability",
    saveDay: "Save",
    specialDateTextTitle: "Set Special Dates ",
    specialDateTextSubTitle:
      "If at a certain time or date you are not available for meetings, add them here and they will be displayed in your calendar accordingly",
    start: "Start",
    end: "End",
    addSpecialDate: "Add a special date",
    creditNameValidation: "at least 2 digits",
    monthValidation: "wrong month",
    validationMsg: "Invalid value",
    idValidation: "Wrong ID",
    creditCartValidation: "Wrong credit card number",
    paymentError: "Payment error",
    paymentMessage: "Error. Please try again",
    pay: "Credit card details",
    creditCard: "Credit card",
    paymentMethod: "Payment method",
    paymentName: "Card holder's name",
    idOfPayment: "Card holder's ID",
    creditNumber: "Card number",
    year: "year",
    expiration: "Expiration Date",
    month: "month",
    cvvNumber: "CVV number",
    paymentLoading: "Processing Payment",
    funOrder: "Thank you for your order !",
    orderResponse: "Your order was sent and we will be respond soon",
    rejectedOrderQuestion: "Reject this order ?",
    rejectedOrderAction: "Reject order",
    available: "Available",
    unAvailable: "Unavailable",
    saveSpecialDate: "save special date",
    startAndEndDate: "Start and end date",
    specialDateText: "set a special date",
    typeProduct: "Service type",
    titleProduct: "Title",
    descriptionProduct: "Description",
    durationProduct: "Duration",
    minutesProduct: "minutes",
    priceProduct: "Price",
    saveProduct: "Save",
    deleteProduct: "Delete",
    product: "Services",
    searchOrder: "Search by name or order ID",
    availability: "Availability",
    availability2: "Availability",

    meetingWillStartIn: "The meeting will start in",
    myWallet: "Wallet",
    orders: "Orders",
    status: "Order Status",
    reviewText: "How was your experience? Please write us a review",
    personalVideo: "Personalized video",
    personalMeeting: "Meeting \\ Tutorial \\ Online Show",
    businessVideo: "Business Marketing Video",
    businessMeeting: "Meeting \\ Tutorial \\ Online show for businesses",
    reviewDescription: "Describe your experience",
    orderId: "Order ID",
    approvedOrder: "Accept",
    rejectedOrder: "Reject",
    orderDescription: "Order description",
    publishReviewText: "Post your review",
    requesterName: "Name of recipient",
    pendingDate: "Scheduled Date",
    approvedDate: "Due Date",
    createVideo: "Create your video",
    uploadVideo: "Upload video",
    enterToMeeting: "Enter the waiting room",
    addProduct: "Add a Meeting \\ Video",
    editProduct: "Edit Product",
    edit: "Edit",

    done: "Done",
    donationText: "Charity",
    businessVideoType: "Business video",
    personalMeetingType: "Video meeting",
    businessMeetingType: "Business meeting",
    hour4: "4 Hours",
    hour8: "8 Hours",
    hour12: "12 Hours",
    day1: "Day",
    day2: "2 Days",
    day3: "3 Days",
    week: "Week",
    notFoundOrders: "You haven't got any orders yet",
    english: "English",
    hebrew: "Hebrew",
    sunday: "Su",
    monday: "Mo",
    tuesday: "Tu",
    wednesday: "We",
    thursday: "Th",
    friday: "Fr",
    saturday: "Sa",
    sunday2: "Su",
    monday2: "Mo",
    tuesday2: "Tu",
    wednesday2: "We",
    thursday2: "Th",
    friday2: "Fr",
    saturday2: "Sa",
    day: "Days",
    hours: "Hours",
    bannerText: "The first Adfluencer network",
    startMeetings: "",
    acceptTerms: "I read and accept the terms",
    accepted: "Accepted",
    acceptedDate: "Date of approval",
    accountSetting: "Account settings",
    enterMeeting: "Join to meeting",
    android: "Android",
    april: "April",
    august: "August",
    avgResponses: "average response time",
    back: "Back",
    birthDate: "Birthday",
    blog: "Blog",
    business: "Business",
    businessName: "Business name",
    category: "Category",
    cleanMessage: "Clean massages",
    close: "Close",
    codeCopen: "Promo code",
    contactYou: "After approval your request we will contact you.",
    copyright: "© LetsTok 2022",
    december: "December",
    description: "Description",
    donation: "Some earnings are donated",
    donationTalent: "Show only charity",
    downloadApp: "Download the app",
    email: "Email",
    emailValidation:
      "We have sent you a confirmation code to your email. You must confirm to continue",
    enterCopen: "Enter",
    invite: "Invite",
    enterTimeAndDate: "Choose date and time",
    error: "Error",
    errorMsgSaveChange: "Error. Please try again.",
    eventInApp: "Meetings and shows only be made in the app",
    exitAccount: "Logout",
    february: "February",
    forgotPassword: "Forgot my password",
    fullName: "Your name",
    fun: "Thank you for joining!",
    haveCopen: "I have a promo code",
    help: "How does it work?",
    hi: "Hey",
    home: "Home",
    instagram: "Instagram",
    invoicing: "Invoice for another name",
    iphone: "Iphone",
    january: "January",
    joinRecently: "Recently joined",
    july: "July",
    wrongPassword: "Wrong Password",
    june: "June",
    keepRead: "Read More",
    leaveEmpty: "In case of gift or leave blank if not relevant",
    letstokActivities:
      "I confirm receipt of notifications regarding my activity on LetsTok",

    login: "Sign in",
    loginText: "Login with email and password",
    march: "March",
    may: "May",
    meeting: "Meeting",
    minute: "Min",
    myAccount: "My account",
    myFavorite: "My favorites",
    myOrders: "My orders",
    newsFeedSubscribe: "Get updates of talent and content",
    newsfeed: "News Feed",
    newsfeedAccept: "I'm interested getting updates on new talent and content",
    notValidEmail: "Invalid Email",
    notValidNumber: "Invalid phone number",
    november: "November",
    october: "October",
    orderApproved: "Order accepted",
    orderPlaced: "Order placed",
    orderDate: "Order Date",
    orderEnd: "Order completed",
    orderName: "Brand name",
    orderOptions: "Order options",
    orderPadding: "Offered",
    orderReject: "Order rejected",
    orderRequestDate: "Order Date",
    password: "Password",
    phone: "Phone Number",
    phoneNumber: "Phone Number",
    price: "Price",
    private: "Privacy Policy",
    profit: "Start getting brand collaborations NOW!",
    publicVideo:
      "I confirm the video prepared by the artist will appear on his public artist page in LetsTok",
    publicMeeting:
      "I confirm the meeting prepared by the artist will appear on his public artist page in LetsTok",
    qa: "FAQ",
    ratings: "Ratings",
    receivesName: "Receiver name",
    recentVideos: "Recent Videos",
    rejectedReason: "Reject reason",
    resetPassword: "Reset password",
    responseTime: "Response time",
    review: "Review",
    saveChange: "Save changes",
    saveChangeSuccess: "Changes saved successfully!",
    searchTalent: "Search",
    september: "September",
    setDate: "Date of meeting",
    settings: "Account settings",
    share: "Share",
    shekel: "Shekels",
    showAll: "Show all",
    signMe: "Sign me up!",
    signUp: "Signup",
    signUpText: "Signup with email and password",
    socialLoginText: "Login via social",
    socialSignUpText: "Signup via social",
    someWords: "A few words about me",
    sortByHighPrice: "Price from high to low",
    sortByLowPrice: "Price from low to high",
    sortByNew: "Sort by newest",
    sortByOrder: "Sort by hottest",
    talentSignUp: "Register as a talent",
    talents: "Talent",
    talentsRegister: "Registration for talents",
    talentsRegisterText: "Ready to become a LetsToker?",
    terms: "Terms of use",
    terms2: "Terms and Conditions",

    toPay: "Pay",
    video: "Video",
    welcome: "Welcome",
    notFoundFavorite: "No talents added to your favorites list yet",
    fileExamples: "Sample Profile Video",
    changeYourPassword: "Change Password",
    passwordChangedSuccessfully: "Password was changed successfully",
    passwordError: "Error occurred, please try again later.",
    previousPassword: "Old Password",
    newPassword: "New Password",
    retypePassword: "Retype Password",
    forgotMyPassword: "Forgot Password",
    resetCodeSentToEmail: "Reset code sent to your email",
    resetCode: "Reset Code",
    resetLinkSent: " Reset link sent to email",
    saveNewPassword: "Save new password",
    videoLabel: "Promo video",
    imageLabel: "Profile picture",
  },

  he: {
    askCampaign: "בקשה לקמפיין",
    contactTitle:"Let's talk and get your campaign going in no time",
    campaignOffers:"Start getting campaign offers now!",
    deleteAccount: "השבתת חשבון",
    deleteAccountContent:
      "חשבון יוצר מושבת לא מוצג באתר ולא מקבל הצעות. כל ההצעות הפעילות יהפכו להיות לא פעילות ולא יהיה ניתן לגשת לפרטי החשבון ?עד להפעלה מחדש. אם להשבית את החשבון",
    caseStudies: "Case Studies",
    withdrawalMoneyTooltip: "The minimum withdrawal is one dollar",
    promotionBrand: "קידום לעסק שלך עם",
    teamContactMsg: "הצוות שלנו ייצור איתך קשר בקרוב!",
    notDeleteAccount:"לא להשבית את החשבון",

    dashboard: "לוח בקרה",
    created: "נוצר",
    for: "עבור",
    amount: "סכום",
    transaction: "עסקה",
    myPosts: "הפוסטים שלי",
    brandSetting: "הגדרות מותג",
    twitch: "Twitch",
    discord: "Discord",
    linkedIn: "LinkedIn",
    paymentDetails: "פרטי תשלום",
    contactMe: "צרו קשר",
    name: "שם",
    companyRole: "תפקיד",
    contactMePayment: "צרו קשר לתשלום",

    ref: "לינק מעקב",
    accountName: "שם מלא",
    accountEmail: "מייל",
    accountNumber: "מספר טלפון",
    owner: "מנהל",
    manager: "מנהל",
    accountDetails: "פרטי חשבון",
    billing: "חיוב",
    aboutUs: "אודותינו",
    messages: "הודעות",
    campaigns: "קמפיינים",
    creator: "כניסה ליוצרים",
    advertiser: "כניסה למפרסמים",
    emailMsg: "כתובת מייל לא תקינה",
    emailBusinessMsg: "Email is not defined as a business email",

    nameMsg: "שם מלא אמור להכיל לפחות שני תווים",
    passwordNotValid: "סיסמה אמורה להכיל לפחות שישה תווים",
    userNotExist: "משתמש לא קיים",
    social: "רשת חברתית",
    link: "לינק",
    followers: "מספר עוקבים",
    newSocial: " הוספת עוד רשת חברתית",
    videoOrMeeting: "וידאו / פגישה",
    orderLink: "קישור",

    videoLabel: "סרטון פרופיל",
    imageLabel: "תמונת פרופיל",
    chooseService: "בחר את סוג השירות",
    hideProfile: "אל תציגו את הפרופיל שלי ברשימות הקטגוריות",

    talentBuyService: "רכישת שירותים אינה זמינה כרגע לחשבון טאלנט",
    talentBuyServiceTitle: "באפשרותך ליצור חשבון רגיל ואז לנסות שוב",
    updatePassword: "עדכון סיסמא חדשה",
    searchResult: "תוצאות חיפוש",
    accountUsers: "משתמשים בחשבון",
    copyLink: "העתק קישור",
    wrongPassword: "סיסמה שגויה",
    forgotMyPassword: "שכחתי סיסמה",
    previousPassword: "סיסמא קודמת",
    newPassword: "סיסמה חדשה",
    passwordError: "קרתה שגיאה בלתי צפויה אנא נסה במועד מאוחר יותר",
    passwordChangedSuccessfully: "הסיסמה שונתה בהצלחה",
    changeYourPassword: "שינוי סיסמה",
    businessProduct: "מוצר עסקי",
    yes: "כן",
    showMore: "הצג עוד",
    newCoupon: "קופון חדש",
    codeCoupon: "קוד קופון",
    productSelect: "החל על מוצר",
    singleUse: "שימוש חד פעמי",

    datesCoupon: "טווח תוקף",
    expired: "פג תוקף",
    discount: "הנחה",
    percentage: "אחוזים",
    timeZoneLabel: "אזור זמן",
    currencyLabel: "מטבע",
    notFound: "לא נמצאו תוצאות",
    otherTalents: "עוד טאלנטים",
    videos: "סירטונים",
    orderErrorMsg: "קרתה שגיאה בשיריון הזמנה אנא נסה במועד מאוחר יותר",
    dollarUSD: "דולר אמריקאי",
    dollarHKD: "דולר הונג קונג",
    linkCopy: "הקישור עותק",

    toBusiness: "לעסקים",
    male: "זכר",
    female: "נקבה",
    helpPersonalVideo: "אני רוצה סרטון אישי",
    helpText1:
      "לחצו על כפתור הסרטון שבדף הטאלנט מלאו את ההזמנה - ככל שתהיו יותר ברורים בתיאור ההזמנה, כך יהיה יותר קל לטאלנט למלא אחר בקשתכם. אתם יכולים לבקש ממנו לברך מישהו/ להקריא קטע שתעבירו/ לענות על שאלה או לתת טיפ… רק תכתבו.",
    helpText2: "התשלום יבוצע במעמד ההזמנה",
    helpText3: "לטאלנט יש עד 7 ימים לשלוח לכם בחזרה את הסרטון (לרוב יקח פחות)",
    helpText4:
      "כשהסרטון יהיה מוכן תקבלו אותו לדף הפעילות שלכם באתר, באפליקציה ולכתובת המייל האישית שלכם. ניתן להוריד את הסרטון, לשלוח לחברים ולהעלות לרשתות החברתיות. ",
    helpText5: "אני רוצה פגישת וידאו",
    helpText6:
      "לחצו על כפתור הפגישה שבדף הטאלנט מלאו בהזמנה מספר פרטים אישיים ותיאור קצר על מה שתרצו לשמוע מהטאלנט בפגישה (לא חובה אבל יכול לעזור לו/לה להגיע מוכנים יותר) רצוי לספר לטאלנט גם מה הסיבה/ האירוע שלשמו הוזמן.",
    helpText7:
      "סמנו בהזמנה תאריך ושעה המתאימים לכם ובצעו תשלום (ניתן לחלק עד 12 תשלומים ללא ריבית) תוך 48 שעות תקבלו אישור/סירוב לבקשה מהטאלנט",
    helpText8: "תזכורת תשלח אליכם כ-24 שעות וגם כחצי שעה לפני המועד",
    helpText9: "לשאלות נוספות, אנא בקרו בדף ה",
    helpText10: "שאלות הנפוצות",
    helpText11: "שלנו",
    helpText12: "והכי חשוב, תהנו!",
    myProfile: "עמוד הפרופיל שלי",
    otherSettings: "הגדרות נוספות",
    doneStepTitle: "!כיף שהצטרפת",
    doneStepSubTitle: `עמוד הפרופיל שלך נוצר ובאפשרותך לשתף אותו עם העוקבים שלך ולהתחיל להרוויח מהתוכן שלך!`,
    doneStepDescription:
      "הפרופיל נמצא כרגע בבדיקה על ידי צוות השיווק שלנו על מנת להופיע בעמוד בית של LetsTok",
    doneStepDescription2:
      "אנו ממליצים להמשיך ולשפר את הגדרות הפרופיל כדי לעודד עסקים, ועוקבים לקנות את מוצרי התוכן שלך. כדאי להגדיר מוצרים נוספים, מועדים זמינים לפגישות, לינקים לפעילות שלך ברשתות חברתיות. קופוני הנחה לקידום ועוד",
    fileExamples: "דוגמאות לסרטון פרופיל",
    simples: "דוגמאות",
    edit: "עריכה",
    productStepSubTitle:
      "מוצרי התוכן שלך לפרטיים או לעסקים, יכולים להיות פגישות הופעה, הדרכה, סדנאות או סרטונים. בהמשך ניתן להוסיף עוד מוצרים",
    productStep: "המוצרים הראשונים שלך",
    infoShortBio:
      "התיאור שלך לדף הבית. לדוגמה: יוטיובר, סגנון חיים, גיימר, מאמן",
    mediaStep: "תמונת וסרטון פרופיל",
    mediaStepSubTitle:
      "‏5 יותר מכירותX פרופיל עם תמונה וסרטון אטרקטיביים מעודדים",
    infoBio:
      "למשל: היי, אני שירה, אני משפיע אופנה וסגנון חיים, אני כאן כדי לדבר איתך על ביטחון עצמי מחכה לפגוש אותך",
    urlInfo: "קישור לשיתוף",
    signUpTalent: "המשך הרשמה למשפיענים",
    backToSignUp: "חזרה להרשמה רגילה",
    displayStep: "פרטים לתצוגה",
    mustFill: "יש למלא שדה זה",
    displayStepSubTitle: "אלה הפרטים שיוצגו למבקרים בעמוד הפרופיל שלך",
    continue: "המשך",
    bioLabel: "ביוגרפיה קצרה",
    categoryLabel: "קטגוריות (מקסימום 3)",
    countryLabel: "מדינה",
    numberLabel: "טלפון",
    addCoupon: "קופון חדש",
    editCoupon: "עריכת קופון",
    terms2: "Terms and Conditions",

    saveCoupon: "שמור קופון",
    deleteCoupon: "מחיקת קופון",
    experiences: "חוויות",
    url: "כתובת העמוד שלי",
    activityAccept: "LetsTokאני מאשר קבלת הודעות בקשר לפעילות שלי ב",
    shortBioLabel: "תיאור במשפט אחד",
    couponText:
      "קופונים מאפשרים לך לקדם מכירות מוצרים. ניתן ליצור קופונים לכבוד חג, אירוע, השקת מוצר חדש או לצורך מתן הנחה אישית ללקוח",
    ordersRejected: "הזמנות שנדחו",
    ordersEnd: "הזמנות שהסתיימו",
    paddingVideo: "סרטונים להכנה",
    paddingMeetings: "פגישות שנקבעו",
    waitToApprove: "ממתינים לאישור",
    skip: "דילוג על השלב",
    usa: "United States",
    israel: "Israel",
    philipines: "International",
    hongkong: "Hong Kong",
    language: "שפה",
    leaders: "טאלנטים",
    save: "Save",
    instagramLink: "אינסטגרם",
    facebook: "פייסבוק",
    twitter: "טוויטר",
    tikTokLink: "טיקטוק",
    youtube: "יוטיוב",
    orderNow: "הזמינו עכשיו",
    helpAndInfo: "לשאלות ותמיכה",
    username: "שם משתמש",
    currency: "מטבע",
    usernameMsg: "תווים רק באנגלית או מספרים",
    country: "מדינה",
    userExistError: "שם המשתמש כבר קיים",
    addProductHeaderText: " צרו מוצרי תוכן וידאו שהעוקבים שלכם ירצו להזמין",
    addProductHeaderTextExample: `.לדוגמא: הופעה, הדרכה, סרטון אישי, סרטון ברכה, פגישה אישית, סרטון פרסומת ועוד`,
    wantDonation: "ברצוני לתרום מההכנסות שלי ",
    donationProfileInfo: `הפעלת אפשרות זו תציין "חלק מההכנסות נתרמות" בפרופיל הציבורי שלך`,
    donationInfoText: ` באפשרותך לתרום באופן אוטומטי חלק מהכנסותיך ב Let'sTok – לטובת הארגונים :
    "עמותת לתת", "יד שרה", "עזרה לטאלנט החוסה"`,
    promoVideo: "סרטון פרומו",
    uploadVideo2: "העלאת סרטון",
    shortBio: "ביוגרפיה קצרה",
    type: "סוג",
    displayName: "שם",
    shortDescription: "תיאור במשפט אחד",
    toOrder: "להזמנה",
    recommended: "מומלצים",
    influencers: "אושיות רשת",
    realityStars: "כוכבי ריאליטי",
    tikTok: "טיקטוק",
    actors: "שחקנים",
    music: "מוזיקה",
    beautyFashion: "ביוטי ואופנה",
    sport: "ספורט",
    comedians: "קומיקאים",
    experts: "מומחים",
    errorTitle: "התרחשה שגיאה",
    paymentErrorMsg: "קרתה שגיאה בחיוב הכרטיס אנא נסה במועד מאוחר יותר",
    businessVideoType: "סרטון עסקי",
    personalMeetingType: "פגישת וידאו",
    businessMeetingType: "פגישת עסקי",
    hour4: "4 שעות",
    hour8: "8 שעות",
    hour12: "12 שעות",
    day1: "יום",
    day2: "2 ימים",
    day3: "3 ימים",
    week: "שבוע",
    withdrawalMoney: "משיכת כסף",
    withdrawalMoneyMsg: "הבקשה למשיכת הכסף נשלח בהצלחה",
    availableWithdrawal: "זמין למשיכה",
    totalWithdrawal: `סה"כ משיכות`,
    letstokCommission: `LetsTok לאחר ניכוי 25% עמלת *`,
    bankTransfer: "העברה בנקאית",
    sendWithdrawal: "שליחת בקשת משיכה",
    activityFrom: "פעילות מתקופה",
    sunday: "א",
    monday: "ב",
    tuesday: "ג",
    wednesday: "ד",
    thursday: "ה",
    friday: "ו",
    saturday: "ש",
    sunday2: "א",
    monday2: "ב",
    tuesday2: "ג",
    wednesday2: "ד",
    thursday2: "ה",
    friday2: "ו",
    saturday2: "ש",
    coupon: "הקופונים שלי",
    withdrawalMethod: "אופן קבלת התשלום",
    date: "מועד",
    action: "פעולה",
    sum: "סכום",
    startOfActivity: "מתחילת הפעילות",
    last30Day: "30 יום אחורה",
    currYear: "שנה נוכחית",
    lastYear: "שנה קודמת",
    personalInfo: "פרטים אישיים (לשימוש פנימי)",
    displayInfo: "פרטים לתצוגה",
    donationInfo: "מכל הלב",
    inCome: "הכנסה",
    totalMoney: `*סה"כ הכנסות`,
    outCome: "משיכה",
    availabilityPageText:
      "הגדירו ימים ושעות בהם העוקבים שלכם יוכלו לקבוע איתכם פגישות",
    custom: "מותאם אישית",
    allDay: "כל היום זמין",
    allDayNot: "כל היום לא זמין",
    availabilityInDay: "זמינות שעות היום הנבחר",
    saveDay: "שמירת יום",
    specialDateTextTitle: "הגדירו מועדים חריגים בהם תהיו זמינים או לא זמינים",
    specialDateTextSubTitle:
      "אם בתקופה או מועד מסוים אינכם זמינים לפגישות, הוסיפו אותם לכאן והם יוצגו ביומן שלכם בהתאם",
    start: "התחלה",
    end: "סיום",
    addSpecialDate: "הוספת מועד חריג",
    creditNameValidation: "שם בעל הכרטיס אמור להכיל לפחות שני תווים",
    monthValidation: "חודש לא תקין",
    validationMsg: "ערך לא תקין",
    showError: "הצג שגיאה",

    idValidation: "תעודת זהות לא חוקית",
    creditCartValidation: "כרטיס אשראי לא תקין",

    paymentLoading: "מבצע תשלום...",
    paymentError: "שגיאה בתשלום",
    paymentMessage: "קרתה שגיאה בחיוב הכרטיס, כרטיסיך לא חויב אנא נסה שנית",
    pay: "פרטי אשראי",
    creditCard: "כרטיס אשראי",
    paymentMethod: "שיטת תשלום",
    paymentName: "שם בעל הכרטיס",
    idOfPayment: "תעודת זהות של בעל הכרטיס",
    creditNumber: "מספר כרטיס",
    year: "שנה",
    expiration: "תוקף",
    month: "חודש",
    cvvNumber: "CVV מספר",
    paymentInfo: "",
    funOrder: "!איזה כיף שהזמנת אותי",
    orderResponse: ".ההזמנה שלך נשלחה אלי ואגיב בהקדם",
    donationText: "תורם חלק מההכנסות",
    english: "אנגלית",
    rejectedOrderQuestion: "?האם לדחות את ההזמנה",
    rejectedOrderAction: "דחיית הזמנה",
    typeProduct: "סוג",
    titleProduct: "כותרת",
    descriptionProduct: "תיאור",
    durationProduct: "משך זמן",
    minutesProduct: "דקות",
    priceProduct: "מחיר",
    saveProduct: "שמור מוצר",
    deleteProduct: "מחיקת מוצר",
    available: "זמין",
    unAvailable: " לא זמין",
    saveSpecialDate: "שמירת מועד חריג",
    startAndEndDate: "תאריך התחלה וסיום",
    chooseDateText: "המועד הנבחר יוצג בתור",
    specialDateText: "הגדרת מועד חריג",
    hebrew: "עברית",
    approvedOrder: "אישור הזמנה",
    personalVideo: "סרטון אישי",

    personalMeeting: "פגישת/ הדרכת/ הופעת וידאו",
    businessVideo: "סרטון עבור עסק",
    businessMeeting: "פגישה/ הדרכה/ הופעה עבור עסק",
    rejectedOrder: "דחיית הזמנה",
    orderDescription: "תיאור הבקשה",
    requesterName: "שם המקבל",
    pendingDate: "מועד נקבע",
    approvedDate: "מועד הגשה",
    mettingsDate: "מועד נקבע",
    approveTerms: "I approve the ",

    createVideo: "יצירת סרטון",
    uploadVideo: "העלאה סרטון",
    addProduct: "מוצר חדש",
    editProduct: "עריכת מוצר",
    enterToMeeting: "כניסה לחדר ההמתנה",
    publishReviewText: "פרסום חוות דעת",
    reviewText: "?איך היתה החוויה שלך",
    orderDate: "מועד הזמנה",
    done: "הסתיים",
    notFoundOrders: "עדיין אין לך הזמנות",
    searchOrder: "חיפוש לפי שם המזמין או מספר עסקה",
    allTalents: "כל הטאלנטים",
    product: "מוצרים",
    availability: "זמינות",
    availability2: "זמינות",

    meetingWillStartIn: "הפגישה תחל בעוד",
    myWallet: "הארנק שלי",
    orders: "הזמנות",
    day: "ימים",
    hours: "שעות",
    status: "סטטוס",
    orderId: "מספר עסקה",
    reviewDescription: "תיאור החוויה בכמה מילים",
    startMeetings: "הפגישה שלך עם ",
    home: "בית",
    talents: "טאלנטים",
    newsfeed: "ניוזפיד",
    enterMeeting: "כניסה לפגישה",
    searchTalent: "חיפוש טאלנטים",
    showAll: "הצג את כולם",
    email: "מייל",
    password: "סיסמא",
    fullName: "שם מלא",
    loginText: "כניסה באמצעות אימייל וסיסמא",
    signUpText: "הרשמה באמצעות אימייל וסיסמא",
    socialLoginText: "כניסה באמצעות רשתות חברתיות",
    socialSignUpText: "הרשמה באמצעות רשתות חברתיות",
    login: "כניסה",
    signUp: "הרשמה",
    myAccount: "החשבון שלי",
    myOrders: "ההזמנות שלי",
    myFavorite: "המועדפים שלי",
    accountSetting: "הגדרות חשבון",
    welcome: "ברוכים הבאים",
    hi: "היי",
    category: "קטגוריות",
    orderOptions: "אפשרויות הזמנה",
    responseTime: "זמן תגובה",
    price: "מחיר",
    donationTalent: "הצג רק טאלנטים שנותנים תרומה",
    newsFeedSubscribe: "קבלו עדכונים על טאלנטים ותכנים",
    downloadApp: "הורידו את האפליקציה",
    iphone: "אייפון",
    android: "אנדרואיד",
    talentsRegisterText: "?רוצה להצטרף ללטסטוק",
    talentsRegister: "הרשמה לטאלנטים",
    qa: "שאלות ותשובות",
    blog: "בלוג",
    private: "פרטיות",
    terms: "תנאי שימוש",
    help: "?איך זה עובד",
    copyright: "2022 Let's Tok כל הזכויות שמורות ל",
    cleanMessage: "נקה הודעות",
    keepRead: "המשך קריאה",
    joinRecently: "הצטרפו לאחרונה",
    close: "סגור",
    orderPadding: "ממתין לאישור",
    orderApproved: "הזמנה אושרה",
    orderPlaced: "נקבע",
    orderReject: "הזמנה נדחתה",
    orderEnd: "הזמנה הסתיימה",
    orderRequestDate: "מועד הזמנה",
    accepted: "התקבל",
    acceptedDate: "מועד אישור",
    setDate: "מועד נקבע",
    rejectedReason: "סיבת הדחייה",
    share: "שיתוף",
    review: "חוות דעת",
    eventInApp: "פגישות/הופעות יתקיימו רק באפליקציה",
    orderName: "שם המזמין",
    leaveEmpty: "במקרה של מתנה או הקדשה או השאירו ריק אם לא רלוונטי",
    receivesName: "שם המקבל",
    description: "תיאור הבקשה ופרטים נוספים",
    notValidNumber: "מספר לא חוקי",
    phoneNumber: "טלפון לבירורים",
    businessName: "שם העסק",
    minute: "דקות",
    video: "סרטון",
    meeting: "פגישה",
    publicVideo:
      "אני מאשר שהסרטון שיכין האמן תופיע בדף האמן הציבורי שלו בלטסטוק",
    publicMeeting:
      "אני מאשר שהפגישה שיכין האמן תופיע בדף האמן הציבורי שלו בלטסטוק",
    acceptTerms: "קראתי ואישרתי את",
    toPay: "לתשלום",
    shekel: `ש"ח`,
    shekelILS: "שקלים",
    invite: "הזמן",
    haveCopen: "יש לי קופון",
    codeCopen: "קוד קופון",
    enterCopen: "יישום קוד",
    invoicing: "חשבונית על שם אחר",
    ratings: "מדרגים",
    enterTimeAndDate: "בחירת תאריך ושעה",
    january: "ינואר",
    february: "פברואר",
    march: "מרץ",
    april: "אפריל",
    may: "מאי",
    june: "יוני",
    july: "יולי",
    august: "אוגוסט",
    september: "ספטמבר",
    october: "אוקטובר",
    november: "נובמבר",
    december: "דצמבר",
    saveChange: "שמירת שינוים",
    saveChangeSuccess: "השינויים נשמרו בהצלחה",
    error: "שגיאה",
    errorMsgSaveChange: "קרתה שגיאה לא צפויה בשמירת הנתונים",
    resetPassword: "איפוס סיסמא",
    exitAccount: "יציאה מהחשבון",
    newsfeedAccept: "אני מעוניין לקבל עדכונים על טאלנטים ותכנים חדשים",
    letstokActivities: "אני מאשר קבלת הודעות בקשר לפעילות שלי בLetsTok",
    notValidEmail: "כתובת מייל לא תיקנית",
    phone: "טלפון",
    birthDate: "תאריך לידה",
    sortByOrder: "לפי הכי מוזמנים",
    donation: "חלק מההכנסות נתרמות",
    avgResponses: "זמן תגובה ממוצע",
    business: "עסקי",
    settings: "הגדרות",
    sortByNew: "לפי הכי חדשים",
    sortByLowPrice: "מחיר נמוך לגבוה",
    sortByHighPrice: "מחיר גבוה לנמוך",
    fun: "!איזה כיף שהצטרפת",
    contactYou: "לאחר אישור הבקשה ניצור איתך קשר",
    profit: "!בואו להרוויח מהתוכן שלכם",
    instagram: "אינסטגרם",
    back: "חזרה",
    emailValidation: "יש לבצע כניסה עם המייל והסיסמה שבחרתם",
    talentSignUp: "הרשם כטאלנט",
    forgotPassword: "שכחתי סיסמא",
    recentVideos: "סרטונים אחרונים",
    someWords: "כמה מילים עלי",
    signMe: "רשמו אותי!",
    bannerText: "The first Adfluencer network",
    notFoundFavorite: "עוד לא בחרת טאלנטים מועדפים",
    retypePassword: "הקלד שוב את הסיסמא",
    resetCodeSentToEmail: "שלחנו קוד איפוס סיסמה למייל שלך",
    resetCode: "קוד איפוס",
    resetLinkSent: "נשלח למייל קוד איפוס",
    saveNewPassword: "שמור סיסמה חדשה",
    addService: "הוסף מוצר",
  },
  ch: {
    promotionBrand: "Promote your brand with",
    askCampaign: "Start now!",
    contactTitle:"Let's talk and get your campaign going in no time",
    campaignOffers:"Start getting campaign offers now!",
    teamContactMsg: "Our team will contact you soon!",
    deleteAccount: "Deactivate my account",
    notDeleteAccount:"Don't disable the account",

    deleteAccountContent:
      "A disabled creator account is not displayed on the site and does not receive offers. All active bids will become inactive and account details will not be accessible until reactivated. If you disable the account",
    caseStudies: `公司合作案例`,
    created: "Created",
    for: "For",
    amount: "Amount",
    transaction: "Transaction",
    dashboard: "Dashboard",
    myPosts: "My Posts",
    brandSetting: "Brand Setting",
    contactMe: "Contact me",
    name: "Name",
    contactMePayment: "Contact me for payment",
    twitch: "Twitch",
    discord: "Discord",
    linkedIn: "LinkedIn",
    companyRole: "Company Role",

    paymentDetails: "Payment Details",
    ref: "Refferal Link",
    accountName: "Account Name",
    accountEmail: "Account Email",
    accountNumber: "Account Number",
    owner: "Owner",
    manager: "Manager",
    accountDetails: "Account Details",
    billing: "Billing",
    aboutUs: "About Us",
    messages: "Messages",
    emailMsg: "Invalid email address",
    emailBusinessMsg: "Email is not defined as a business email",

    nameMsg: "Full name should contain at least two characters",
    chooseService: "Choose a service type",
    videoLabel: "Promo video",
    imageLabel: "Profile picture",
    hideProfile: "Do not show my profile in the category lists",
    social: "Social network",
    link: "link",
    followers: "Followers",
    newSocial: "Add another social network",
    addService: "Add a Service",
    videoOrMeeting: "Meeting \\ Video",
    passwordNotValid: "Password must be at least six characters long",
    userNotExist: "User not exist",
    orderLink: "Link",
    campaigns: "Campaigns",
    creator: "I'm a Creator",
    advertiser: "I'm an Advertiser",
    accountUsers: "Account Users",
    withdrawalMoneyTooltip: "The minimum withdrawal is one dollar",

    copyLink: "將鏈結複製到剪貼板",
    talentBuyService: "您可以建立一個普通帳戶，然後再試一次",
    talentBuyServiceTitle: "目前未能提供服務",
    paymentInfo: "",
    wrongPassword: "密碼不正確",
    saveNewPassword: "保存新密碼",
    resetLinkSent: "重設連結發送至你的登記電郵地址",
    resetCode: "重置密碼",
    resetCodeSentToEmail: "重設連結發送至你的登記電郵地址",
    forgotMyPassword: "忘記密碼",
    retypePassword: "重新輸入密碼",
    previousPassword: "舊密碼",
    searchResult: "搜尋結果",
    newPassword: "新密碼",
    passwordError: "發生錯誤，請稍後重試",
    businessProduct: "商業客戶",
    yes: "確定",
    changeYourPassword: "更改密碼",
    productSelect: "選擇產品",
    newCoupon: "新優惠券",
    codeCoupon: "優惠卷號碼",
    datesCoupon: "日期",
    singleUse: "只能使用一次",
    passwordChangedSuccessfully: "更改密碼成功",
    expired: "過期",
    discount: "折扣",
    percentage: "百份比",
    otherTalents: "更多網紅",
    timeZoneLabel: "時間區域",
    videos: "視頻影片",
    dollarUSD: "美金",
    dollarHKD: "港元",
    currencyLabel: "貨幣",
    showMore: "顯示更多",
    updatePassword: "更新密碼",
    linkCopy: "已複製連結",

    showError: "訊息錯誤",
    notFound: "找不到相關資料",
    toBusiness: "商業查詢",
    male: "男性",
    female: "女性",
    helpPersonalVideo: "自訂個人短片",
    helpText1:
      "請於頁面填寫較為具體的述越, 描述越具體, Letstoker 就會更容易滿足你的要求. 如果你嘅影片係要送給某人, 你可以將成段說話 寫給我們, Letstoker 將會幫你演繹出來",
    helpText2: "落單時將會收取費用",
    helpText3: "Letstoker會於7天內將短片發送給你(一般會少於7天)",
    helpText4:
      "短片會於活動頁面, 手機程式或電郵顯示. 你可以下載短片, 分享或上載到自己的社交平枱",
    helpText5: "我想要一個視頻",
    helpText6:
      "在Letstok頁面上,按下會議鍵.在邀請中填寫一些個人資料以及你希望Letstok會提及什麼內容(不是強迫性，但可以幫到他/她多一點 準備)",
    helpText7: "nil",
    helpText8: "會議前24小時及會議前半小時會發送提醒",
    helpText9: "瀏覽我們",
    helpText10: "常用問題",
    helpText11: "更多資訊",
    helpText12: "希望你有一個愉快嘅體驗",
    signUpTalent: "Letstoker繼續註冊",
    backToSignUp: "返回用戶註冊",
    myProfile: "我的個人資訊",
    otherSettings: "更多設置",
    doneStepTitle: "很高興你加入我們!",
    doneStepSubTitle:
      "你的個人頁面已經成功建立, 現在你可以分享給你的粉絲, 開始營業!",
    doneStepDescription:
      "Letstok同事會先核對你嘅個人資料, 確認後將會於主頁面顯示出來",
    doneStepDescription2:
      "我們建議你填寫詳盡嘅個人資料, 以吸引更多商業查詢或粉絲訂購你的產品. 你應該設定多元化產品以及開放更多預訂日期/時間, 及不時在你嘅社交平枱分享產品連接、優惠卷等.",
    productStepSubTitle: "你日後還可以添加更多私人或商業產品",
    productStep: "你的第一個產品",
    simples: "例子",
    mediaStep: "個人專頁圖片和短片介紹",
    mediaStepSubTitle: "一個帶有創意性嘅短片及圖片可提升銷售能力,達到五倍之多",
    mustFill: "必須填寫此欄",
    usa: "United States",
    israel: "Israel",
    philipines: "International",
    hongkong: "Hong Kong",
    experiences: "體驗",
    leaders: "選擇界別",
    infoShortBio: "簡短介紹自己(例如: Youtuber, 歌手, 網紅, 專業人士, 教練等)",
    infoBio:
      "例如: 你好, 我叫Andy, 我係一個旅遊達人, 想知道更多旅遊資訊, 記得嚮Letstok book 我, 等我帶你環遊世界!",
    errorTitle: "發生錯誤",
    paymentErrorMsg: "付款交易出現錯誤, 請稍後再試",
    orderErrorMsg: "訂購時發生錯誤, 請稍候再試",
    couponText:
      "優惠券可以讓你宣傳你嘅產品. 你可以於假期, 活動或推出新產品時向粉絲們提供折扣.",
    mettingsDate: "日期設定",
    chooseDateText: "所選日期將顯示為",
    saveCoupon: "儲存優惠券",
    deleteCoupon: "刪除優惠券",
    addCoupon: "新的優惠卷",
    editCoupon: "編輯優惠券",
    displayStep: "顯示詳細內容 ",
    displayStepSubTitle: "請填寫你的資料, 我們會盡快聯絡你",
    continue: "繼續",
    urlInfo: "你的分享連結",
    skip: "略過",
    url: "網頁連結",
    activityAccept: "我同意收取Letstok上的活動信息",
    bioLabel: "自我介紹",
    shortBioLabel: "個人簡介",
    categoryLabel: "請選擇類別",
    countryLabel: "國家",
    numberLabel: "電話號碼",
    ordersRejected: "拒絕訂單",
    ordersEnd: "完成訂單",
    paddingVideo: "準備視頻",
    paddingMeetings: "已確認會議",
    waitToApprove: "等待確認",
    shekelILS: "以色列新錫克爾",
    currency: "貨幣",
    language: "語言",
    save: "儲存/Save",
    country: "城市",
    instagramLink: "Instagram連結",
    facebook: "Facebook",
    twitter: "Twitter",
    tikTokLink: "TikTok",
    youtube: "YouTube",
    usernameMsg: "只容許英文和數字",
    userExistError: "用戶存在錯誤",
    username: "用戶名",
    orderNow: "提交訂單",
    inCome: "收入",
    outCome: "提款",
    helpAndInfo: "聯絡我們",
    addProductHeaderText: "新增粉絲對你感興趣嘅話題",
    addProductHeaderTextExample:
      "個人化短片, 在線會議, 教學, 網上展覽, 網上商演",
    toOrder: "提交訂單",
    wantDonation: "我想將部份收益撥捐慈善團體",
    donationProfileInfo:
      '啟用此選項將在你的公開資料中顯示"部份收入將會捐贈慈善團體"',
    shortDescription: "簡短介紹(短句)",
    shortBio: "個人簡介",
    promoVideo: "宣傳短片",
    uploadVideo2: "上傳短片",
    type: "類別",
    displayName: "全名",
    allTalents: "全部 Letstoker",
    recommended: "Letstok 推介",
    influencers: "網紅",
    realityStars: "真人秀明星",
    tikTok: "TikTok",
    actors: "演員",
    music: "音樂",
    beautyFashion: "美容與時裝",
    sport: "運動",
    comedians: "喜劇演員",
    experts: "專家",
    date: "日期",
    action: "行動",
    sum: "總和",
    sendWithdrawal: "發出提款請求",
    activityFrom: "顯示時段",
    bankTransfer: "銀行轉帳",
    withdrawalMoney: "提款",
    withdrawalMoneyMsg: "提款請求已成功發送",
    availableWithdrawal: "可提取金額",
    totalWithdrawal: "總提款金額",
    letstokCommission: "扣除LetsTok佣金後",
    withdrawalMethod: "提款方式",
    totalMoney: "總收入",
    startOfActivity: "總收入",
    last30Day: "過去 30 天",
    currYear: "今年",
    lastYear: "上一年",
    coupon: "我的優惠券",
    personalInfo: "個人資料(內部使用)",
    displayInfo: "顯示細節",
    donationInfo: "慈善設定",
    donationInfoText: "你可以自動捐贈部分收入",
    availabilityPageText: "設定你的會面時間",
    custom: "訂製",
    allDay: "全天可用",
    allDayNot: "全天不可用",
    availabilityInDay: "可預約日期",
    saveDay: "儲存",
    specialDateTextTitle: "設置特別日期",
    specialDateTextSubTitle:
      "如果在某些時間或日子你無法讓粉絲預約, 你可以在此加上, 系統會自動顯示於你嘅日曆中",
    start: "開始",
    end: "結尾",
    addSpecialDate: "加入特別日子",
    creditNameValidation: "至少2位數字",
    monthValidation: "錯誤的月份",
    validationMsg: "無效數值",
    idValidation: "身份證資料錯誤",
    creditCartValidation: "信用卡號碼資料錯誤",
    paymentError: "付款錯誤",
    paymentMessage: "錯誤.請重試",
    approveTerms: "I approve the ",

    pay: "付款",
    creditCard: "信用卡",
    paymentMethod: "付款方法",
    paymentName: "信用卡持有人名稱",
    idOfPayment: "信用卡持有人身分証明",
    creditNumber: "信用卡號碼",
    year: "年份",
    expiration: "有效日期",
    month: "月份",
    cvvNumber: "安全號碼",
    paymentLoading: "付款進行中",
    funOrder: "多謝訂購!",
    orderResponse: "訂單已經收到 我們會盡快回覆",
    rejectedOrderQuestion: "訂單被拒絕？",
    rejectedOrderAction: "拒絕訂單",
    available: "可用",
    unAvailable: "不可用",
    saveSpecialDate: "儲存特別日子",
    startAndEndDate: "開始和結束日期",
    specialDateText: "設定一個特別的日期",
    typeProduct: "產品類別",
    titleProduct: "標題",
    descriptionProduct: "產品描述",
    durationProduct: "時間長度",
    minutesProduct: "分鐘",
    priceProduct: "產品價格",
    saveProduct: "儲存",
    deleteProduct: "刪除",
    product: "我的商品",
    searchOrder: "按姓名或訂單ID搜尋",
    availability: "可預約日期",
    availability2: "可預約日期",
    meetingWillStartIn: "The meeting will start in",
    myWallet: "我的錢包",
    orders: "我的訂單",
    status: "訂單狀態",
    reviewText: "你嘅Letstok體驗如何？請給予評論",
    personalVideo: "個人化短片",
    personalMeeting: "視像會議",
    businessVideo: "商業促銷視頻",
    businessMeeting: "會面/教學/網上商演",
    reviewDescription: "描述一下你今次的體驗",
    orderId: "訂單編號",
    approvedOrder: "批准",
    rejectedOrder: "拒絕",
    orderDescription: "訂單說明",
    publishReviewText: "填寫評論",
    requesterName: "接收人姓名",
    pendingDate: "設定日期",
    approvedDate: "到期日 ",
    createVideo: "建立你的短片",
    uploadVideo: "上載短片",
    enterToMeeting: "進入你的會議",
    addProduct: "增加產品",
    editProduct: "編輯產品",
    edit: "編輯",
    done: "完成",
    donationText: "慈善設定",
    businessVideoType: "商業短片",
    personalMeetingType: "視頻會面",
    businessMeetingType: "商業會面",
    hour4: "4小時",
    hour8: "8小時",
    hour12: "12小時",
    day1: "24小時",
    day2: "2日",
    day3: "3日",
    week: "一星期",
    notFoundOrders: "沒有任何訂單",
    english: "English",
    hebrew: "以色列文",
    sunday: "星期日",
    monday: "星期一",
    tuesday: "星期二",
    wednesday: "星期三",
    thursday: "星期四",
    friday: "星期五",
    saturday: "星期六",
    sunday2: "星期日",
    monday2: "星期一",
    tuesday2: "星期二",
    wednesday2: "星期三",
    thursday2: "星期四",
    friday2: "星期五",
    saturday2: "星期六",
    day: "天",
    hours: "小時",
    bannerText: "隨時隨地同Letstoker線上1:1互動",
    startMeetings: "開始會面",
    acceptTerms: "我已閱讀並同意使用條款及細則",
    accepted: "接受",
    acceptedDate: "確定日期",
    accountSetting: "帳户設定",
    enterMeeting: "加入會議",
    android: "Andriod",
    april: "4月",
    august: "8月",
    avgResponses: "平均回覆時間為",
    back: "返回",
    birthDate: "生日",
    blog: "日誌",
    business: "商業查詢",
    businessName: "商戶名稱",
    category: "類別",
    cleanMessage: "清理訊息",
    close: "關閉",
    codeCopen: "優惠碼",
    contactYou: "待資料核對後, 我們會盡快聯絡你",
    copyright: "© LetsTok 2022",
    december: "12月",
    description: "描述",
    donation: "慈善設定",
    terms2: "Terms and Conditions",

    donationTalent: "只顯示慈善Letstoker",
    downloadApp: "下載應用程序",
    email: "電郵",
    emailValidation: "我們已發出電郵確認, 請盡快進行核實",
    enterCopen: "輸入優惠碼",
    invite: "Invite",
    enterTimeAndDate: "選擇日期和時間",
    error: "錯誤",
    errorMsgSaveChange: "錯誤, 請重新輸入",
    eventInApp: "會面及表演只限於平台使用",
    exitAccount: "登出",
    february: "2月",
    forgotPassword: "忘記密碼",
    fullName: "全名",
    fun: "感謝你的加入!",
    haveCopen: "有優惠券?",
    help: "如何運作?",
    hi: "你好",
    home: "主頁",
    instagram: "Instagram",
    invoicing: "如需使用其他名用作開單",
    iphone: "Iphone",
    january: "1月",
    joinRecently: "最新加盟",
    july: "7月",
    june: "6月",
    keepRead: "繼續閱讀",
    leaveEmpty: "",
    letstokActivities: "確認收取有關我LetsTok帳戶上的活動通知",
    login: "登入",
    loginText: "使用電郵及密碼登入",
    march: "3月",
    may: "5月",
    meeting: "會議",
    minute: "分鐘",
    myAccount: "我的帳户",
    myFavorite: "我的最愛",
    myOrders: "我的訂單",
    newsFeedSubscribe: "收取最新資訊",
    newsfeed: "最新資訊",
    newsfeedAccept: "我有興趣接受最新加入Letstok平台嘅資訊",
    notValidEmail: "電郵格式錯誤",
    notValidNumber: "電話號碼錯誤",
    november: "11月",
    october: "10月",
    orderApproved: "訂單確定",
    orderPlaced: "已預訂",
    orderDate: "訂單日期",
    orderEnd: "訂單完成",
    orderName: "全名",
    orderOptions: "訂單選項",
    orderPadding: "等待",
    orderReject: "訂單拒絕",
    orderRequestDate: "訂單日期",
    password: "密碼",
    phone: "電話",
    phoneNumber: "電話號碼",
    price: "價格",
    private: "私隱條款",
    profit: "設定個人頁面",
    publicVideo: "我確認會議影片有機會於Letstok網頁顯示",
    publicMeeting: "我確認Letstoker準備的視頻可以出現在Letstok的頁面上",
    qa: "常見問題",
    ratings: "評分",
    receivesName: "收件人姓名",
    recentVideos: "最新影片",
    rejectedReason: "拒絕原因",
    resetPassword: "更新密碼",
    // responseTime: "回覆時間",
    responseTime: "平均回覆時間為7天",
    review: "預覽",
    saveChange: "儲存更改",
    saveChangeSuccess: "成功更改",
    searchTalent: "搜尋Letstoker",
    september: "九月",
    setDate: "會面日期",
    settings: "設定",
    share: "分享",
    shekel: "以色列貨幣",
    showAll: "顯示全部",
    signMe: "立即註冊!",
    signUp: "建立帳户",
    signUpText: "使用電郵及密碼建立帳户",
    socialLoginText: "使用社交平台登入",
    socialSignUpText: "使用社交平台建立帳户",
    someWords: "簡短介紹",
    sortByHighPrice: "價格由高至低",
    sortByLowPrice: "價格由低至高",
    sortByNew: "最新加入",
    sortByOrder: "熱門搜尋",
    talentSignUp: "Letstoker登記",
    talents: "Letstoker",
    talentsRegister: "Letstoker登記",
    talentsRegisterText: "準備好成為Letstoker?",
    terms: "使用條款",
    toPay: "付款",
    video: "影片",
    welcome: "歡迎",
    notFoundFavorite: "目前未有任何我的最愛",
    fileExamples: "自我介紹短片示範",
  },
});
