import classnames from "classnames";
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";
import ChatInput from "../../../../components/ChatInput/chatInput";
import ChatList, { IChat } from "../../../../components/ChatList/chatList";
import ChatView, { IChatView } from "../../../../components/ChatView/chatView";
import DotMenu from "../../../../components/DotMenu/dotMenu";
import SearchInput from "../../../../components/SearchInput/searchInput";
import { attachS3Url } from "../../../../helpers/s3.utils";
import "./messagesPage.scss";

export interface IMessagesPageProps {
  show: boolean;
}

const chatsContactMock = [
  {
    avatar: attachS3Url("public/docs/20220225/70930d11-0797-4356-aecf-b8bd53325696.jpg"),
    username: "Amir Geva",
    date: DateTime.fromISO("2022-04-02T09:08:34.123"),
    message:
      "Hello This is the message text up to two lines This is the message text up to two lines This is tech",
    online: true,
    unread: true,
    nonfiction: 3,
  },
  {
    avatar:
      attachS3Url("public/video/20220402/e7579f08-d291-4255-844f-f3439e40dcfc.jfif"),
    username: "Talent Name",
    date: DateTime.fromISO("2022-04-02T09:08:34.123"),
    message:
      "Hello This is the message text up to two lines This is the message text up to two lines This is tech",
    online: true,
    unread: false,
    nonfiction: 0,
  },
  {
    avatar:
      attachS3Url("public/video/20220402/7d14bf1a-967a-4225-bc14-0f1692236a1b.png"),
    username: "LetsTok Team",
    date: DateTime.fromISO("2022-04-02T09:08:34.123"),
    message:
      "Welcome to LetsTok. We are glad to have you here. Please enjoy our services",
    online: false,
    unread: true,
    nonfiction: 0,
  },
];

const MessagesPage: FC<IMessagesPageProps> = ({ show }) => {
  const [chatsContact] = useState<IChat[]>(chatsContactMock);
  const [chats, setChats] = useState<IChatView[]>([]);
  const [chatContactIndex, setChatContactIndex] = useState(-1);

  useEffect(() => {
    setChats([...[]]);
  }, [chatContactIndex]);

  return (
    <>
      <div
        className={classnames("messages-page-container", {
          display: show,
        })}
      >
        <div className="chat-box-continer">
          <div className="top-row">
            <div className="search-continer">
              <SearchInput placeholder={"Search"} />
            </div>
            <div className="header-continer">
              <div className="chat-name">
                {chatsContact[chatContactIndex]?.username || ""}
              </div>
              <DotMenu
                options={[
                  { name: "End chat", icon: <> </>, onClick: () => { } },
                  { name: "Block user", icon: <> </>, onClick: () => { } },
                  { name: "Block and report", icon: <> </>, onClick: () => { } },
                ]}
              />
            </div>
          </div>
          <div className="bottom-row">
            <div className="chat-list-container">
              <ChatList
                chats={chatsContact}
                onIndexChange={setChatContactIndex}
              />
            </div>
            <div className="chat-container">
              <div className="chat-view">
                <ChatView chats={chats} />
              </div>
              <div className="chat-input-continer">
                <ChatInput
                  onSend={(input) => {
                    setChats([
                      ...chats,
                      {
                        content: input,
                        date: DateTime.local(),
                        username: "LetsTok Team",
                      },
                    ]);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessagesPage;
