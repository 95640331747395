import classnames from "classnames";
import { useFormik } from "formik";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import AccordionInputs from "../../../../components/AccordionInputs/accordionInputs";
import Button from "../../../../components/Button/button";
import InputWithLabel from "../../../../components/InputWithLabel/inputWithLabel";
import { LocalizationContext } from "../../../../context/localization";
import { ServicesContext } from "../../../../context/services";
import { brandFormInputs, BrandFormSchema } from "./brandPage.consts";
import "./brandPage.scss";

export interface IBrandPageProps {
  show: boolean;
}

const BrandPage: FC<IBrandPageProps> = ({ show }) => {
  const { configService, organizationsService } = useContext(ServicesContext);
  const [interests, setInterests] = useState<{ id: string, value: string }[]>([]);
  const { localization, country, currency } = useContext(LocalizationContext)
  const [inputs, setInputs] = useState(brandFormInputs(configService, interests));

  
  const formik = useFormik({
    initialValues: {
      age: [18, 75],
      gender: 2,
      campaignGoal: 1,
      deadline: [14],
      influencerLevel: [2],
      budget: [10000],
      location: [],
      promoCode: [],
    },
    validate: (values) => {
    },
    validationSchema: BrandFormSchema,
    onSubmit: (values) => {
     
    },
  });

  useEffect(() => {
    (async () => {
      setInterests(await organizationsService.getInterests({ country: country, currency: currency, language: localization }))
    })()
  }, [country, currency, localization, organizationsService])

  useEffect(() => {
    setInputs(brandFormInputs(configService, interests))
  }, [configService, interests])

  const getInputs = useCallback(() => {
    const stepInputs = inputs.filter(
      (input) => !input.advanced
    );
    const stepInputsAdvance = inputs.filter(
      (input) => input.advanced
    );

    return (
      <>
        {stepInputs.map((input) => (
          <InputWithLabel
            inputKey={input.key}
            formik={formik}
            label={input.label}
            input={input.input}
            // preLabel={input.preLabel}
            // inputTransform={input.inputTransform}
          />
        ))}
        {stepInputsAdvance.length ? (
          <AccordionInputs>
            {stepInputsAdvance.map((input) => (
              <InputWithLabel
                // inputTransform={input.inputTransform}
                inputKey={input.key}
                formik={formik}
                // preLabel={input.preLabel}
                // otherInput={input.otherInput}
                label={input.label}
                input={input.input}
              />
            ))}
          </AccordionInputs>
        ) : (
          <></>
        )}
      </>
    );
  }, [formik, inputs]);

  return (
    <div
      className={classnames("brand-page-container", {
        display: show,
      })}
    >
      <div className="campaign-form">
        <form >
          {getInputs()}
          <Button
            onClick={() => {
              // if (checkValidForm()) {
              //   if (currStep === 2) {
              //     formik.handleSubmit();
              //   } else {
              //     setStep(currStep + 1);
              //   }
              // }
            }}
          >
            {"Continue"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default BrandPage;
