import { useRef, useState } from "react";
import classnames from "classnames";
import { ReactComponent as PlaySvg } from "../../../assets/IconPlay.svg";

import "./videoCard.scss";

const VideoCard = ({
  url,
  controls = false,
}: {
  url: string;
  controls?: boolean;
}) => {
  const ref = useRef<HTMLVideoElement>(null);
  const [play, setPlay] = useState<boolean>(false);

  return (
    <div className={"video-card-container"}>
      <video
        loop={false}
        controls={controls || play}
        ref={ref}
        width="100%"
        height="100%"
      >
        <source src={url} type="video/mp4" />
      </video>
      {!play ? (
        <div
          onClick={() => {
            if (ref.current?.paused) {
              ref.current?.play();
              setPlay(true);
            } else {
              ref.current?.pause();
              setPlay(false);
            }
          }}
          className={classnames("video-control", { play: play })}
        >
          {!play ? <PlaySvg width={"40px"} height={"40%"} /> : <></>}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default VideoCard;
