import { Toggle } from "rsuite";
import { IsValidEmail } from "../../../../../helpers/utils";
import { strings } from "../../../../../localizedStrings";
import { validName } from "../../../../Authentication/authentication.helpers";
import Input from "../../../../../components/Input/input";
import InputPhone from "../../../../../components/PhoneInput/phoneInput";
import Select from "../../../../../components/Select/select";
import { FC, useContext, useState } from "react";
import { ServicesContext } from "../../../../../context/services";
import { SettingsForms } from "../settings.helper";

export const ProfileInputs: FC<{
  isTalent: boolean;
  isBusiness: boolean;
  form: SettingsForms;
  setForm: (state: SettingsForms) => void;
}> = ({ form, setForm, isTalent, isBusiness }) => {
  const { configService } = useContext(ServicesContext);

  const [timeZone] = useState(configService.getTimeZonesLabel());

  return (
    <>
      {isBusiness ? (
        <Input
          customClass={"profile-input"}
          value={form.businessName.value}
          validate={validName}
          valid={form.businessName.valid}
          onChange={(value, valid) =>
            setForm({ ...form, businessName: { value, valid } })
          }
          type={"text"}
          label={strings.businessName}
        />
      ) : (
        <></>
      )}
      <Input
        customClass={"profile-input"}
        value={form.name.value}
        validate={validName}
        valid={form.name.valid}
        onChange={(value, valid) =>
          setForm({ ...form, name: { value, valid } })
        }
        type={"text"}
        label={strings.fullName}
      />
      <Input
        validCheck={false}
        type={"text"}
        customClass={"profile-input"}
        label={strings.email}
        autoComplete="username"
        value={form.mail.value}
        validate={(val) => {
          if (!val.length) {
            return { valid: true, msg: "" };
          }
          if (!IsValidEmail(val)) {
            return {
              valid: false,
              msg: strings.notValidEmail,
            };
          }
          return {
            valid: true,
            msg: "",
          };
        }}
        valid={form.mail.valid}
        onChange={(value, valid) => {
          setForm({ ...form, mail: { value, valid } });
        }}
      />
      <InputPhone
        label={strings.phone}
        customClass={""}
        required
        onChange={(value, countryCode, valid) => {
          setForm({
            ...form,
            phone: {
              value: { value: value, country: countryCode },
              valid: { valid: valid, msg: "" },
            },
          });
        }}
        country={form.phone.value.country}
        value={form.phone.value.value}
      />

      <Input
        validCheck={false}
        type={"birth-date"}
        autoComplete={"bday"}
        customClass={"profile-input"}
        placeholder={"DD/MM/YYYY"}
        value={form.birthDate.value}
        onChange={(value, valid) => {
          setForm({ ...form, birthDate: { value, valid } });
        }}
        label={strings.birthDate}
      />

      <div className={"toggle-option-container first"}>
        <div className={"toggle-text"}>{strings.letstokActivities} </div>
        <div className={"toggle-container"}>
          <Toggle
            checked={form.receiveEmail.value}
            onChange={(value) => {
              setForm({
                ...form,
                receiveEmail: { value, valid: { valid: true, msg: "" } },
              });
            }}
          />
        </div>
      </div>
      {/* <div className={"toggle-option-container"}>
        <div className={"toggle-text"}>{strings.newsfeedAccept}</div>
        <div className={"toggle-container"}>
          <Toggle />
        </div>
      </div> */}
    </>
  );
};
