import { Modal } from "rsuite";
import "./deleteAccountPopup.scss";
import CloseButton from "../CloseButton/closeButton";
import classNames from "classnames";
import { useMediaQuery } from "@react-hook/media-query";
import { useContext, useState } from "react";

import { LocalizationContext } from "../../context/localization";
import Button from "../Button/button";
import { strings } from "../../localizedStrings";

export interface IDeleteAccountPopupModalProps {
  dismiss: () => void;
  onDelete: () => Promise<void>;
  show: boolean;
}

const DeleteAccountPopupModal = ({
  dismiss,
  onDelete,
  show,
}: IDeleteAccountPopupModalProps) => {
  const mobileView = useMediaQuery("(max-width: 600px)") as boolean;
  const { isRTL } = useContext(LocalizationContext);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal
      size="xs"
      style={{
        margin: mobileView ? 0 : "80px auto 0 auto",
        maxWidth: mobileView ? "100vw" : "600px",
        minWidth: mobileView ? "100vw" : "600px",
      }}
      dialogClassName={classNames("delete-account-modal", {
        ltr: !isRTL,
      })}
      backdropClassName={"delete-popup-backdrop"}
      backdrop={true}
      show={show}
      onHide={() => { }}
    >
      <CloseButton
        onClick={() => {
          dismiss();
        }}
      />
      <div className={classNames("body", { ltr: !isRTL })}>
        <div className="title">{strings.deleteAccount}?</div>
        <div className="content">{strings.deleteAccountContent}</div>
        <Button onClick={() => {
          dismiss();
        }}>{strings.notDeleteAccount}</Button>
        <div
          onClick={async () => {
            if (!isLoading) {
              setIsLoading(true)
              await onDelete();
              setIsLoading(false)
            }
          }}
          className="link-text"
        >
          {strings.deleteAccount}
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccountPopupModal;
