import { createContext, memo, useState } from "react";
import firebaseConfigProd from "../config/firebaseConfig.prod";
import firebaseConfigDev from "../config/firebaseConfig.dev";
import AuthenticationFirebaseService from "../services/v1/authentication.firebase";
import BlogService from "../services/v2/blog/blog";
import ConfigService from "../services/v1/config";
import NewsFeedService from "../services/v2/newsFeed/newsFeed.service";
import SearchService from "../services/v2/search/search.service";
import OrdersServiceV2 from "../services/v2/orders/orders.service";
import { isEmpty } from "lodash";
import AnalyticsServices from "../services/v2/analytics/analytics.service";
import LoggerService from "../services/v2/logger/logger.service";
import TalentsServiceV2 from "../services/v2/talents/talents.service";
import UsersService from "../services/v2/users/users.service";
import { useOneEffect } from "../helpers/useLazyEffect";
import OrganizationsService from "../services/v2/organizations/organizations.service";

export const getUrls = () => {
  if (process.env.REACT_APP_NODE_ENV === "dev") {
    return {
      url: "https://api.letstok.com",
      urlSearch: "https://search-dev.letstok.com",
      urlNewsFeed: "https://newsfeed-dev.letstok.com",
      urlOrders: "https://orders-dev.letstok.com",
      urlTalents: "https://users-dev.letstok.com",
      urlOrganizations: "https://organizations-dev.letstok.com",
      cms_url: "https://cms.letstok.com",
      ui: "https://app.letstok.com",
    };
  }
  return {
    url: "https://api.letstok.com",
    urlSearch: "https://search-prod.letstok.com",
    urlNewsFeed: "https://newsfeed-prod.letstok.com",
    urlOrders: "https://orders-prod.letstok.com",
    urlTalents: "https://users-prod.letstok.com",
    urlOrganizations: "https://organizations-prod.letstok.com",
    cms_url: "https://cms.letstok.com",
    ui: "https://app.letstok.com",
  };
};

export const getFirebaseConfig = () => {
  if (process.env.REACT_APP_NODE_ENV === "dev") {
    return firebaseConfigDev;
  }
  return firebaseConfigProd;
};

export interface IServicesContext {
  searchService: SearchService;
  blogService: BlogService;
  configService: ConfigService;
  authenticationFirebaseService: AuthenticationFirebaseService;
  ordersServiceV2: OrdersServiceV2;
  analyticsServices: AnalyticsServices;
  newsFeedService: NewsFeedService;
  organizationsService: OrganizationsService;
  talentsServiceV2: TalentsServiceV2;
  usersService: UsersService;
  loggerService: LoggerService;
  setToken: (token: string) => void;
  error: {
    show: boolean;
    error?: any;
    title: string;
    message: string;
    onClose: () => void;
  };
  setError: (obj: {
    show: boolean;
    title: string;
    error: any;
    message: string;
    onClose: () => void;
  }) => void;
}
const loggerService = new LoggerService();
export const ServicesContext = createContext<IServicesContext>({
  searchService: new SearchService("", () => {}, loggerService),
  configService: new ConfigService("", () => {}, loggerService),
  usersService: new UsersService("", () => {}, loggerService),
  authenticationFirebaseService: new AuthenticationFirebaseService(
    "",
    {},
    () => {},
    loggerService
  ),
  newsFeedService: new NewsFeedService("", () => {}, loggerService),
  blogService: new BlogService("", () => {}, loggerService),
  analyticsServices: new AnalyticsServices("", () => {}, loggerService),
  talentsServiceV2: new TalentsServiceV2("", () => {}, loggerService),
  organizationsService: new OrganizationsService("", () => {}, loggerService),
  loggerService: loggerService,
  ordersServiceV2: new OrdersServiceV2(
    "",
    () => {},
    loggerService,
    new AnalyticsServices("", () => {}, loggerService)
  ),
  setToken: (token: string) => {},
  error: {
    show: true,
    title: "",
    message: "",
    error: {},
    onClose: () => {},
  },
  setError: () => {},
});

export interface IServicesContextProps {
  children: any;
}

const servicesInit = (setError: any) => {
  const {
    url,
    urlNewsFeed,
    urlOrders,
    urlSearch,
    urlTalents,
    cms_url,
    urlOrganizations,
  } = getUrls();

  console.log({
    config: {
      urls: getUrls(),
      firebase: getFirebaseConfig(),
      nodeEnv: process.env.REACT_APP_NODE_ENV,
    },
  });

  const errorHandler = (title: string, msg: string, error: any) => {
    setError({
      show: true,
      title,
      error,
      message: msg,
      onClose: () => {},
    });
  };
  const loggerService = new LoggerService();

  const authenticationFirebaseService = new AuthenticationFirebaseService(
    url,
    getFirebaseConfig(),
    errorHandler,
    loggerService
  );
  const searchService = new SearchService(
    urlSearch,
    errorHandler,
    loggerService
  );
  const analyticsServices = new AnalyticsServices(
    url,
    errorHandler,
    loggerService
  );

  const configService = new ConfigService(url, errorHandler, loggerService);
  const ordersServiceV2 = new OrdersServiceV2(
    urlOrders,
    errorHandler,
    loggerService,
    analyticsServices
  );

  const newsFeedService = new NewsFeedService(
    urlNewsFeed,
    errorHandler,
    loggerService
  );
  const talentsServiceV2 = new TalentsServiceV2(
    urlTalents,
    errorHandler,
    loggerService
  );
  const blogService = new BlogService(
    cms_url + "/graphql",
    errorHandler,
    loggerService
  );

  const usersService = new UsersService(
    urlTalents,
    errorHandler,
    loggerService
  );

  const organizationsService = new OrganizationsService(
    urlOrganizations,
    errorHandler,
    loggerService
  );

  return {
    blogService,
    configService,
    authenticationFirebaseService,
    newsFeedService,
    searchService,
    ordersServiceV2,
    analyticsServices,
    talentsServiceV2,
    usersService,
    organizationsService,
    loggerService,
  };
};

export const ServicesProvider = memo(({ children }: IServicesContextProps) => {
  const [allServices, setAllServices] = useState<any>({});
  const [error, setError] = useState({
    show: false,
    title: "",
    message: "",
    error: {},
    onClose: () => {},
  });

  useOneEffect(() => {
    const services = servicesInit(setError);
    setAllServices(services);
  }, []);

  const setToken = (token: string) => {
    const servicesV1 = [
      allServices.blogService,
      allServices.configService,
      allServices.authenticationFirebaseService,
    ];

    const servicesV2 = [
      allServices.newsFeedService,
      allServices.searchService,
      allServices.ordersServiceV2,
      allServices.analyticsServices,
      allServices.talentsServiceV2,
      allServices.usersService,
      allServices.organizationsService,
      allServices.loggerService,
    ];
    servicesV1.forEach((service) => service.setToken(token ? token : ""));
    servicesV2.forEach((service) => service.setToken(token));
  };
  if (isEmpty(allServices)) {
    return <></>;
  }
  return (
    <ServicesContext.Provider
      value={{
        setToken,
        error,
        setError,
        ...allServices,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
});
