import Service from "../../service";
import moment from "moment";
import { IServiceHeaders } from "../../service.interface";
import LoggerService from "../logger/logger.service";
import {
  IRange,
  ISchedule,
  IScheduleDay,
  IScheduleTalent,
  IDayRange,
  IExceptionalDays,
  IUserResponse,
  ICreateProductBody,
  IService,
  IProduct,
  ICoupons,
  ICreateCoupon,
} from "./talents.interface";
import { compact, flatten, isEmpty, pickBy, toLower } from "lodash";
import {
  negativeTimeOffset,
  padWithZero,
  stringToHours,
} from "../../../helpers/utils";
import { strings } from "../../../localizedStrings";
import { ITalent } from "../../v1/talents";
import { CurrencyTypes } from "../../../context/localization";
import { CountryCode } from "libphonenumber-js";
import { DateTime } from "luxon";
import { Social } from "../../../components/SocialButton/socialButton";

export default class TalentsServiceV2 extends Service {
  constructor(
    url: string,
    protected errorHandler: (title: string, msg: string, error: string) => void,
    protected LoggerService: LoggerService
  ) {
    super(url, errorHandler, LoggerService);
  }

  async getTalentSchedule(headers: IServiceHeaders): Promise<IScheduleTalent> {
    const { data } = await this.get<{ schedule: IScheduleTalent; gmt: string }>(
      `/talents/schedule/own`,
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
      },
      {}
    );
    return this.convertSchedule(
      data.schedule,
      (time: string) => {
        return `${time.split(":")[0]}:${time.split(":")[1]}`;
      },
      (time: string) => {
        return `${time.split(":")[0]}:${time.split(":")[1]}`;
      }
    );
  }

  async requestTalentWithdrawal(
    headers: IServiceHeaders,
    body: {
      email: string;
      phone: string;
      financialPlatform: number;
      amount: number;
    }
  ) {
    await this.post(
      `/talents/wallet/withdraw`,
      {
        email: body.email,
        phone: body.phone,
        financialPlatform:
          body.financialPlatform === 0 ? "bank-account" : "paypal-zelle",
        amount: body.amount,
      },
      { headers: this.attachHeaders(headers) }
    );
  }

  async updateCoupon(
    headers: IServiceHeaders,
    couponId: number,
    coupon: ICreateCoupon
  ) {
    await this.patch(
      `/talents/coupons/${couponId}`,
      {
        couponDto: {
          code: coupon.code,
          availableUntil: coupon.availableUntil,
          value: Number(coupon.value),
          type: coupon.type,
          singleServing: coupon.singleServing,
          availableFrom: coupon.availableFrom,
        },

        services: coupon.services,
      },
      {
        headers: this.attachHeaders(headers),
      }
    );
  }

  async deleteCoupon(headers: IServiceHeaders, coupon: number) {
    await this.delete<IService>(
      `talents/coupons/${coupon}`,
      {
        headers: this.attachHeaders(headers),
      },
      {}
    );
  }

  async getService(
    headers: IServiceHeaders,
    serviceId: number
  ): Promise<IService> {
    const service = await this.get<IService>(
      `/talents/services/own/${serviceId}`,
      {
        headers: this.attachHeaders(headers),
      },
      {}
    );
    return service.data;
  }

  async saveTalentSchedule(
    headers: IServiceHeaders,
    schedule: IScheduleTalent
  ) {
    const scheduleByTalentGmt = this.convertSchedule(
      schedule,
      (time: string) => {
        return time + ":00";
      },
      (time: string) => {
        return time + ":00";
      }
    );
    const response = await this.post<{
      schedule: IScheduleTalent;
      gmt: number;
    }>(
      `/talents/schedule/`,
      scheduleByTalentGmt,
      {
        headers: this.attachHeaders(headers),
      },
      {}
    );
    if (!response.ok) {
      this.errorHandler(
        strings.error,
        strings.errorMsgSaveChange,
        (response as any).response && (response as any).response.data
          ? JSON.stringify((response as any).response.data)
          : ""
      );
    }
  }

  async getTalentExceptionalDays(headers: IServiceHeaders) {
    const { data: exceptionalDay } = await this.get<IExceptionalDays[]>(
      `/talents/exceptional-days`,
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
      },
      {}
    );
    return exceptionalDay;
  }

  async getTalentScheduleByServiceId(
    headers: IServiceHeaders,
    serviceId: string,
    range: IRange,
    timezone: string
  ) {
    const [fromDate, toDate] = [
      range.fromTime.format("MM/DD/YYYY"),
      range.toTime.format("MM/DD/YYYY"),
    ];

    const { data: scheduleResponse } = await this.get<{ schedule: ISchedule }>(
      `/talents/schedule/service?serviceId=${serviceId}&fromDate=${fromDate}&toDate=${toDate}&timezone=${timezone}`,
      {
        headers: this.attachHeaders(headers),
      },
      {}
    );
    const { schedule } = scheduleResponse;
    const flattenSchedule = flatten(Object.values(schedule));
    const localSchedule = flattenSchedule.reduce((localSchedule, slot) => {
      const currTime = moment(slot);
      const day = padWithZero(currTime.local(true).date(), 2, false);
      const month = currTime.local(true).month() + 1;
      const year = currTime.local(true).year();
      const timeKey = `${day}/${month}/${year}`;
      localSchedule[timeKey] = (localSchedule[timeKey] || []).concat(slot);
      return localSchedule;
    }, {} as { [key: string]: string[] });

    const schedulePerDay = compact(
      Object.keys(localSchedule).map((day) => {
        const date = moment(day, "DD/MM/YYYY");
        if (!isEmpty(localSchedule[day])) {
          return {
            day: date.date(),
            month: date.month() + 1,
            year: date.year(),
            time: localSchedule[day].map((time) => {
              const currTime = moment(time);
              return currTime.local(true).format("HH:mm");
            }),
            fullTime: localSchedule[day],
            date: date.toDate(),
          };
        }
        return null;
      })
    );
    return schedulePerDay as IScheduleDay[];
  }

  async getTalent(
    headers: IServiceHeaders,
    userName: string,
    favorites: string[] = []
  ): Promise<ITalent | null> {
    if (!userName) {
      return null as any;
    }
    let { data: talent } = await this.get<any>(
      `/users/id/${userName}`,
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
      },
      {}
    );
    if (isEmpty(talent)) {
      return null as any;
    }
    return {
      id: talent.cognitoId,
      name: talent.fullName,
      categories: [],
      reviews: [],
      avatar: talent.talentProfile.avatar,
      shortBio: talent.talentProfile.shortBio,
      videoUrl: await this.getS3Url(talent.talentProfile.avatarVideo),
      isFavorite: false,
      bio: talent.talentProfile.bio,
      features: {
        donates: false,
        response: 0,
      },
      country: "US",
      username: talent.username,
      socialMedias: Object.entries(Social).reduce(
        (socialMedias, [socialValue, socialKey]) => {          
          if (talent.talentProfile[toLower(socialKey)]) {
            socialMedias.push({
              url: talent.talentProfile[toLower(socialKey)],
              platform: socialKey,
            });
          }
          return socialMedias;
        },
        [] as any
      ),
      products: [],
      percDonation: 0,
      videosUrls: [], //not forget
      hashtags: [],
    };
  }

  async createProduct(headers: IServiceHeaders, product: ICreateProductBody) {
    const body = {
      ...product,
      duration: product.duration ? parseInt(product.duration as any) : 1,
      example: "https://letstok.com/",
    };
    const { data } = await this.post<any>(`/talents/services`, body, {
      headers: this.attachHeaders(headers),
    });
    return data;
  }

  async updateProduct(
    headers: IServiceHeaders,
    id: string,
    product: ICreateProductBody
  ) {
    const body = pickBy({
      ...product,
      duration: product.duration ? parseInt(product.duration as any) : 1,
      example: "https://letstok.com/",
    });
    const { data } = await this.patch<any>(`/talents/services/${id}`, body, {
      headers: this.attachHeaders(headers),
    });
    return data;
  }

  async getTalentTransaction(headers: IServiceHeaders) {
    const { data } = await this.get<{
      results: {
        id: number;
        amount: number;
        type: string;
        createdAt: string;
        orderId: number;
        userId: string;
      }[];
      hasNext: boolean;
    }>(
      `/talents/wallet/transactions?size=1000&page=1`,
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
      },
      { results: [] }
    );
    return data.results;
  }

  async getTalentService(
    headers: IServiceHeaders,
    serviceId: number
  ): Promise<IProduct | null> {
    const { data: service } = await this.get<IService>(
      `/talents/services/id/${serviceId}`,
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
      }
    );
    const talent = await this.getTalent(headers, service.userProfileId, []);
    if (isEmpty(service) || isEmpty(talent)) {
      return null;
    }
    return {
      talentId: talent!.id,
      type: (service as IService).service,
      avatar: talent!.avatar,
      talentName: talent!.name,
      productName: (service as IService).subService,
      description: (service as IService).description,
      price: (service as IService).price,
      features: {
        business: (service as IService).isBusiness,
        video: (service as IService).service === "video",
        time: (service as IService).duration,
        appointment: (service as IService).service === "meeting",
      },
      duration: (service as IService).duration,
    };
  }

  async deleteProduct(headers: IServiceHeaders, serviceId: string) {
    const { data } = await this.delete<any>(`/talents/services/${serviceId}`, {
      headers: this.attachHeaders(headers),
    });
    return data;
  }

  async getTalentWallet(headers: IServiceHeaders) {
    const { data } = await this.get<{
      totalIncome: number;
      totalWithdraw: number;
      currency: CurrencyTypes;
    }>(`/talents/wallet/statistics`, {
      headers: this.attachHeaders(headers),
    });
    return {
      availableMoney: data.totalIncome - data.totalWithdraw,
      pulledMoney: data.totalWithdraw,
      totalMoney: data.totalIncome,
      currency: data.currency,
    };
  }

  async addTalentExceptionalDays(
    headers: IServiceHeaders,
    fromTime: Date,
    toTime: Date,
    isAvailable: boolean
  ) {
    return this.post(
      `/talents/exceptional-days`,
      {
        isAvailable,
        from: DateTime.fromJSDate(fromTime).toFormat("MM/dd/yyyy"),
        to: DateTime.fromJSDate(toTime).toFormat("MM/dd/yyyy"),
      },
      {
        headers: this.attachHeaders(headers),
      }
    );
  }

  async deleteTalentExceptionalDays(headers: IServiceHeaders, id: string) {
    return this.delete(
      `/talents/exceptional-days/${id}`,

      {
        headers: this.attachHeaders(headers),
      }
    );
  }

  async getCoupons(headers: IServiceHeaders): Promise<ICoupons[]> {
    const { data } = await this.get<ICoupons[]>(
      "/talents/coupons",
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
      },
      []
    );

    return data;
  }

  async createCoupon(headers: IServiceHeaders, coupon: ICreateCoupon) {
    await this.post(
      "/talents/coupons",
      {
        couponDto: {
          code: coupon.code,
          availableUntil: coupon.availableUntil,
          value: Number(coupon.value),
          type: coupon.type,
          singleServing: coupon.singleServing,
          availableFrom: coupon.availableFrom,
        },

        services: coupon.services,
      },
      {
        headers: this.attachHeaders(headers),
      }
    );
  }

  convertSchedule(
    schedule: IScheduleTalent,
    fromConvert: (time: string) => string,
    toConvert: (time: string) => string
  ) {
    return Object.entries(schedule || {}).reduce(
      (schedule, day: [string, IDayRange[]]) => {
        const [dayName, daySchedule] = day;
        (schedule as any)[dayName] = daySchedule.map((range) => {
          const convertFrom = fromConvert(range.from);
          const convertTo = toConvert(
            negativeTimeOffset(convertFrom, range.to)
          );
          return {
            from: convertFrom,
            to: convertTo,
          };
        });
        return schedule;
      },
      {} as IScheduleTalent
    );
  }
}
