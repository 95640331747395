import "./socialButton.scss";
import { ReactComponent as SvgFacebook } from "../../assets/IconFacebook.svg";
import { ReactComponent as SvgInstagram } from "../../assets/IconInstagram.svg";
import { ReactComponent as SvgTwitter } from "../../assets/IconTwitter.svg";
import { ReactComponent as SvgTikTok } from "../../assets/IconTiktok.svg";
import { ReactComponent as SvgYouTube } from "../../assets/IconYoutube.svg";
import { useContext } from "react";
import { LocalizationContext } from "../../context/localization";
import classNames from "classnames";
import { formatNumber } from "../../helpers/utils";

export enum Social {
  tiktok = "Tiktok",
  discord = 'Discord',
  linkedIn = 'LinkedIn',
  twitch = 'Twitch',
  instagram = "Instagram",
  youtube = "Youtube",
  facebook = "Facebook",
  twitter = "Twitter",
}

export interface SocialButtonProps {
  social: Social;
  count?: number;
  onClick?: () => void;
  show?: boolean;
  border?: boolean;
}

export const IconMapping = {
  [Social.tiktok]: <SvgTikTok children="icon" />,
  [Social.instagram]: <SvgInstagram children="icon" />,
  [Social.facebook]: <SvgFacebook children="icon" />,
  [Social.twitter]: <SvgTwitter children="icon" />,
  [Social.youtube]: <SvgYouTube children="icon" />,
  [Social.twitch]: <SvgYouTube children="icon" />,
  [Social.discord]: <SvgYouTube children="icon" />,
  [Social.linkedIn]: <SvgYouTube children="icon" />,

};

const SocialButton = ({
  social,
  count,
  onClick = () => { },
  show = true,
}: SocialButtonProps) => {
  const { isRTL } = useContext(LocalizationContext);
  if (!show) {
    return <></>;
  }
  return (
    <div
      onClick={onClick}
      className={classNames("social-button-container", {
        ltr: !isRTL,
        "with-text": count,
      })}
    >
      {IconMapping[social]}
      {count ? <div className="text">{formatNumber(count)}</div> : <></>}
    </div>
  );
};

export default SocialButton;
