import classnames from "classnames";

import "./ordersPage.scss";
import Order, { OrderLoading } from "../../../../components/Order/order";
import AddReview from "../../../../components/AddReview/addReview";
import { useCallback, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { ServicesContext } from "../../../../context/services";
import StackGrid from "react-stack-grid";
import { isEmpty, uniqBy } from "lodash";
import { useHistory } from "react-router-dom";
import { LocalizationContext } from "../../../../context/localization";
import { strings } from "../../../../localizedStrings";
import { removeEmptyFilters } from "../../../Talents/talents.utils";
import {
  IOrder,
  IOrdersFilters,
} from "../../../../services/v2/search/search.interfaces";
import { DateTime } from "luxon";
const initOrders = new Array(10).fill({
  status: "loading",
  talent_data: { full_name: "", avatar: "" },
  sub_service_type: { description: "" },
  ordered_at: new Date(),
});
export interface IOrdersPageProps {
  show: boolean;
}
const OrdersPage = ({ show }: IOrdersPageProps) => {
  const { searchService, ordersServiceV2 } = useContext(ServicesContext);
  const [review, setReview] = useState(false);
  const [currOrder, setOrder] = useState<IOrder | null>(null);
  const history = useHistory();
  const [orders, setOrders] = useState<IOrder[]>(initOrders);
  const [endFetch, setEndFetch] = useState<boolean>(false);
  const mobileView = useMediaQuery("(max-width: 570px)") as boolean;
  const smallView = useMediaQuery("(max-width: 1025px)") as boolean;
  const [pages, setPages] = useState<number[]>([]);
  const [done, setDone] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { localization, country, currency } = useContext(LocalizationContext);
  const [page, setPage] = useState(1);
  const [grid, setGrid] = useState<any>(null);
  useEffect(() => {
    if (!endFetch && !pages.includes(page) && page !== 0) {
      searchService
        .searchOrdersByQuery(
          { language: localization, country, currency },
          { page: page, size: 10 },
          {
            filters: removeEmptyFilters<IOrdersFilters>({
            } as any) as IOrdersFilters,
            relationToFollowingTypes: "and",
            orderBy: {
              field: "order.createdAt",
              method: "DESC",
            },
          }
        )
        .then((ordersRes) => {
          if (ordersRes.ok) {
            const orders = ordersRes.orders;
            if (isEmpty(orders)) {
              setEndFetch(true);
            } else {
              setOrders((prevOrder) =>
                uniqBy(
                  [...prevOrder, ...orders].filter(
                    (talent) => talent.status !== "loading"
                  ),
                  "id"
                )
              );
              setPages([...pages, page]);
              setTimeout(() => {
                setLoading(false);
              }, 1000);
            }
          }
        });
    }
  }, [
    country,
    currency,
    endFetch,
    grid,
    localization,
    orders,
    page,
    pages,
    searchService,
  ]);

  useEffect(() => {
    setOrders(initOrders);
    setLoading(false);
    setEndFetch(false);
    setDone(false);
    setPage(0);
    setPages([]);
  }, []);

  useEffect(() => {
    if (endFetch && !done) {
      setDone(true);
      setOrders(orders.filter((talent) => talent.status !== "loading"));
    }
  }, [done, endFetch, orders]);

  const fetchMoreOrders = useCallback(async () => {
    if (!loading) {
      setLoading(true);
      setPage(page + 1);
    }
  }, [loading, page]);

  const onScroll = useCallback(
    (e: any) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop - (smallView ? 1100 : 400) <
        e.target.clientHeight;

      if (bottom && !isEmpty(orders)) {
        fetchMoreOrders();
      }
    },
    [fetchMoreOrders, orders, smallView]
  );

  useEffect(() => {
    if (orders && orders.length) {
      window.addEventListener("scroll", onScroll, true);
    }
    return () => window.removeEventListener("scroll", onScroll, true);
  }, [onScroll, orders]);

  return (
    <>
      <AddReview
        show={review}
        onReview={async (rate, comment) => {
          if (currOrder) {
            await ordersServiceV2.postReview(
              {
                language: localization,
                country: country,
                currency: currency,
              },
              currOrder.id,
              rate,
              comment
            );
            const indexOrder = orders.findIndex(
              (order) => order.id === currOrder.id
            );
            const orderTmp = orders;
            orderTmp[indexOrder] = {
              ...orderTmp[indexOrder],
              isReviewed: true,
              orderReviews: [{ comment: comment, review: rate }],
            };
            setOrders(orderTmp);
            setOrder(null);
            setReview(false);
          }
        }}
        dismiss={() => {
          setOrder(null);
          setReview(false);
        }}
      />
      <div className={classnames("orders-page-container", { display: show })}>
        {!orders.length ? (
          <div className="orders-title-empty">{strings.notFoundOrders}</div>
        ) : (
          <></>
        )}
        {orders.length ? (
          <div className={"orders-container"}>
            {show ? (
              <StackGrid
                rtl
                gridRef={setGrid}
                className={classnames("grid", {
                  empty: !orders.filter((item: IOrder) =>
                    item.status !== "loading" ? true : true
                  ).length,
                })}
                gutterHeight={mobileView ? 1 : 12}
                columnWidth={"100%"}
              >
                {(show ? orders : ([] as any)).map(
                  (order: IOrder, index: number) =>
                    order.status === "loading" ? (
                      <OrderLoading />
                    ) : (
                      <Order
                        onReview={() => {
                          setOrder(order);
                          setReview(true);
                        }}
                        onMeeting={() => {
                          history.push(
                            `/talent/${
                              order.talentProfile.username
                                ? order.talentProfile.username
                                : order.talentProfile.userId
                            }/meeting/${order.id}`
                          );
                        }}
                        type={order.status as any}
                        talentName={order.talentProfile.fullName || ""}
                        talentImage={order.talentProfile.avatar || ""}
                        review={
                          order.isReviewed && !isEmpty(order.orderReviews)
                            ? {
                                msg: order.orderReviews[0].comment,
                                rate: order.orderReviews[0].review,
                              }
                            : undefined
                        }
                        content={
                          order.status === "delivered" &&
                          order.serviceType === "video" &&
                          !isEmpty(order.orderMaterials)
                            ? {
                                type: "video",
                                content: order.orderMaterials[0].video,
                                link: order.orderMaterials[0].link,
                              }
                            : order.serviceType === "meeting" &&
                              order.status !== "rejected"
                            ? { type: "meeting", content: null }
                            : undefined
                        }
                        productName={order!.subServiceType!.description}
                        receivedDate={
                          order.status === "delivered"
                            ? DateTime.fromISO(order!.updatedAt)
                            : undefined
                        }
                        orderDate={DateTime.fromISO(order!.orderedAt)}
                        social={
                          order.status === "delivered" &&
                          order.serviceType === "video"
                            ? {
                                facebook: "",
                                instagram: "",
                                twitter: "",
                                mail: "",
                                whatsapp: "",
                              }
                            : undefined
                        }
                        meetingDate={
                          order.status !== "delivered"
                            ? DateTime.fromISO(order!.fromTime)
                            : undefined
                        }
                      />
                    )
                )}
              </StackGrid>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default OrdersPage;
