import { useCallback, useContext, useEffect, useState } from "react";
import classnames from "classnames";
import Avatar from "../../../../components/Header/Profile/Avatar/avatar";
import { ReactComponent as SvgCamera } from "../../../../assets/IconCamera.svg";
import moment from "moment";
import "./settingsPage.scss";
import { ApplicationContext } from "../../../../context/application";
import { compact, isEmpty, isString, toLower } from "lodash";
import { ServicesContext } from "../../../../context/services";
import { AuthenticationContext } from "../../../../context/authentication";
import PopupMessage from "../../../../components/PopUpMessage/popUpMessage";
import AuthenticationRestPassword from "../../../Authentication/authentication.reset";
import { strings } from "../../../../localizedStrings";
import { LocalizationContext } from "../../../../context/localization";
import AccordionContainer from "../../../../components/AccordionContainer/accordionContainer";
import { ProfileInputs } from "./SettingsSection/profileInput";
import { TalentsInputs } from "./SettingsSection/talentInput";
import { DonationInputs } from "./SettingsSection/donationInput";
import { IProfile } from "../../../../services/v2/users/users.interface";
import { CouponInputs } from "./SettingsSection/couponInput";
import Loader from "../../../../components/Loader/loader";
import Button from "../../../../components/Button/button";
import {
  categoriesToCategoriesOptions,
  initForms,
  initFormsTalents,
  SettingsForms,
  tryGetNumber,
  updateTalentProfile,
  updateUserProfile,
} from "./settings.helper";
import { AccountInputs } from "./SettingsSection/accountInput";
import { IOrganization } from "../../../../services/v2/organizations/organizations.service";
import DeleteAccountPopupModal from "../../../../components/DeleteAccountPopup/deleteAccountPopup";
export interface ISettingsPageProps {
  show: boolean;
}

const SettingsPage = ({ show }: ISettingsPageProps) => {
  const { profile, setProfile, mode, categoriesAll } =
    useContext(ApplicationContext);
  const { logout } = useContext(AuthenticationContext);
  const [showModal, setShow] = useState<any>(false);
  const [showModalError, setShowError] = useState<any>(false);
  const [showModalErrorUser, setShowErrorUser] = useState<any>(false);
  const { isRTL, country, localization, currency } =
    useContext(LocalizationContext);
  const [passwordRestart, setPasswordRestart] = useState<any>(false);
  const [isLoading, setLoading] = useState<any>(true);
  const [upload, setUpload] = useState(false);
  const [disableModel, setDisableModel] = useState(false);
  const {
    talentsServiceV2,
    configService,
    usersService,
    organizationsService,
  } = useContext(ServicesContext);
  const [categoriesOptions, setCategoriesOptions] = useState<any>([]);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);

  const [form, setForm] = useState<SettingsForms>(initForms);

  const [formTalent, setFormTalent] = useState<any>(initFormsTalents);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 50);
  }, []);

  useEffect(() => {
    setCategoriesOptions(
      categoriesToCategoriesOptions(
        categoriesAll.filter((item) => item.country === country)
      )
    );
  }, [categoriesAll, country]);

  useEffect(() => {
    (async () => {
      if (!isEmpty(profile) && mode === "business") {
        setForm((form) => ({ ...form }));
      }
    })();
  }, [mode, profile]);

  useEffect(() => {
    (async () => {
      if (
        !isEmpty(profile) &&
        mode === "talent" &&
        (profile as IProfile).userId
      ) {
        const [localizationVersion, englishVersion] = await Promise.all([
          talentsServiceV2.getTalent(
            {
              language: toLower((profile as IProfile).language),
              currency: currency,
              country: country,
            },
            (profile as IProfile).userId,
            []
          ),
          talentsServiceV2.getTalent(
            { language: "en", currency: currency, country: country },
            (profile as IProfile).userId,
            []
          ),
        ]);
        if (!englishVersion || !localizationVersion) {
          return;
        }
        const categories = compact(
          localizationVersion.categories.map((category) =>
            categoriesOptions.find(
              (option: { id: number }) => option.id === category.id
            )
          )
        );

        setFormTalent({
          name: {
            value: localizationVersion.name,
            value_en: englishVersion.name,
            valid: { valid: true, msg: "" },
          },
          bio: {
            value: localizationVersion.bio,
            value_en: englishVersion.bio,
            valid: { valid: true, msg: "" },
          },
          username: {
            value: localizationVersion.username,
            valid: { valid: true, msg: "" },
          },
          videoPromo: {
            value: localizationVersion.videoUrl,
            value_en: "",
            valid: { valid: true, msg: "" },
          },
          bioShort: {
            value: localizationVersion.shortBio,
            value_en: englishVersion.shortBio,
            valid: { valid: true, msg: "" },
          },
          donationPercent: {
            value: !((localizationVersion?.percDonation || 0) > 0.5)
              ? 0
              : localizationVersion?.percDonation || 0,
            valid: true,
          },
          isDonation: {
            value: (localizationVersion.percDonation || 0) > 0.5,
            valid: true,
          },
          socialMedias: {
            value: localizationVersion.socialMedias,
            valid: true,
          },
          category: {
            value: categories,
            valid: true,
          },
        });
        setLoading(false);
      }
    })();
  }, [categoriesOptions, country, currency, mode, profile, talentsServiceV2]);

  useEffect(() => {
    (async () => {
      const organizations = await organizationsService.getOwnOrganizations({
        currency: currency,
        country: country,
        language: localization,
      });
      if (!isEmpty(organizations)) {
        setOrganizations(organizations);
        setForm((form) => ({
          ...form,
          businessName: {
            value: organizations[0].name,
            valid: { valid: true, msg: "" },
          },
        }));
      }
    })();
  }, [country, currency, localization, organizationsService]);

  useEffect(() => {
    if (!isEmpty(profile)) {
      const { countryInput, phoneInput } = tryGetNumber(
        configService,
        profile as IProfile,
        country
      );
      setForm((form) => ({
        businessName: {
          value: form.businessName.value,
          valid: form.businessName.valid,
        },
        mail: {
          value: (profile as IProfile).email,
          valid: { valid: true, msg: "" },
        },
        name: {
          value: (profile as IProfile).fullName,
          valid: { valid: true, msg: "" },
        },
        avatar: {
          value: (profile as IProfile).avatar,
          valid: { valid: true, msg: "" },
        },

        phone: {
          value: {
            value: phoneInput,
            country: countryInput,
          },
          valid: { valid: true, msg: "" },
        },
        birthDate: {
          value: moment((profile as IProfile).birthDate, [
            "YYYY-MM-DD",
            "DD/MM/YYYY",
            "DD-MM-YYYY",
          ]).format("DD/MM/YYYY"),
          valid: { valid: true, msg: "" },
        },
        receiveEmail: {
          value: (profile as IProfile).canReceiveEmail,
          valid: { valid: true, msg: "" },
        },
        timeZone: {
          value: (profile as IProfile).timezone
            ? (profile as IProfile).timezone
            : Intl.DateTimeFormat().resolvedOptions().timeZone,
          valid: { valid: true, msg: "" },
        },
      }));
      if (mode !== "talent") {
        setLoading(false);
      }
    }
  }, [configService, country, mode, profile]);

  const validFrom = Object.keys(form).every((key) => form[key]?.valid!.valid);
  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setForm({
        ...form,
        avatar: {
          value: img,
          valid: { valid: true, msg: "" },
        },
      });
    }
  };

  const onSubmit = useCallback(async () => {
    const { ok, updateProfile } =
      mode === "talent"
        ? await updateTalentProfile(
            {
              country: country,
              currency: currency,
              language: localization,
            },
            form,
            formTalent,
            profile as IProfile,
            usersService,
            configService
          )
        : await updateUserProfile(
            {
              country: country,
              currency: currency,
              language: localization,
            },
            form,
            profile as IProfile,
            usersService,
            configService
          );
    if (mode === "business" && organizations[0].id) {
      organizationsService.updateOrganization(
        {
          country: country,
          currency: currency,
          language: localization,
        },
        organizations[0].id,
        form["businessName"].value
      );
    }
    if (ok && updateProfile) {
      setProfile(updateProfile);
      setShow(true);
    }
  }, [
    configService,
    country,
    currency,
    form,
    formTalent,
    localization,
    mode,
    organizations,
    organizationsService,
    profile,
    setProfile,
    usersService,
  ]);

  if (isLoading) {
    return (
      <div className={"orders-loader"}>
        <Loader height={150} />
      </div>
    );
  }

  return (
    <>
      <PopupMessage
        title={strings.saveChange}
        message={strings.saveChangeSuccess}
        show={showModal}
        dismiss={() => {
          setShow(false);
        }}
      />
      <PopupMessage
        title={strings.error}
        message={strings.errorMsgSaveChange}
        show={showModalError}
        dismiss={() => {
          setShowError(false);
        }}
      />
      <PopupMessage
        title={strings.error}
        message={strings.userExistError}
        show={showModalErrorUser}
        dismiss={() => {
          setShowErrorUser(false);
        }}
      />
      <DeleteAccountPopupModal
        show={disableModel}
        onDelete={async () => {
          await usersService.deactivate({
            language: localization,
            currency: currency,
            country: country,
          });
        }}
        dismiss={() => {
          setDisableModel(!disableModel);
        }}
      />
      <AuthenticationRestPassword
        show={passwordRestart}
        dismissCallback={() => {
          setPasswordRestart(false);
        }}
      />
      <div
        className={classnames("settings-page-container", {
          display: show,
          ltr: !isRTL,
        })}
      >
        {mode !== "talent" ? (
          <div className="avatar-image-container">
            <Avatar
              url={
                isString(form.avatar.value) || !form.avatar.value
                  ? form.avatar.value
                  : URL.createObjectURL(form.avatar.value as any)
              }
            />
            <div className="image-upload-container">
              <div className="image-upload-shape-container">
                <input
                  onChange={onImageChange}
                  className="fileInput"
                  type="file"
                  accept="image/*;capture=camera"
                />
                <SvgCamera
                  color="#FFFFFF"
                  width="30px"
                  height="30px"
                  style={{ zIndex: 10, marginBottom: "10px" }}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {(profile as IProfile).fullName && mode !== "talent" ? (
          <div className={"user-name-text"}>{`${strings.hi} ${
            localization === "en"
              ? (profile as IProfile).englishName || ""
              : (profile as IProfile).fullName || ""
          }`}</div>
        ) : (
          <></>
        )}

        {mode === "talent" && (
          <>
            <AccordionContainer initOpen={true} title={strings.displayInfo}>
              <div className="profile-inputs-container">
                <TalentsInputs
                  localization={"en".toLowerCase()}
                  avatar={
                    isString(form.avatar.value) || !form.avatar.value
                      ? form.avatar.value
                      : URL.createObjectURL(form.avatar.value as any)
                  }
                  form={formTalent}
                  categoriesOptions={categoriesOptions}
                  setForm={setFormTalent}
                  setAvatar={(image: any) => {
                    setForm({
                      ...form,
                      avatar: {
                        value: image,
                        valid: { valid: true, msg: "" },
                      },
                    });
                  }}
                />
              </div>
            </AccordionContainer>
            <AccordionContainer title={strings.personalInfo}>
              <div className="profile-inputs-container">
                <ProfileInputs
                  form={form}
                  setForm={setForm}
                  isTalent
                  isBusiness={false}
                />
              </div>
            </AccordionContainer>
            {/* <AccordionContainer title={strings.donationInfo}>
              <DonationInputs form={formTalent} setForm={setFormTalent} />
            </AccordionContainer> */}
            {/* <AccordionContainer title={strings.coupon}>
              <CouponInputs />
            </AccordionContainer> */}
          </>
        )}

        <div className="profile-inputs-container">
          {mode !== "talent" && (
            <ProfileInputs
              form={form}
              setForm={setForm}
              isTalent={false}
              isBusiness={mode === "business"}
            />
          )}
        </div>
        {mode === "business" ? (
          <>
            <AccordionContainer title={strings.accountUsers}>
              <AccountInputs />
            </AccordionContainer>
          </>
        ) : (
          <></>
        )}
        <div className="profile-inputs-container">
          <Button
            disable={!validFrom || upload || isEmpty(form["phone"]?.value)}
            loading={upload}
            onClick={async () => {
              setUpload(true);
              await onSubmit();
              setUpload(false);
            }}
          >
            {strings.saveChange}
          </Button>
          <div
            onClick={() => {
              setPasswordRestart(true);
            }}
            className="link-text first"
          >
            {strings.resetPassword}
          </div>
          <div
            onClick={() => {
              logout();
            }}
            className="link-text"
          >
            {strings.exitAccount}
          </div>
          <div
            onClick={() => {
              // logout();
              setDisableModel(true);
            }}
            className="link-text"
          >
            {strings.deleteAccount}
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
