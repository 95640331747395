import "./orderConfirm.scss";
import { Modal } from "rsuite";
import CloseButton from "../../components/CloseButton/closeButton";
import { useMediaQuery } from "@react-hook/media-query";
import Avatar from "../../components/Header/Profile/Avatar/avatar";
import { ReactComponent as SvgMovie } from "../../assets/IconMovie.svg";
import { ReactComponent as SvgCalendar } from "../../assets/IconCalendar.svg";
import { ReactComponent as SvgTime } from "../../assets/IconTime.svg";
import { strings } from "../../localizedStrings";
import { isEmpty } from "lodash";
import { getStatusByType } from "../Order/order";
import { useContext, useEffect, useState } from "react";
import classnames from "classnames";
import { TailSpin } from "react-loader-spinner";
import { IOrder } from "../../services/v2/search/search.interfaces";
import { LocalizationContext } from "../../context/localization";
import { ServicesContext } from "../../context/services";
import { splitByUrl } from "../../helpers/utils";
import { DateTime } from "luxon";
import { ApplicationContext } from "../../context/application";
import { IProfile } from "../../services/v2/users/users.interface";
import CheckboxWithLabel from "../Checkbox/checkbox";
import Input from "../Input/input";

export interface IOrderConfirmProps {
  dismiss: () => void;
  show: boolean;
  order: IOrder;
  onSubmit: (file?: File | null, url?: string) => Promise<void>;
  onCancel: () => Promise<void>;
}

const OrderConfirm = ({
  dismiss,
  show,
  order,
  onSubmit = async () => {},
  onCancel = async () => {},
}: IOrderConfirmProps) => {
  const mobileView = useMediaQuery("(max-width: 600px)") as boolean;
  const [isLoadingCancel, setLoadingCancel] = useState(false);
  const [isLoadingSubmit, setLoadingSubmit] = useState(false);
  const [terms, setTerms] = useState(false);
  const [url, setUrl] = useState("");
  const { isRTL } = useContext(LocalizationContext);
  const { configService } = useContext(ServicesContext);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    setFile(null);
  }, [order]);

  if (isEmpty(order)) {
    return <></>;
  }
  return (
    <Modal
      size="xs"
      style={{
        margin: mobileView ? 0 : "0px auto 0 auto",
        maxWidth: mobileView ? "100vw" : "600px",
        minWidth: mobileView ? "100vw" : "600px",
        paddingTop: mobileView ? 0 : "30px",
      }}
      dialogClassName={classnames("order-confirm-modal", { ltr: !isRTL })}
      backdropClassName={"order-confirm-backdrop"}
      backdrop={true}
      show={show}
      onHide={() => {}}
    >
      <CloseButton
        onClick={() => {
          dismiss();
        }}
      />
      <div className="avatar-container">
        <Avatar url={order.userProfile.avatar} />
      </div>
      <div className="title">{order.userProfile.fullName}</div>
      <div className="features">
        <div className={"feature"}>
          <div className={"text-feature"}>{strings.video}</div>
          <div className={"icon"}>
            <SvgMovie
              style={{ marginBottom: "3px" }}
              width={"100%"}
              height={"100%"}
            />
          </div>
        </div>
      </div>
      <div className="order-info">
        <div className="row">
          <div className="col-container">
            <div className="col-title">{strings.status}</div>
            <div className="col-value">
              {getStatusByType(order.status as any, order.serviceType as any)}
            </div>
          </div>
          <div className="col-container">
            <div className="col-title">{strings.orderDate}</div>
            <div className="col-value">
              {configService.formatTime(
                DateTime.fromISO(order.createdAt),
                false
                // (profile as IProfile).timezone
              )}
            </div>
          </div>
          <div className="col-container">
            <div className="col-title">Age Range</div>
            <div className="col-value">
              {!isEmpty((order as any)?.campaign?.audience)
                ? `${(order as any)?.campaign?.audience[0]} - ${
                    (order as any)?.campaign?.audience[1]
                  }`
                : ""}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-container">
            <div className="col-title">{strings.price}</div>
            <div className="col-value">{`${
              (order as any).talentPrice
            }  ${configService.getCurrencySign("USD")}`}</div>
          </div>
          <div className="col-container">
            <div className="col-title">{strings.orderId}</div>
            <div className="col-value">{order.id}</div>
          </div>
          <div className="col-container">
            <div className="col-title">{"Gender"}</div>
            <div className="col-value">{(order as any)?.campaign?.gender}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-container long">
            <div className="col-title">{"Campaign instructions"}</div>
            <div className="col-value">
              {splitByUrl((order as any)?.campaign?.campaignInstructions)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-container long">
            <div className="col-title">{"About the brand"}</div>
            <div className="col-value">{splitByUrl(order.description)}</div>
          </div>
        </div>
      </div>
      <div className="order-actions">
        {order.serviceType === "video" && order.status === "accepted" ? (
          <Input
            validCheck={false}
            customClass={"profile-input"}
            label={strings.orderLink}
            value={url}
            onChange={(value, valid) => {
              setUrl(value);
            }}
          />
        ) : (
          <></>
        )}
        {order.serviceType === "video" && order.status === "accepted" ? (
          <CheckboxWithLabel
            checked={terms}
            onChecked={(state) => {
              setTerms(state);
            }}
            customLabel={() => {
              return (
                <div className="label-with-link">
                  <div className="text-box">{strings.acceptTerms}</div>
                  <a
                    href="/TermsAndConditionsTalents"
                    className="text-box link"
                  >
                    {strings.terms}
                  </a>
                </div>
              );
            }}
          />
        ) : (
          <></>
        )}
        {order.status === "offered" ||
        order.status === "accepted" ||
        (order.status === "delivered" && order.serviceType !== "video") ? (
          <>
            <div
              className={classnames("submit", {
                disable:
                  isLoadingSubmit ||
                  (order.serviceType === "video" &&
                    order.status === "accepted" &&
                    !terms),
              })}
              onClick={async () => {
                if (
                  order.status === "accepted" &&
                  order.serviceType === "video" &&
                  !file
                ) {
                  return;
                }
                if (
                  order.serviceType === "video" &&
                  order.status === "accepted" &&
                  !terms
                ) {
                  return;
                }
                if (!isLoadingSubmit) {
                  setLoadingSubmit(true);
                  await onSubmit(file, url);
                  setLoadingSubmit(false);
                }
              }}
            >
              {isLoadingSubmit ? (
                <TailSpin color="#ffffff" height={32} width={32} />
              ) : (
                <>
                  {order.status === "offered"
                    ? strings.approvedOrder
                    : order.status === "accepted" &&
                      order.serviceType === "video" &&
                      !file
                    ? strings.createVideo
                    : order.status === "accepted" &&
                      order.serviceType === "video" &&
                      file
                    ? strings.uploadVideo
                    : (order.status === "accepted" ||
                        order.status === "delivered") &&
                      order.serviceType === "meeting"
                    ? strings.enterToMeeting
                    : ""}
                  {order.status === "accepted" &&
                    order.serviceType === "video" &&
                    !file && (
                      <input
                        onChange={(e) => {
                          const files = e.target.files || [];
                          if (files.length === 1) {
                            setFile(files[0]);
                          }
                        }}
                        name="video"
                        accept="video/mp4,video/x-m4v,video/*;capture=camera"
                        type="file"
                        className="video-input"
                      />
                    )}
                </>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        {order.status === "offered" || order.status === "accepted" ? (
          <div
            onClick={async () => {
              if (!isLoadingCancel) {
                setLoadingCancel(true);
                await onCancel();
                setLoadingCancel(false);
              }
            }}
            className="click-text"
          >
            {strings.rejectedOrder}
          </div>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};

export default OrderConfirm;
