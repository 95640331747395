import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as DownloadSvg } from "../../assets/IconDownload.svg";
import { ReactComponent as EyeSvg } from "../../assets/IconEyePink.svg";
import { ReactComponent as LinkSvg } from "../../assets/IconLinkPink.svg";
import "./talentsOrderTable.scss";
import { downloadFile } from "../../helpers/utils";
import { attachS3Url } from "../../helpers/s3.utils";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import Avatar from "../BusinessHeader/Avatar/avatar";
import { IOrder } from "../../services/v2/search/search.interfaces";
import TalentPreviewModal from "../TalentPreview/talentPreview";

const TalentsOrderTable: FC<{ order: IOrder[] }> = ({ order }) => {
    return (
        <div className="talents-table">
            <TalentRow />
            {(order).map((offered) => {
                return <TalentCell order={offered} orderMaterials={offered.orderMaterials} />;
            })}
        </div>
    );
};


export const TalentRow = () => {
    return (
        <div className="talent-row">
            <div></div>
            <div>Influencer</div>
            <div>Submitted</div>
            {/* <div></div> */}
            {/* <div></div> */}
            <div></div>
        </div>
    );
};

export const TalentCell = ({
    order,
    orderMaterials,
}: {
    order: IOrder;
    orderMaterials?: {
        id: string;
        video: string;
        orderId: string;
        link: string;
    }[];
}) => {
    const [show, setShow] = useState(false);
    const history = useHistory();
    return (
        <>
            <TalentPreviewModal
                show={show}
                talent={order.talentProfile}
                dismiss={() => {
                    setShow(false);
                }}
            />
            <div className="talent-cell">
                <div
                    onClick={() => {
                        setShow(true);
                    }}
                >
                    <Avatar url={attachS3Url(order.talentProfile.avatar)} />
                </div>
                <div
                    onClick={() => {
                        setShow(true);
                    }}
                >
                    {order.talentProfile.fullName}
                </div>
                <div>
                    {DateTime.fromISO(order.updatedAt).toFormat("hh:mm dd/MM/yyyy")}
                </div>
                {/* <div>756</div> */}
                {/* <div>453</div> */}
                {!isEmpty(orderMaterials) ? (
                    <div className="icon-actions">
                        <DownloadSvg
                            onClick={() => {
                                if (orderMaterials && orderMaterials[0]) {
                                    downloadFile(attachS3Url(orderMaterials[0].video));
                                }
                            }}
                        />
                        {orderMaterials && orderMaterials[0].link ? <LinkSvg
                            onClick={() => {
                                if (orderMaterials && orderMaterials[0]) {
                                    window.open(orderMaterials[0].link, "_blank");
                                }
                            }}
                        /> : <></>}
                        <EyeSvg
                            onClick={() => {
                                if (orderMaterials && orderMaterials[0]) {
                                    history.push(`/player?path=${orderMaterials[0].video}`);
                                }
                            }}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};


export default TalentsOrderTable;
