export const defaultSchedule = {
  sunday: [],
  monday: [
    { from: "00:00", to: "01:00" },
    { from: "01:00", to: "02:00" },
    { from: "02:00", to: "03:00" },
    { from: "03:00", to: "04:00" },
    { from: "04:00", to: "05:00" },
    { from: "05:00", to: "06:00" },
    { from: "06:00", to: "07:00" },
    { from: "07:00", to: "08:00" },
    { from: "08:00", to: "09:00" },
    { from: "09:00", to: "10:00" },
    { from: "10:00", to: "11:00" },
    { from: "11:00", to: "12:00" },
    { from: "12:00", to: "13:00" },
    { from: "13:00", to: "14:00" },
    { from: "14:00", to: "15:00" },
    { from: "15:00", to: "16:00" },
    { from: "16:00", to: "17:00" },
    { from: "17:00", to: "18:00" },
    { from: "18:00", to: "19:00" },
    { from: "19:00", to: "20:00" },
    { from: "20:00", to: "21:00" },
    { from: "21:00", to: "22:00" },
    { from: "22:00", to: "23:00" },
    { from: "23:00", to: "24:00" },
  ],
  tuesday: [
    { from: "00:00", to: "01:00" },
    { from: "01:00", to: "02:00" },
    { from: "02:00", to: "03:00" },
    { from: "03:00", to: "04:00" },
    { from: "04:00", to: "05:00" },
    { from: "05:00", to: "06:00" },
    { from: "06:00", to: "07:00" },
    { from: "07:00", to: "08:00" },
    { from: "08:00", to: "09:00" },
    { from: "09:00", to: "10:00" },
    { from: "10:00", to: "11:00" },
    { from: "11:00", to: "12:00" },
    { from: "12:00", to: "13:00" },
    { from: "13:00", to: "14:00" },
    { from: "14:00", to: "15:00" },
    { from: "15:00", to: "16:00" },
    { from: "16:00", to: "17:00" },
    { from: "17:00", to: "18:00" },
    { from: "18:00", to: "19:00" },
    { from: "19:00", to: "20:00" },
    { from: "20:00", to: "21:00" },
    { from: "21:00", to: "22:00" },
    { from: "22:00", to: "23:00" },
    { from: "23:00", to: "24:00" },
  ],
  wednesday: [
    { from: "00:00", to: "01:00" },
    { from: "01:00", to: "02:00" },
    { from: "02:00", to: "03:00" },
    { from: "03:00", to: "04:00" },
    { from: "04:00", to: "05:00" },
    { from: "05:00", to: "06:00" },
    { from: "06:00", to: "07:00" },
    { from: "07:00", to: "08:00" },
    { from: "08:00", to: "09:00" },
    { from: "09:00", to: "10:00" },
    { from: "10:00", to: "11:00" },
    { from: "11:00", to: "12:00" },
    { from: "12:00", to: "13:00" },
    { from: "13:00", to: "14:00" },
    { from: "14:00", to: "15:00" },
    { from: "15:00", to: "16:00" },
    { from: "16:00", to: "17:00" },
    { from: "17:00", to: "18:00" },
    { from: "18:00", to: "19:00" },
    { from: "19:00", to: "20:00" },
    { from: "20:00", to: "21:00" },
    { from: "21:00", to: "22:00" },
    { from: "22:00", to: "23:00" },
    { from: "23:00", to: "24:00" },
  ],
  thursday: [
    { from: "00:00", to: "01:00" },
    { from: "01:00", to: "02:00" },
    { from: "02:00", to: "03:00" },
    { from: "03:00", to: "04:00" },
    { from: "04:00", to: "05:00" },
    { from: "05:00", to: "06:00" },
    { from: "06:00", to: "07:00" },
    { from: "07:00", to: "08:00" },
    { from: "08:00", to: "09:00" },
    { from: "09:00", to: "10:00" },
    { from: "10:00", to: "11:00" },
    { from: "11:00", to: "12:00" },
    { from: "12:00", to: "13:00" },
    { from: "13:00", to: "14:00" },
    { from: "14:00", to: "15:00" },
    { from: "15:00", to: "16:00" },
    { from: "16:00", to: "17:00" },
    { from: "17:00", to: "18:00" },
    { from: "18:00", to: "19:00" },
    { from: "19:00", to: "20:00" },
    { from: "20:00", to: "21:00" },
    { from: "21:00", to: "22:00" },
    { from: "22:00", to: "23:00" },
    { from: "23:00", to: "24:00" },
  ],
  friday: [],
  saturday: [],
};

export const countryNames = {
  andorra: "Andorra",
  "united arab emirates": "United Arab Emirates",
  afghanistan: "Afghanistan",
  "antigua and barbuda": "Antigua and Barbuda",
  anguilla: "Anguilla",
  albania: "Albania",
  armenia: "Armenia",
  angola: "Angola",
  antarctica: "Antarctica",
  argentina: "Argentina",
  "american samoa": "American Samoa",
  austria: "Austria",
  australia: "Australia",
  aruba: "Aruba",
  åland: "Åland",
  azerbaijan: "Azerbaijan",
  "bosnia and herzegovina": "Bosnia and Herzegovina",
  barbados: "Barbados",
  bangladesh: "Bangladesh",
  belgium: "Belgium",
  "burkina faso": "Burkina Faso",
  bulgaria: "Bulgaria",
  bahrain: "Bahrain",
  burundi: "Burundi",
  benin: "Benin",
  "saint barthélemy": "Saint Barthélemy",
  bermuda: "Bermuda",
  brunei: "Brunei",
  bolivia: "Bolivia",
  bonaire: "Bonaire",
  brazil: "Brazil",
  bahamas: "Bahamas",
  bhutan: "Bhutan",
  "bouvet island": "Bouvet Island",
  botswana: "Botswana",
  belarus: "Belarus",
  belize: "Belize",
  canada: "Canada",
  "cocos [keeling] islands": "Cocos [Keeling] Islands",
  "democratic republic of the congo": "Democratic Republic of the Congo",
  "central african republic": "Central African Republic",
  "republic of the congo": "Republic of the Congo",
  switzerland: "Switzerland",
  "ivory coast": "Ivory Coast",
  "cook islands": "Cook Islands",
  chile: "Chile",
  cameroon: "Cameroon",
  china: "China",
  colombia: "Colombia",
  "costa rica": "Costa Rica",
  cuba: "Cuba",
  "cape verde": "Cape Verde",
  curacao: "Curacao",
  "christmas island": "Christmas Island",
  cyprus: "Cyprus",
  "czech republic": "Czech Republic",
  germany: "Germany",
  djibouti: "Djibouti",
  denmark: "Denmark",
  dominica: "Dominica",
  "dominican republic": "Dominican Republic",
  algeria: "Algeria",
  ecuador: "Ecuador",
  estonia: "Estonia",
  egypt: "Egypt",
  "western sahara": "Western Sahara",
  eritrea: "Eritrea",
  spain: "Spain",
  ethiopia: "Ethiopia",
  finland: "Finland",
  fiji: "Fiji",
  "falkland islands": "Falkland Islands",
  micronesia: "Micronesia",
  "faroe islands": "Faroe Islands",
  france: "France",
  gabon: "Gabon",
  "united kingdom": "United Kingdom",
  grenada: "Grenada",
  georgia: "Georgia",
  "french guiana": "French Guiana",
  guernsey: "Guernsey",
  ghana: "Ghana",
  gibraltar: "Gibraltar",
  greenland: "Greenland",
  gambia: "Gambia",
  guinea: "Guinea",
  guadeloupe: "Guadeloupe",
  "equatorial guinea": "Equatorial Guinea",
  greece: "Greece",
  "south georgia and the south sandwich islands":
    "South Georgia and the South Sandwich Islands",
  guatemala: "Guatemala",
  guam: "Guam",
  "guinea-bissau": "Guinea-Bissau",
  guyana: "Guyana",
  "hong kong": "Hong Kong",
  "heard island and mcdonald islands": "Heard Island and McDonald Islands",
  honduras: "Honduras",
  croatia: "Croatia",
  haiti: "Haiti",
  hungary: "Hungary",
  indonesia: "Indonesia",
  ireland: "Ireland",
  israel: "Israel",
  "isle of man": "Isle of Man",
  india: "India",
  "british indian ocean territory": "British Indian Ocean Territory",
  iraq: "Iraq",
  iran: "Iran",
  iceland: "Iceland",
  italy: "Italy",
  jersey: "Jersey",
  jamaica: "Jamaica",
  jordan: "Jordan",
  japan: "Japan",
  kenya: "Kenya",
  kyrgyzstan: "Kyrgyzstan",
  cambodia: "Cambodia",
  kiribati: "Kiribati",
  comoros: "Comoros",
  "saint kitts and nevis": "Saint Kitts and Nevis",
  "north korea": "North Korea",
  "south korea": "South Korea",
  kuwait: "Kuwait",
  "cayman islands": "Cayman Islands",
  kazakhstan: "Kazakhstan",
  laos: "Laos",
  lebanon: "Lebanon",
  "saint lucia": "Saint Lucia",
  liechtenstein: "Liechtenstein",
  "sri lanka": "Sri Lanka",
  liberia: "Liberia",
  lesotho: "Lesotho",
  lithuania: "Lithuania",
  luxembourg: "Luxembourg",
  latvia: "Latvia",
  libya: "Libya",
  morocco: "Morocco",
  monaco: "Monaco",
  moldova: "Moldova",
  montenegro: "Montenegro",
  "saint martin": "Saint Martin",
  madagascar: "Madagascar",
  "marshall islands": "Marshall Islands",
  "north macedonia": "North Macedonia",
  mali: "Mali",
  "myanmar [burma]": "Myanmar [Burma]",
  mongolia: "Mongolia",
  macao: "Macao",
  "northern mariana islands": "Northern Mariana Islands",
  martinique: "Martinique",
  mauritania: "Mauritania",
  montserrat: "Montserrat",
  malta: "Malta",
  mauritius: "Mauritius",
  maldives: "Maldives",
  malawi: "Malawi",
  mexico: "Mexico",
  malaysia: "Malaysia",
  mozambique: "Mozambique",
  namibia: "Namibia",
  "new caledonia": "New Caledonia",
  niger: "Niger",
  "norfolk island": "Norfolk Island",
  nigeria: "Nigeria",
  nicaragua: "Nicaragua",
  netherlands: "Netherlands",
  norway: "Norway",
  nepal: "Nepal",
  nauru: "Nauru",
  niue: "Niue",
  "new zealand": "New Zealand",
  oman: "Oman",
  panama: "Panama",
  peru: "Peru",
  "french polynesia": "French Polynesia",
  "papua new guinea": "Papua New Guinea",
  philippines: "Philippines",
  pakistan: "Pakistan",
  poland: "Poland",
  "saint pierre and miquelon": "Saint Pierre and Miquelon",
  "pitcairn islands": "Pitcairn Islands",
  "puerto rico": "Puerto Rico",
  palestine: "Palestine",
  portugal: "Portugal",
  palau: "Palau",
  paraguay: "Paraguay",
  qatar: "Qatar",
  réunion: "Réunion",
  romania: "Romania",
  serbia: "Serbia",
  russia: "Russia",
  rwanda: "Rwanda",
  "saudi arabia": "Saudi Arabia",
  "solomon islands": "Solomon Islands",
  seychelles: "Seychelles",
  sudan: "Sudan",
  sweden: "Sweden",
  singapore: "Singapore",
  "saint helena": "Saint Helena",
  slovenia: "Slovenia",
  "svalbard and jan mayen": "Svalbard and Jan Mayen",
  slovakia: "Slovakia",
  "sierra leone": "Sierra Leone",
  "san marino": "San Marino",
  senegal: "Senegal",
  somalia: "Somalia",
  suriname: "Suriname",
  "south sudan": "South Sudan",
  "são tomé and príncipe": "São Tomé and Príncipe",
  "el salvador": "El Salvador",
  "sint maarten": "Sint Maarten",
  syria: "Syria",
  swaziland: "Swaziland",
  "turks and caicos islands": "Turks and Caicos Islands",
  chad: "Chad",
  "french southern territories": "French Southern Territories",
  togo: "Togo",
  thailand: "Thailand",
  tajikistan: "Tajikistan",
  tokelau: "Tokelau",
  "east timor": "East Timor",
  turkmenistan: "Turkmenistan",
  tunisia: "Tunisia",
  tonga: "Tonga",
  turkey: "Turkey",
  "trinidad and tobago": "Trinidad and Tobago",
  tuvalu: "Tuvalu",
  taiwan: "Taiwan",
  tanzania: "Tanzania",
  ukraine: "Ukraine",
  uganda: "Uganda",
  "u.s. minor outlying islands": "U.S. Minor Outlying Islands",
  "united states": "United States",
  uruguay: "Uruguay",
  uzbekistan: "Uzbekistan",
  "vatican city": "Vatican City",
  "saint vincent and the grenadines": "Saint Vincent and the Grenadines",
  venezuela: "Venezuela",
  "british virgin islands": "British Virgin Islands",
  "u.s. virgin islands": "U.S. Virgin Islands",
  vietnam: "Vietnam",
  vanuatu: "Vanuatu",
  "wallis and futuna": "Wallis and Futuna",
  samoa: "Samoa",
  kosovo: "Kosovo",
  yemen: "Yemen",
  mayotte: "Mayotte",
  "south africa": "South Africa",
  zambia: "Zambia",
  zimbabwe: "Zimbabwe",
};

export const orderCountries = (country: string[]) => {
  return country.reduce((countryOrder, currCountry) => {
    if (
      ["Israel", "Hong Kong", "Philippines", "United States"].includes(
        currCountry
      )
    ) {
      countryOrder.unshift(currCountry);
    } else {
      countryOrder.push(currCountry);
    }
    return countryOrder;
  }, [] as string[]);
};

export const isoCountry = {
  AF: "afghanistan",
  AX: "aland islands",
  AL: "albania",
  DZ: "algeria",
  AS: "american samoa",
  AD: "andorra",
  AO: "angola",
  AI: "anguilla",
  AQ: "antarctica",
  AG: "antigua and barbuda",
  AR: "argentina",
  AM: "armenia",
  AW: "aruba",
  AU: "australia",
  AT: "austria",
  AZ: "azerbaijan",
  BS: "bahamas",
  BH: "bahrain",
  BD: "bangladesh",
  BB: "barbados",
  BY: "belarus",
  BE: "belgium",
  BZ: "belize",
  BJ: "benin",
  BM: "bermuda",
  BT: "bhutan",
  BO: "bolivia",
  BA: "bosnia and herzegovina",
  BW: "botswana",
  BV: "bouvet island",
  BR: "brazil",
  IO: "british indian ocean territory",
  BN: "brunei darussalam",
  BG: "bulgaria",
  BF: "burkina faso",
  BI: "burundi",
  KH: "cambodia",
  CM: "cameroon",
  CA: "canada",
  CV: "cape verde",
  KY: "cayman islands",
  CF: "central african republic",
  TD: "chad",
  CL: "chile",
  CN: "china",
  CX: "christmas island",
  CC: "cocos (keeling) islands",
  CO: "colombia",
  KM: "comoros",
  CG: "congo",
  CD: "congo, democratic republic",
  CK: "cook islands",
  CR: "costa rica",
  CI: "cote d'ivoire",
  HR: "croatia",
  CU: "cuba",
  CY: "cyprus",
  CZ: "czech republic",
  DK: "denmark",
  DJ: "djibouti",
  DM: "dominica",
  DO: "dominican republic",
  EC: "ecuador",
  EG: "egypt",
  SV: "el salvador",
  GQ: "equatorial guinea",
  ER: "eritrea",
  EE: "estonia",
  ET: "ethiopia",
  FK: "falkland islands (malvinas)",
  FO: "faroe islands",
  FJ: "fiji",
  FI: "finland",
  FR: "france",
  GF: "french guiana",
  PF: "french polynesia",
  TF: "french southern territories",
  GA: "gabon",
  GM: "gambia",
  GE: "georgia",
  DE: "germany",
  GH: "ghana",
  GI: "gibraltar",
  GR: "greece",
  GL: "greenland",
  GD: "grenada",
  GP: "guadeloupe",
  GU: "guam",
  GT: "guatemala",
  GG: "guernsey",
  GN: "guinea",
  GW: "guinea-bissau",
  GY: "guyana",
  HT: "haiti",
  HM: "heard island & mcdonald islands",
  VA: "holy see (vatican city state)",
  HN: "honduras",
  HK: "hong kong",
  HU: "hungary",
  IS: "iceland",
  IN: "india",
  ID: "indonesia",
  IR: "iran, islamic republic of",
  IQ: "iraq",
  IE: "ireland",
  IM: "isle of man",
  IL: "israel",
  IT: "italy",
  JM: "jamaica",
  JP: "japan",
  JE: "jersey",
  JO: "jordan",
  KZ: "kazakhstan",
  KE: "kenya",
  KI: "kiribati",
  KR: "korea",
  KW: "kuwait",
  KG: "kyrgyzstan",
  LA: "lao people's democratic republic",
  LV: "latvia",
  LB: "lebanon",
  LS: "lesotho",
  LR: "liberia",
  LY: "libyan arab jamahiriya",
  LI: "liechtenstein",
  LT: "lithuania",
  LU: "luxembourg",
  MO: "macao",
  MK: "macedonia",
  MG: "madagascar",
  MW: "malawi",
  MY: "malaysia",
  MV: "maldives",
  ML: "mali",
  MT: "malta",
  MH: "marshall islands",
  MQ: "martinique",
  MR: "mauritania",
  MU: "mauritius",
  YT: "mayotte",
  MX: "mexico",
  FM: "micronesia, federated states of",
  MD: "moldova",
  MC: "monaco",
  MN: "mongolia",
  ME: "montenegro",
  MS: "montserrat",
  MA: "morocco",
  MZ: "mozambique",
  MM: "myanmar",
  NA: "namibia",
  NR: "nauru",
  NP: "nepal",
  NL: "netherlands",
  AN: "netherlands antilles",
  NC: "new caledonia",
  NZ: "new zealand",
  NI: "nicaragua",
  NE: "niger",
  NG: "nigeria",
  NU: "niue",
  NF: "norfolk island",
  MP: "northern mariana islands",
  NO: "norway",
  OM: "oman",
  PK: "pakistan",
  PW: "palau",
  PS: "palestinian territory, occupied",
  PA: "panama",
  PG: "papua new guinea",
  PY: "paraguay",
  PE: "peru",
  PH: "philippines",
  PN: "pitcairn",
  PL: "poland",
  PT: "portugal",
  PR: "puerto rico",
  QA: "qatar",
  RE: "reunion",
  RO: "romania",
  RU: "russian federation",
  RW: "rwanda",
  BL: "saint barthelemy",
  SH: "saint helena",
  KN: "saint kitts and nevis",
  LC: "saint lucia",
  MF: "saint martin",
  PM: "saint pierre and miquelon",
  VC: "saint vincent and grenadines",
  WS: "samoa",
  SM: "san marino",
  ST: "sao tome and principe",
  SA: "saudi arabia",
  SN: "senegal",
  RS: "serbia",
  SC: "seychelles",
  SL: "sierra leone",
  SG: "singapore",
  SK: "slovakia",
  SI: "slovenia",
  SB: "solomon islands",
  SO: "somalia",
  ZA: "south africa",
  GS: "south georgia and sandwich isl.",
  ES: "spain",
  LK: "sri lanka",
  SD: "sudan",
  SR: "suriname",
  SJ: "svalbard and jan mayen",
  SZ: "swaziland",
  SE: "sweden",
  CH: "switzerland",
  SY: "syrian arab republic",
  TW: "taiwan",
  TJ: "tajikistan",
  TZ: "tanzania",
  TH: "thailand",
  TL: "timor-leste",
  TG: "togo",
  TK: "tokelau",
  TO: "tonga",
  TT: "trinidad and tobago",
  TN: "tunisia",
  TR: "turkey",
  TM: "turkmenistan",
  TC: "turks and caicos islands",
  TV: "tuvalu",
  UG: "uganda",
  UA: "ukraine",
  AE: "united arab emirates",
  GB: "united kingdom",
  US: "united states",
  UM: "united states outlying islands",
  UY: "uruguay",
  UZ: "uzbekistan",
  VU: "vanuatu",
  VE: "venezuela",
  VN: "viet nam",
  VG: "virgin islands, british",
  VI: "virgin islands, u.s.",
  WF: "wallis and futuna",
  EH: "western sahara",
  YE: "yemen",
  ZM: "zambia",
  ZW: "zimbabwe",
};
