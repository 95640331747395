import classNames from "classnames";
import "./accordionContainer.scss";
import { ReactComponent as IconArrow } from "../../assets/IconArrow.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { LocalizationContext } from "../../context/localization";
import classnames from "classnames";

export interface IAccordionContainerProps {
  title: string;
  children: any;
  initOpen?: boolean;
  notification?: number;
  hideToggle?: boolean;
  lock?: boolean;
}

const AccordionContainer = ({
  title,
  children,
  initOpen = false,
  notification = 0,
  hideToggle = false,
  lock = false,
}: IAccordionContainerProps) => {
  const [isOpen, setOpen] = useState(initOpen);
  const { isRTL } = useContext(LocalizationContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && initOpen) {
      setTimeout(() => {
        document.querySelector(".root")?.scrollTo(0, 0);
      }, 100);
    }
  }, [ref, initOpen]);

  return (
    <>
      <div
        ref={ref}
        className={classnames("accordion-container-wrapper", { ltr: !isRTL })}
      >
        <div
          className={classnames("accordion-container", { ltr: !isRTL })}
          onClick={(e) => {
            if (!lock) {
              setOpen(!isOpen);
              e.stopPropagation();
            }
          }}
        >
          <div className="title">{title}</div>
          <div className="end-container">
            {!hideToggle ? (
              <div
                onClick={(e) => {
                  if (!lock) {
                    setOpen(!isOpen);
                    e.stopPropagation();
                  }
                }}
                className={classNames("toggle", {
                  open: isOpen,
                })}
              >
                <IconArrow
                  transform="scale (-0.5, 0.5)"
                  transform-origin="center"
                />
              </div>
            ) : (
              <></>
            )}
            {notification ? (
              <div className="notification">{notification}</div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          className={classNames("panel", {
            open: isOpen,
          })}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default AccordionContainer;
