import "./addReview.scss";
import { Modal, Rate } from "rsuite";
import CloseButton from "../../components/CloseButton/closeButton";
import Input from "../Input/input";
import { useMediaQuery } from "@react-hook/media-query";
import { useState } from "react";
import { strings } from "../../localizedStrings";

export interface IAddReviewProps {
  dismiss: () => void;
  show: boolean;
  onReview: (rate: number, comment: string) => void;
}

const AddReview = ({ dismiss, show, onReview }: IAddReviewProps) => {
  const mobileView = useMediaQuery("(max-width: 500px)") as boolean;
  const [input, setInput] = useState("");
  const [rate, setRate] = useState(0);

  return (
    <Modal
      size="sm"
      style={{ margin: mobileView ? "0" : "80px auto 0 auto" }}
      dialogClassName="review-modal"
      backdropClassName={"review-backdrop"}
      backdrop={true}
      show={show}
      onHide={() => { }}
    >
      <>
        <CloseButton
          onClick={() => {
            dismiss();
          }}
        />
        <div className="review-container">
          <div className="title">{strings.reviewText}</div>
          <Rate
            value={rate}
            onChange={(val) => setRate(val)}
            readOnly={false}
            defaultValue={0}
            size="md"
          />
          <Input
            type={"text"}
            multiline={true}
            value={input}
            onChange={(val) => {
              setInput(val);
            }}
            validCheck={false}
            placeholder=""
            customClass={"product-input multiline"}
            label={strings.reviewDescription}
          />
          <div
            className="submit-button"
            onClick={() => {
              onReview(rate, input);
              setInput("");
              setRate(0);
            }}
          >
            {strings.publishReviewText}
          </div>
        </div>
      </>
    </Modal>
  );
};

export default AddReview;
