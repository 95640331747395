import Input from "../../../../components/Input/input";
import ConfigService from "../../../../services/v1/config";
import * as Yup from "yup";
import FileDropzoneInput from "../../../../components/FileDropzoneInput/fileDropzoneInput";
import TagsInput from "../../../../components/TagsInput/tagsInput";

export const BrandFormSchema = Yup.object().shape({
  campaignLogo: Yup.array().min(1).required("Required"),
  about: Yup.string().required("Required"),
  infoLink: Yup.string().required("Required"),

  campaignTitle: Yup.string().min(1).required("Required"),
});

export const brandFormInputs = (
  configService: ConfigService,
  interests: { id: string; value: string }[]
) => [
  {
    label: "Brand name",
    key: "campaignTitle",
    required: true,
    advanced: false,
    input: (
      <Input
        validCheck={false}
        placeholder="Set a title for the influencers to see"
      />
    ),
  },
  {
    label: "Brand logo",
    key: "campaignLogo",
    required: true,
    advanced: false,
    input: <FileDropzoneInput pathS3="/tmp-folder" multiple={false} />,
  },
  {
    label: "About your brand/ product/ service",
    key: "about",
    required: true,
    advanced: false,
    input: (
      <Input
        validCheck={false}
        multiline
        customClass={"multiline"}
        placeholder="Give the influencers some background about you"
      />
    ),
  },
  {
    label: "More info link",
    key: "infoLink",
    required: true,
    advanced: false,
    input: (
      <Input
        validCheck={false}
        placeholder="Your website, blog or social media"
      />
    ),
  },
  {
    label: "Tags to attach to the submitted posts",
    key: "tags",
    required: false,
    advanced: true,
    input: (
      <TagsInput
        customClass={"multiline"}
        placeholder="Separate tags with space"
      />
    ),
  },
  {
    key: "utm",
    label: "UTM tracker (optional)",
    required: false,
    advanced: true,
    input: (
      <Input
        validCheck={false}
        placeholder="Add a link for the influencers to share"
      />
    ),
  },
];
