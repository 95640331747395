const firebaseConfig = {
  apiKey: "AIzaSyCd9X2nGWt40XJEYUR8lNv47jzTukKLi8o",
  authDomain: "letstok-dev.firebaseapp.com",
  projectId: "letstok-dev",
  storageBucket: "letstok-dev.appspot.com",
  messagingSenderId: "805841896270",
  appId: "1:805841896270:web:69c133cba048a35faa141f",
};

export default firebaseConfig;
