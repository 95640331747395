/* eslint-disable jsx-a11y/alt-text */
import "./tagsInput.scss";
import classnames from "classnames";
import { useContext, useState } from "react";
import { LocalizationContext } from "../../context/localization";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { isEmpty } from "lodash";


export interface ITagsInputProps {
    customClass?: string;
    value?: string[];
    onChange?: (val: string[]) => void;
    placeholder?: string;
    valid?: { valid: boolean; msg: string };
    errorBorder?: boolean;
}

const TagsInput = ({
    value = [],
    onChange = () => { },
    customClass,
    placeholder = "",
    valid,
    errorBorder,
}: ITagsInputProps) => {
    const { isRTL } = useContext(LocalizationContext);
    const [filter, setFilter] = useState("");

    return (
        <div
            className={classnames("tags-input-container", customClass, {
                ltr: !isRTL,
            })}
        >
            <div
                className={classnames("select", {
                    "error-border": valid && !valid.valid && errorBorder,
                    chips: true,
                })}
            >
                <div
                    className={classnames("input-text-container", { chips: true })}
                >
                    <>
                        {(value || []).map((option: any, index: number) => {
                            return (
                                <div className="chips-container">
                                    <div className="chips-text">{option}</div>
                                    <div
                                        className="chips-close"
                                        onClick={(e) => {
                                            onChange(
                                                value.filter((val, currIndex) => currIndex !== index)
                                            );
                                            e.stopPropagation();
                                        }}
                                    >
                                        <CloseSvg />
                                    </div>
                                </div>
                            );
                        })}
                        <input
                            onKeyUp={
                                (e) => {
                                    console.log(e)
                                    if (filter.trim() === "" && e.key === "Backspace") {
                                        onChange(value.slice(0,-1))
                                    }
                                    if (e.key === " ") {
                                        if (filter.trim() !== "") {
                                            onChange([...value, filter])
                                            setFilter('')
                                        }
                                    }
                                }
                            }
                            onChange={(event) => {
                                console.log(event)
                                setFilter(event.target.value);
                            }}
                            value={filter}
                            placeholder={isEmpty(value) ? placeholder : ""}
                            className="input-chips"
                        />
                    </>

                </div>
            </div>
        </div>
    );
};

export default TagsInput;
