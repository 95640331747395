import classNames from "classnames";
import { useContext } from "react";
import { LocalizationContext } from "../../context/localization";
import { formatNumber } from "../../helpers/utils";
import { IconMapping, Social } from "../SocialButton/socialButton";

import "./socialTag.scss";

const SocialTag = ({
  platform,
  followers,
  url,
}: {
  platform: Social;
  followers?: number;
  url?: string;
}) => {
  const { isRTL } = useContext(LocalizationContext);
  return (
    <div
      className={classNames("social-continer", { ltr: !isRTL })}
      onClick={() => {
        if (url) {
          window.open(url, "_blank");
        }
      }}
    >
      <div className="social-icon">{IconMapping[platform]}</div>
      {followers ? (
        <div className="social-followers">{formatNumber(followers)}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SocialTag;
