import { isEmpty } from "lodash";
import TagManager from "react-gtm-module";
import Service from "../../service";
import { IOrder } from "../orders/orders.interface";

export default class AnalyticsServices extends Service {
  placeOrderEvent(request: IOrder) {
    TagManager.dataLayer({
      dataLayer: { ecommerce: null },
    });
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          checkout: {
            actionField: { step: 1, option: "Visa" },
            products: [
              {
                name: request.order.serviceType,
                id: request.order.id,
                // price: request.payment ? request.payment.price : 0,
                brand: request.order.talentName,
                category: request.order.serviceType,
                variant: request.order.serviceType,
                quantity: 1,
                coupon: "",
                currency: "ILS",
              },
            ],
          },
        },
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: "service-atc",
        influencer: request.order.talentName,
        service: request.order.serviceType,
        // "service-value": request.payment ? request.payment.price : 0,
      },
    });
  }

  payOrderEvent(request: IOrder) {
    TagManager.dataLayer({
      dataLayer: {
        event: "purchase-complete",
        influencer: request.order.talentName,
        service: request.order.serviceType,
        // "service-value": request.payment ? request.payment.price : 0,
      },
    });
    TagManager.dataLayer({
      dataLayer: { ecommerce: null },
    });
    if (request.order) {
      TagManager.dataLayer({
        dataLayer: {
          ecommerce: {
            purchase: {
              actionField: {
                id: request.order.id ? request.order.id : 0, // Transaction ID. Required for purchases and refunds.
                affiliation: "Letstok",
                // revenue: request.payment ? request.payment.price : 0, // Total transaction value (incl. tax and shipping)
                tax: 0,
                shipping: 0,
              },
              products: isEmpty(request)
                ? []
                : [
                    {
                      name: request.order.serviceType, // Name or ID is required.
                      id: request.order.id ? request.order.id : 0, // Transaction ID. Required for purchases and refunds.
                      // price: request.payment ? request.payment.price : 0,
                      brand: request.order.talentName,
                      category: request.order.serviceType,
                      variant: request.order.serviceType,
                      quantity: 1,
                      coupon: "",
                      currency: "ILS",
                    },
                  ],
            },
          },
        },
      });
    }
  }
}