import classnames from "classnames";
import { FC, useState } from "react";
import SearchInput from "../../../../components/SearchInput/searchInput";
import { strings } from "../../../../localizedStrings";
import { ReactComponent as DownloadSvg } from "../../../../assets/IconDownload.svg";

import "./billingPage.scss";

export interface IBillingPageProps {
  show: boolean;
}

const BillingPage: FC<IBillingPageProps> = ({ show }) => {
  const [, setInput] = useState<string>("");

  return (
    <>
      <div
        className={classnames("billing-page-container", {
          display: show,
        })}
      >
        <SearchInput
          placeholder="Search"
          onChange={(input) => {
            setInput(input);
          }}
        />
        <div className="billing-page-content">
          <div className="header-title">
            <div>{strings.created}</div>
            <div>{strings.type}</div>
            <div>{strings.for}</div>
            <div>{strings.amount}</div>
            <div>{strings.transaction}</div>
            <div></div>

          </div>
          {[{}, {}, {}].map((item: any) => {
            return (
              <div
                className="order-row"
                onClick={() => {
                  // onClickOrders && onClickOrders(item);
                }}
              >
                <div>01/01/2022</div>
                <div>{"Invoice"}</div>
                <div>{"Silver subscription plan"}</div>
                <div>{"$12345 USD"}</div>
                <div>{"1234567890"}</div>
                <div>
                  <DownloadSvg width={"24px"} />
                </div>

              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BillingPage;
