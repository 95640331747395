import "./chatInput.scss";

import { FC, useContext, useState } from "react";
import classnames from "classnames";
import { LocalizationContext } from "../../context/localization";
import Button from "../Button/button";

const ChatInput: FC<{
  onSend: (message: string) => void;
}> = ({ onSend }) => {
  const { isRTL } = useContext(LocalizationContext);
  const [input, setInput] = useState("");

  const submit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      setInput("");
      onSend(input);
    }
  };
  return (
    <div className="input-message">
      <div className={classnames("messages-input", { ltr: !isRTL })}>
        <div className="messages-input-container">
          <input
            value={input}
            onChange={(event) => {
              setInput(event.target.value);
            }}
            dir={isRTL ? "rtl" : "ltr"}
            onKeyDown={(e) => submit(e)}
            placeholder={"Type your message"}
          ></input>
        </div>
      </div>
      <div className="actions-buttons">
        {/* <Button onClick={() => {}}>Send</Button> */}
        <Button
          onClick={() => {
            setInput("");
            onSend(input);
          }}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default ChatInput;
