import { CurrencyTypes } from "../../../context/localization";
import { ICampaignObject } from "../../../pages/Business/CampaignGenerator/CampaignForm/campaignForm";
import {
  IServiceTypes,
  IOrderStatus,
  IGender,
} from "../orders/orders.interface";
import { ISocialMedia } from "../talents/talents.interface";

export interface ITalentsFilters {
  categories?: string[];
  orderType?: string[];
  price?: { start: number; end: number };
  responseTime?: string[];
  donates?: boolean;
  hashtags?: string[];
  username?: string[];
  englishName?: string;
  name?: string;
}

export interface ITalentsSearch {
  userId?: string;
  bio?: string;
  avatar: string;
  twitter?: string;
  instagram?: string;
  fullName?: string;
  donation?: true;
  hashtags?: string[];
  responseTime?: string[];
  social: ISocialMedia[];
  talentCategories: { id: number; categoriesId: number }[];
  instagramFollowers: number;
  youtubeFollowers: number;
  facebookFollowers: number;
  tiktokFollowers: number;
  twitterFollowers: number;
  shortBio?: string;
  updatedAt?: string;
  englishName?: string;
  avatarVideo?: string;
  percOfDonation?: number;
  bioEn?: string;
  shortBioEn?: string;
  facebook?: string;
  youtube?: string;
  tiktok?: string;
  username?: string;
  country?: string;
  rating?: number;
}

export interface IUserSearch {
  userId: string;
  bio: string;
  avatar: string;
  twitter: string;
  instagram: string;
  fullName: string;
  donation: string;
  hashtags: string;
  responseTime: string;
  shortBio: string;
  createdAt: string;
  updatedAt: string;
  englishName: string;
  username: string;
  avatarVideo: string;
  percOfDonation: string;
  bioEn: string;
  shortBioEn: string;
  facebook: string;
  youtube: string;
  tiktok: string;
  country: string;
  starred: string;
  rating: string;
  timezone: string;
  language: string;
  currency: string;
  agentEmail: string;
  shouldReceiveRegistrationNotification: string;
  registrationNotificationStage: string;
  notifications: string;
  profileUrl: string;
}

export interface IOrder {
  id: number;
  requesterName: string;
  receiverName: string;
  serviceType: IServiceTypes;
  description: string;
  isAgree: boolean;
  addressType: any;
  talent: string;
  userId: string;
  status: IOrderStatus;
  fromTime: string;
  toTime: string;
  isPaymentSuccess: boolean;
  isBusiness: boolean;
  subServiceTypeId: number;
  isReviewed: boolean;
  type: string;
  createdAt: string;
  updatedAt: string;
  paymentPaypalId: null;
  descriptionEn: string;
  orderedAt: string;
  mediauploadedAt: string;
  genderCustomer: IGender;
  genderReceiver: IGender;
  price: number;
  currency: CurrencyTypes;
  subServiceType: ISubServiceType;
  campaign?: ICampaignObject;
  orderMaterials: {
    id: string;
    video: string;
    orderId: string;
    link: string;
  }[];
  orderReviews: {
    id?: string;
    review: number;
    comment: string;
    createdAt?: string;
    orderId?: string;
    createdById?: string;
  }[];
  talentProfile: ITalentsSearch;
  userProfile: IUserSearch;
}

export interface ISubServiceType {
  id: number;
  service: IServiceTypes;
  price: number;
  userId: string;
  userProfileId: string;
  description: string;
  example: null;
  subService: string;
  isBusiness: false;
  duration: number;
}

export interface IBaseFilters {
  and: object;
  or: object;
}

export interface ITalentsOrdersFilters extends IBaseFilters {
  id?: string;
  serviceType?: "meeting" | "video";
  userId?: string;
  isBusiness: boolean;
  isPaymentSuccess: boolean;
  status: IStatus;
}

export interface IOrdersFilters extends IBaseFilters {
  id?: number;
  serviceType?: "meeting" | "video";
  classification?: "service" | "campaign";
  userId?: string;
  isBusiness?: boolean;
  isPaymentSuccess?: boolean;
  requesterName?: string;
  receiverName?: string;
  status?: IStatus[];
}

export type IStatus =
  | "offered"
  | "pending"
  | "dispute"
  | "submitted"
  | "accepted"
  | "rejected"
  | "delivered"
  | "posted"
  | "completed"
  | "approved";

export enum ResourceTypes {
  Talents = "Talents",
  UserOrders = "UserOrders",
  TalentOrders = "TalentOrders",
}
