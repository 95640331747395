import classnames from "classnames";
import { TailSpin } from "react-loader-spinner";
import ReactTooltip from "react-tooltip";

import "./button.scss";

const Button = ({
  onClick,
  children,
  showTooltip,
  tooltipText = '',
  disable = false,
  loading = false,
  customClass = "",
}: {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: any;
  disable?: boolean;
  loading?: boolean;
  tooltipText?: string
  showTooltip?: boolean
  customClass?: string;
}) => {
  return (
    <> {showTooltip ? <ReactTooltip
      arrowColor={"transparent"}
      backgroundColor="#D1CECE"
      textColor="#222222"
      id={"6"}
    /> : <></>}
      <div
        onClick={async (e) => {
          if (!disable) {
            await onClick(e);
          }
        }}
        data-tip={tooltipText}data-for={"6"}
        className={classnames("button", customClass, { disable })}
      >
        {loading ? (
          <TailSpin color="#ffffff" height={30} width={30} />
        ) : (
          children
        )}
      </div>
    </>
  );
};

export default Button;
