import classnames from "classnames";
import { FC, useContext, useState } from "react";
import { ReactComponent as IconSearch } from "../../assets/IconSearch.svg";
import { LocalizationContext } from "../../context/localization";
import "./searchInput.scss";

export interface ISearchInputProps {
  onSubmit?: (value: string) => void;
  onChange?: (value: string) => void;
  placeholder?: string;
}

const SearchInput: FC<ISearchInputProps> = ({
  onSubmit = () => {},
  onChange = () => {},
  placeholder,
}) => {
  const [input, setInput] = useState<string>("");
  const { isRTL } = useContext(LocalizationContext);

  const submit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      onSubmit(input);
    }
  };
  return (
    <div className={classnames("search-input", { ltr: !isRTL })}>
      <div className="search-input-container">
        <div
          onClick={() => {
            onSubmit(input);
          }}
          className="search-icon-container"
        >
          <IconSearch className="search-icon" />
        </div>
        <input
          onChange={(event) => {
            setInput(event.target.value);
            onChange(event.target.value);
          }}
          dir={isRTL ? "rtl" : "ltr"}
          onKeyDown={(e) => submit(e)}
          placeholder={placeholder}
        ></input>
      </div>
    </div>
  );
};

export default SearchInput;
