import { useCallback, useContext, useRef, useState } from "react";
import classnames from "classnames";
import "./talentOrdersPage.scss";
import { LocalizationContext } from "../../../../context/localization";
import AccordionOrders from "./AccordionOrders/accordionOrders";
import OrderConfirm from "../../../../components/OrderConfirm/orderConfirm";
import { ServicesContext } from "../../../../context/services";
import { useHistory } from "react-router-dom";
import RejectConfirm from "../../../../components/RejectConfirm/rejectConfirm";
import { strings } from "../../../../localizedStrings";
import { IOrder } from "../../../../services/v2/search/search.interfaces";
import SearchInput from "../../../../components/SearchInput/searchInput";

export interface ITalentOrdersPageProps {
  show: boolean;
}

const TalentOrdersPage = ({ show }: ITalentOrdersPageProps) => {
  const { isRTL, localization, country, currency } =
    useContext(LocalizationContext);
  const { ordersServiceV2 } = useContext(ServicesContext);
  const [input, setInput] = useState<string>("");
  const [currOrder, setCurrOrder] = useState<IOrder | null>(null);
  const [showOrderModal, setShowOrderModal] = useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const reloadRef = useRef<(() => void)[]>([]);
  const history = useHistory();

  const reFetch = useCallback(async () => {
    setCurrOrder(null);
    if (reloadRef.current) {
      reloadRef.current.forEach((func) => {
        func();
      });
    }
  }, [reloadRef]);
  return (
    <>
      {currOrder ? (
        <OrderConfirm
          onSubmit={async (file, url) => {
            if (currOrder.status === "offered") {
              await ordersServiceV2.changeOrder(
                {
                  language: localization,
                  country: country,
                  currency: currency,
                },
                currOrder.id,
                "accepted"
              );
            }
            if (
              currOrder.status === "accepted" ||
              currOrder.status === "delivered"
            ) {
              if (file) {
                await ordersServiceV2.uploadOrderVideo(
                  {
                    language: localization,
                    country: country,
                    currency: currency,
                  },
                  currOrder.id,
                  file,
                  url || ""
                );
              }
              if (currOrder.serviceType === "meeting") {
                history.push(
                  `/talent/${currOrder.talentProfile.username}/meeting/${currOrder.id}`
                );
              }
            }
            setShowOrderModal(false);
            reFetch();
            return;
          }}
          onCancel={async () => {
            setShowOrderModal(false);
            setShowRejectModal(true);
          }}
          show={showOrderModal}
          order={currOrder as IOrder}
          dismiss={() => {
            setShowOrderModal(false);
          }}
        />
      ) : (
        <></>
      )}
      <RejectConfirm
        onSubmit={async () => {
          if (currOrder) {
            await ordersServiceV2.changeOrder(
              {
                language: localization,
                country: country,
                currency: currency,
              },
              currOrder.id,
              "rejected"
            );
            setShowRejectModal(false);
            setShowOrderModal(false);
            reFetch();
          }
        }}
        show={showRejectModal}
        dismiss={() => {
          setShowRejectModal(false);
          setShowOrderModal(false);
        }}
      />
      <div
        className={classnames("talent-orders-page-container", {
          display: show,
          ltr: !isRTL,
        })}
      >
        <SearchInput
          onChange={(input) => {
            setInput(input);
          }}
          placeholder={strings.searchOrder}
        />
        <AccordionOrders
          title={strings.waitToApprove}
          status={["offered"]}
          key={"offered"}
          search={input}
          reloadEvent={reloadRef}
          onClickOrders={(order: IOrder) => {
            setCurrOrder(order);
            setShowOrderModal(true);
          }}
        />

        <AccordionOrders
          title={strings.paddingVideo}
          status={["accepted"]}
          key={"accepted-video"}
          serviceType={"video"}
          search={input}
          reloadEvent={reloadRef}
          onClickOrders={(order: IOrder) => {
            setCurrOrder(order);
            setShowOrderModal(true);
          }}
        />
        <AccordionOrders
          showCount={false}
          title={strings.ordersEnd}
          status={["posted", "submitted", "completed", "approved"]}
          key={"submitted"}
          search={input}
          reloadEvent={reloadRef}
          onClickOrders={(order: IOrder) => {
            setCurrOrder(order);
            setShowOrderModal(true);
          }}
        />
        <AccordionOrders
          showCount={false}
          title={strings.ordersRejected}
          status={["rejected", "dispute"]}
          key={"rejected"}
          search={input}
          reloadEvent={reloadRef}
          onClickOrders={(order: IOrder) => {
            setCurrOrder(order);
            setShowOrderModal(true);
          }}
        />
      </div>
    </>
  );
};

export default TalentOrdersPage;
