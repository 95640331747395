import classnames from "classnames";
import { compact, isEmpty, min, toInteger } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { ApplicationContext } from "../../../../context/application";
import { ServicesContext } from "../../../../context/services";
import Card from "../../../../components/Card/card";
import StackGrid from "react-stack-grid";

import "./favoritesPage.scss";
import { useMediaQuery } from "@react-hook/media-query";
import { LocalizationContext } from "../../../../context/localization";
import { strings } from "../../../../localizedStrings";
import {
  initTalentsCategories,
  initTalentsFavoritesCategories,
  ITalent,
} from "../../../../services/v1/talents";
import { IProfile } from "../../../../services/v2/users/users.interface";

export interface IFavoritesPageProps {
  show: boolean;
}
type TalentsData = ITalent & { reviewsScore?: number; lowPrice?: number };
const FavoritesPage = ({ show }: IFavoritesPageProps) => {
  const { profile } = useContext(ApplicationContext);
  const mobileView = useMediaQuery("(max-width: 1025px)") as boolean;
  const { localization, country, currency, isRTL } =
    useContext(LocalizationContext);
  const { talentsServiceV2, configService } = useContext(ServicesContext);
  const [talents, setTalents] = useState<TalentsData[]>(
    initTalentsCategories as any
  );
  const [favorites, setFavorites] = useState(true);

  const gridRef = useRef<any>(null);
  useEffect(() => {
    setTalents(initTalentsFavoritesCategories);
    setFavorites(false);
    if (!isEmpty(profile)) {
      const favorites = ((profile as IProfile).favorites || []).map(
        (favorites: IProfile) => {
          return favorites.userId;
        }
      );
      Promise.all(
        favorites.map(async (talentId) => {
          return talentsServiceV2.getTalent(
            { language: localization, country: country, currency: currency },
            talentId,
            []
          );
        })
      ).then((talents) => {
        setTalents(
          compact(talents || []).map((talent) => {
            return {
              ...talent,
              lowPrice:
                min(
                  talent.products.map(
                    (product: { price: any }) => product.price
                  )
                ) || 0,
              reviewsScore: toInteger(
                talent.reviews.length
                  ? (
                      talent.reviews.reduce(
                        (rating: any, reviews: { rating: any }) => {
                          return rating + reviews.rating;
                        },
                        0
                      ) / talent.reviews.length
                    ).toFixed(1)
                  : 0
              ),
            };
          }) as any
        );
        setFavorites(true);
      });
    }
  }, [
    configService,
    country,
    currency,
    localization,
    profile,
    talentsServiceV2,
  ]);

  return (
    <>
      <div
        className={classnames("favorites-page-container", { display: show })}
      >
        {!talents.length ? (
          <div className="favorites-title-empty">
            {strings.notFoundFavorite}
          </div>
        ) : (
          <></>
        )}
        {talents.length ? (
          <div ref={gridRef} className={"catalog-container"}>
            {show ? (
              <StackGrid
                rtl={isRTL}
                className={"grid"}
                gutterHeight={8}
                columnWidth={mobileView ? 173 : 266}
              >
                {(show ? talents : ([] as any)).map(
                  (talent: TalentsData, index: number) => (
                    <Card
                      social={talent.socialMedias}
                      id={talent.id}
                      key={index}
                      country={talent.country}
                      username={talent.username}
                      title={talent.name}
                      description={talent.shortBio}
                      price={talent.lowPrice || 0}
                      review={talent.reviewsScore || 0}
                      donation={talent?.features?.donates}
                      favorite={favorites}
                      imageUrl={talent.avatar}
                    />
                  )
                )}
              </StackGrid>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default FavoritesPage;
