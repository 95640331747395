import { pickBy } from "lodash";
import moment from "moment";
import { LogLevel } from "./logger.interface";
import Bowser from "bowser";

var logger = require("logzio-nodejs").createLogger({
  token: "MlKnkkKwZYTFnacjYfbGTdVLCXbxnSfo",
  protocol: "https",
  host: "listener.logz.io",
  port: "8071",
  type: "web",
});

export default class LoggerService {
  logger(level: LogLevel, payload: any) {
    logger.log(this.formatLog(level, payload));
  }

  getUserTime() {
    const now = moment();
    return now.toISOString(true);
  }

  getUser() {
    return localStorage.getItem("user_email") || "";
  }

  getBrowserInfo() {

    return Bowser.parse(window.navigator.userAgent)
  }
  getLocalizationInfo() {
    return Intl.DateTimeFormat().resolvedOptions();
  }

  public getToken() {
    return localStorage.getItem("token");
  }

  setToken(){

  }
  
  formatLog(level: LogLevel, payload: any) {
    return {
      level: level,
      payload: pickBy(payload),
      metadata: {
        time: this.getUserTime(),
        user: this.getUser(),
        environment:process.env.REACT_APP_NODE_ENV,
        token: this.getToken(),
        browser: this.getBrowserInfo(),
        userLocalization: this.getLocalizationInfo(),
      },
    };
  }
}
