import "./stars.scss";
import { ReactComponent as SvgStarGold } from "../../../assets/IconStarGold.svg";
import { toInteger } from "lodash";

export interface IStar {
  starts: number;
}

const Stars = ({ starts }: IStar) => {
  return (
    <div className={"star-wrapper"}>
      {new Array(toInteger(starts.toFixed())).fill(starts).map((val, index) => {
        return <SvgStarGold key={index} />;
      })}
    </div>
  );
};

export default Stars;
