import { CancelTokenSource } from "axios";
import Service from "../../service";
import {
  IServiceHeaders,
  IServicePagination,
  ISortBy,
  OpTypes,
  RelationToFollowingTypes,
} from "../../service.interface";
import {
  IOrder,
  IOrdersFilters,
  ITalentsFilters,
  ITalentsSearch,
  ResourceTypes,
} from "./search.interfaces";

export default class SearchService extends Service {
  async searchTalentsByQuery(
    headers: IServiceHeaders,
    pagination: IServicePagination,
    query: {
      filters: ITalentsFilters;
      orderBy: {
        field: string;
        method: ISortBy;
      };
      relationToFollowingTypes: RelationToFollowingTypes;
    },
    cancelToken?: CancelTokenSource
  ): Promise<ITalentsSearch[]> {
    const filtersRemoveEmptyKeys = this.removeEmptyFilters(query.filters);
    const queryReq = {
      alias: "profile",
      relations: ["talentServicePrices", "talentCategories"],
      orderBy: query.orderBy,
      queries: this.buildQuery(
        filtersRemoveEmptyKeys,
        query.relationToFollowingTypes,
        ResourceTypes.Talents
      ),
    };
    const { data: response } = await this.post<{
      hasNext: boolean;
      results: ITalentsSearch[];
    }>(
      `/profile/search?page=${pagination.page}&size=${pagination.size}`,
      queryReq,
      {
        headers: this.attachHeaders(headers),
        cancelToken: cancelToken ? cancelToken.token : undefined,
      },
      { results: [] }
    );
    return Promise.all(
      response.results.map(async (talent: { avatar: string }) => {
        return Object.assign(talent, {
          avatar: await this.getS3Url(talent.avatar),
          social: this.attachSocialMedia(talent),
        });
      })
    ) as Promise<ITalentsSearch[]>;
  }

  async searchOrdersByQuery(
    headers: IServiceHeaders,
    pagination: IServicePagination,
    query: {
      filters: IOrdersFilters;
      orderBy: {
        field: string;
        method: ISortBy;
      };
      relationToFollowingTypes: RelationToFollowingTypes;
    },
    cancelToken?: CancelTokenSource
  ): Promise<{ ok: boolean; orders: IOrder[] }> {
    if (!this.getToken()) {
      return { ok: false, orders: [] };
    }
    const filtersRemoveEmptyKeys = this.removeEmptyFilters(query.filters);
    const queryReq = {
      alias: "order",
      relations: ["talentProfile", "orderMaterials"],
      orderBy: query.orderBy,
      queries: this.buildQuery(
        filtersRemoveEmptyKeys,
        query.relationToFollowingTypes,
        ResourceTypes.UserOrders
      ),
    };
    const { data: response } = await this.post<{
      hasNext: boolean;
      results: IOrder[];
    }>(
      `/user-orders/search?page=${pagination.page}&size=${pagination.size}`,
      queryReq,
      {
        headers: this.attachHeaders(headers),
        cancelToken: cancelToken ? cancelToken.token : undefined,
      },
      { results: [] }
    );
    response.results = await Promise.all(
      response.results.map(async (result) => {
        return Object.assign(result, {
          talentProfile: Object.assign(result.talentProfile, {
            avatar: result.talentProfile.avatar
              ? this.getS3Url(result.talentProfile.avatar)
              : null,
          }),
        });
      })
    );
    return { ok: true, orders: response.results };
  }

  async searchTalentsOrdersByQuery(
    headers: IServiceHeaders,
    pagination: IServicePagination,
    query: {
      filters: IOrdersFilters;
      orderBy: {
        field: string;
        method: ISortBy;
      };
      relationToFollowingTypes: RelationToFollowingTypes;
    },
    relations: string[] = [],
    cancelToken?: CancelTokenSource
  ): Promise<{ ok: boolean; orders: IOrder[]; next: boolean }> {
    if (!this.getToken()) {
      return { ok: false, orders: [], next: false };
    }
    const filtersRemoveEmptyKeys = this.removeEmptyFilters(query.filters);

    const queryReq = {
      alias: "order",
      relations: relations.concat(["talentProfile", "orderMaterials"]),
      orderBy: query.orderBy,
      queries: this.buildQuery(
        filtersRemoveEmptyKeys,
        query.relationToFollowingTypes,
        ResourceTypes.TalentOrders
      ),
    };
    const { data: response } = await this.post<{
      hasNext: boolean;
      results: IOrder[];
    }>(
      `/talent-orders/search?page=${pagination.page}&size=${pagination.size}`,
      queryReq,
      {
        headers: this.attachHeaders(headers),
        cancelToken: cancelToken ? cancelToken.token : undefined,
      },
      { results: [] }
    );
    response.results = await Promise.all(
      response.results.map(async (result) => {
        return Object.assign(result, {
          talentProfile: Object.assign(result.talentProfile, {
            social: this.attachSocialMedia(result.talentProfile),
            avatar: result?.talentProfile?.avatar
              ? await this.getS3Url(result.talentProfile.avatar)
              : null,
          }),
          serviceType: "video",
          orderedAt: (result as any)?.campaign?.createdAt || "",
          description: (result as any)?.campaign?.brandDescription,
          requesterName: (result as any)?.campaign?.brandName || "",

          subServiceType: { subService: "video" },
          userProfile: Object.assign(result?.campaign || ({} as any), {
            avatar: result?.campaign?.campaignLogo
              ? await this.getS3Url(result?.campaign?.campaignLogo)
              : null,
            fullName: (result as any)?.campaign?.brandName || "",
          }) as any,
        });
      })
    );
    return { ok: true, orders: response.results, next: response.hasNext };
  }

  async countTalentsOrdersByQuery(
    headers: IServiceHeaders,
    query: {
      filters: IOrdersFilters;
      orderBy: {
        field: string;
        method: ISortBy;
      };
      relationToFollowingTypes: RelationToFollowingTypes;
    },
    cancelToken?: CancelTokenSource
  ) {
    if (!this.getToken()) {
      return { count: 0 };
    }
    const filtersRemoveEmptyKeys = this.removeEmptyFilters(query.filters);

    const queryReq = {
      alias: "order",
      relations: ["talentProfile", "orderMaterials"],
      orderBy: query.orderBy,
      queries: this.buildQuery(
        filtersRemoveEmptyKeys,
        query.relationToFollowingTypes,
        ResourceTypes.TalentOrders
      ),
    };
    const { data: response } = await this.post<{ count: number }>(
      `/talent-orders/count`,
      queryReq,
      {
        headers: this.attachHeaders(headers),
        cancelToken: cancelToken ? cancelToken.token : undefined,
      },
      { count: 0 }
    );
    return response;
  }

  async countTalentsByQuery(
    headers: IServiceHeaders,
    query: {
      filters: ITalentsFilters;
      orderBy: {
        field: string;
        method: ISortBy;
      };
      relationToFollowingTypes: RelationToFollowingTypes;
    },
    cancelToken?: CancelTokenSource
  ) {
    const filtersRemoveEmptyKeys = this.removeEmptyFilters(query.filters);
    const queryReq = {
      alias: "profile",
      relations: ["talentServicePrices", "talentCategories"],
      orderBy: query.orderBy,
      queries: this.buildQuery(
        filtersRemoveEmptyKeys,
        query.relationToFollowingTypes,
        ResourceTypes.Talents
      ),
    };
    const { data: response } = await this.post<{ count: number }>(
      `/profile/count`,
      queryReq,
      {
        headers: this.attachHeaders(headers),
        cancelToken: cancelToken ? cancelToken.token : undefined,
      },
      { count: 0 }
    );
    return response;
  }

  opMapping(key: string, resource: any): OpTypes | null {
    switch (resource) {
      case ResourceTypes.Talents:
        return ({
          hashtags: "arrContains",
          username: "eq",
        }[key] || null) as OpTypes;
      case ResourceTypes.UserOrders:
        return ({
          status: "eq",
        }[key] || null) as OpTypes;
      case ResourceTypes.TalentOrders:
        return ({
          status: "eq",
        }[key] || null) as OpTypes;
    }
    return null;
  }

  fieldMapping(key: string, resource: any) {
    switch (resource) {
      case ResourceTypes.Talents:
        return (
          {
            name: "profile.fullName",
            englishName: "profile.englishName",
            donates: "profile.donation",
            responseTime: "profile.responseTime",
            hashtags: "profile.hashtags",
            username: "profile.username",
            price: "talentServicePrices.price",
            categories: "talentCategories.categoriesId",
          }[key] || ""
        );
      case ResourceTypes.UserOrders:
        return (
          {
            status: "order.status",
            isPaymentSuccess: "order.isPaymentSuccess",
          }[key] || ""
        );
      case ResourceTypes.TalentOrders:
        return (
          {
            status: "order.status",
            id: "order.id",
            requesterName: "order.requesterName",
            receiverName: "order.receiverName",
            serviceType: "order.serviceType",
            classification: "order.classification",
            isPaymentSuccess: "order.isPaymentSuccess",
          }[key] || ""
        );
    }
    return "";
  }
}
