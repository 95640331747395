import "./rejectConfirm.scss";
import { Modal } from "rsuite";
import CloseButton from "../../components/CloseButton/closeButton";
import { useMediaQuery } from "@react-hook/media-query";
import classNames from "classnames";
import { useState } from "react";
import { strings } from "../../localizedStrings";

export interface IRejectConfirmProps {
  dismiss: () => void;
  show: boolean;
  onSubmit: () => Promise<void>;
}

const RejectConfirm = ({
  dismiss,
  show,
  onSubmit = async () => {},
}: IRejectConfirmProps) => {
  const mobileView = useMediaQuery("(max-width: 600px)") as boolean;
  const [isLoading, setLoading] = useState(false);

  return (
    <Modal
      size="xs"
      style={{
        margin: mobileView ? 0 : "120px auto 0 auto",
        maxWidth: mobileView ? "100vw" : "600px",
        minWidth: mobileView ? "100vw" : "600px",
      }}
      dialogClassName="order-confirm-modal"
      backdropClassName={"order-confirm-backdrop"}
      backdrop={true}
      show={show}
      onHide={() => {}}
    >
      <CloseButton
        onClick={() => {
          dismiss();
        }}
      />
      <div className={"text"}>{strings.rejectedOrderQuestion}</div>
      <div
        className={classNames("submit", {
          disable: isLoading,
        })}
        onClick={async () => {
          if (!isLoading) {
            setLoading(true);
            await onSubmit();
            setLoading(false);
          }
        }}
      >
        {strings.rejectedOrderAction}
      </div>
    </Modal>
  );
};

export default RejectConfirm;
