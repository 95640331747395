import { CountryCode } from "libphonenumber-js";
import { createContext, useContext, useEffect, useState } from "react";
import { strings } from "../localizedStrings";
import { ServicesContext } from "./services";

export interface ILocalizationContext {
  isRTL: boolean;
  localization: LocalizationTypes;
  setLocalization: (localization: LocalizationTypes) => void;
  setRTL: (rtl: boolean) => void;
  country: CountryCode;
  setCountry: (country: CountryCode) => void;
  setCurrency: (currency: CurrencyTypes) => void;
  currency: CurrencyTypes;
}

export const LocalizationContext = createContext<ILocalizationContext>({
  isRTL: true,
  localization: "en",
  setLocalization: () => {},
  setRTL: () => {},
  country: "US",
  currency: "USD",
  setCountry: () => {},
  setCurrency: () => {},
});

export type LocalizationTypes = "he" | "en" | "ch";
export type CurrencyTypes = "USD" | "ILS" | "HKD";

export interface ILocalizationContextProps {
  children: any;
}

export const LocalizationProvider = ({
  children,
}: ILocalizationContextProps) => {
  const [localization, setLocalization] = useState<LocalizationTypes>("en");
  const [country, setCountry] = useState<CountryCode>("US");
  const [currency, setCurrency] = useState<CurrencyTypes>("USD");

  const { configService } = useContext(ServicesContext);
  const [isRTL, setRTL] = useState<boolean>(
    localization === "he" ? true : false
  );
  strings.setLanguage(localization);

  useEffect(() => {
    if (!localStorage.getItem("country")) {
      configService.getLocalization().then((res) => {
        if (configService.getSupportedCountry().includes(res)) {
          setCountry(res);
        } else {
          setCountry("US");
        }
      });
    }
  }, [configService, localization]);

  useEffect(() => {
    if (!localStorage.getItem("localization")) {
      const currency = configService.getCurrencyByCountry(country);
      const language = configService.getLanguageByCountry(country);
      setCurrency(currency);
      // if (["en", "he"].includes(language)) {
      if (["ch"].includes(language)) {
        setLocalization("ch");
        strings.setLanguage("ch");
      } else {
        strings.setLanguage("en");
      }
    }
  }, [configService, country]);

  useEffect(() => {
    if (localStorage.getItem("localization")) {
      const localization = localStorage.getItem("localization");
      setLocalization((localization as any) || "en");
      setRTL(localization === "he" ? true : false);
      setCurrency(localization === "he" ? "ILS" : "USD");
      strings.setLanguage((localization as any) || "en");
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("country")) {
      const country = localStorage.getItem("country");
      setCountry((country as any) || "US");
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currency")) {
      const currency = localStorage.getItem("currency");
      setCurrency((currency as any) || "USD");
    }
  }, []);

  return (
    <LocalizationContext.Provider
      value={{
        isRTL: isRTL,
        localization,
        setRTL,
        currency,
        country,
        setCountry: (country: CountryCode) => {
          localStorage.setItem("country", country);
          setCountry(country);
        },
        setCurrency: (currency: CurrencyTypes) => {
          localStorage.setItem("currency", currency);
          setCurrency(currency);
        },
        setLocalization: (localization: LocalizationTypes) => {
          localStorage.setItem("localization", localization);
          setLocalization(localization);
        },
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};
