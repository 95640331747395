import "./authentication.scss";
import { Modal } from "rsuite";
import className from "classnames";
import React, { useContext, useState } from "react";
import PopupMessage from "../../components/PopUpMessage/popUpMessage";
import CloseButton from "../../components/CloseButton/closeButton";
import Input from "../../components/Input/input";
import { useMediaQuery } from "@react-hook/media-query";
import { validPassword } from "./authentication.helpers";
import { FormType } from "./authentication.interface";
import { isEmpty } from "lodash";
import { ServicesContext } from "../../context/services";
import { strings } from "../../localizedStrings";

export interface IAuthenticationRestPasswordProps {
  dismissCallback: () => void;
  show: boolean;
}

export const initForm = {
  passwordConfirmation: { value: "", valid: { valid: true, msg: "" } },
  password: { value: "", valid: { valid: true, msg: "" } },
  oldPassword: { value: "", valid: { valid: true, msg: "" } },
};

const AuthenticationRestPassword = ({
  show,
  dismissCallback,
}: IAuthenticationRestPasswordProps) => {
  const [mode, setMode] = useState<"rest" | "error" | "ok">("rest");
  const [form, setForm] = useState<FormType>(initForm);
  const [loading, setLoading] = useState(false);
  const { authenticationFirebaseService } = useContext(ServicesContext);

  const mobileView = useMediaQuery("(max-width: 500px)") as boolean;
  const validFrom = Object.keys(form).every(
    (key) => form[key].valid.valid && !isEmpty(form[key].value)
  );

  return (
    <>
      <PopupMessage
        title={strings.changeYourPassword}
        message={strings.passwordChangedSuccessfully}
        dismiss={() => {
          setForm(initForm);
          setMode("rest");
          dismissCallback();
        }}
        show={show && mode === "ok"}
      />
      <PopupMessage
        title={strings.errorTitle}
        message={strings.passwordError}
        dismiss={() => {
          setForm(initForm);
          setMode("rest");
        }}
        show={show && mode === "error"}
      />
      <Modal
        size="sm"
        style={{ margin: mobileView ? "0" : "50px auto 0 auto" }}
        dialogClassName="authentication-modal"
        backdropClassName={"authentication-backdrop"}
        backdrop={true}
        show={show && mode === "rest"}
        onHide={() => {}}
      >
        <CloseButton
          onClick={() => {
            setForm(initForm);
            // setShowAuth(false);
            dismissCallback();
          }}
        />
        <div className="title">{strings.resetPassword}</div>
        <Input
          type={"password"}
          multiline={false}
          validCheck={false}
          placeholder=""
          customClass={"product-input rest-password"}
          label={strings.previousPassword}
          value={form.oldPassword.value}
          validate={validPassword}
          valid={form.oldPassword.valid}
          onChange={(value, valid) =>
            setForm({ ...form, oldPassword: { value, valid } })
          }
        ></Input>
        <Input
          type={"password"}
          multiline={false}
          validCheck={false}
          placeholder=""
          customClass={"product-input rest-password"}
          label={strings.newPassword}
          value={form.password.value}
          validate={validPassword}
          valid={form.password.valid}
          onChange={(value, valid) =>
            setForm({ ...form, password: { value, valid } })
          }
        ></Input>
        <Input
          type={"password"}
          multiline={false}
          validCheck={false}
          placeholder=""
          customClass={"product-input rest-password"}
          label={strings.retypePassword}
          value={form.passwordConfirmation.value}
          validate={validPassword}
          valid={form.passwordConfirmation.valid}
          onChange={(value, valid) =>
            setForm({ ...form, passwordConfirmation: { value, valid } })
          }
        ></Input>
        <div
          className={className("submit-button", {
            disable:
              !validFrom ||
              form.passwordConfirmation.value !== form.password.value ||
              loading,
          })}
          onClick={async () => {
            if (validFrom && !loading) {
              const formValues = Object.keys(form).reduce((formValues, key) => {
                formValues[key] = form[key].value;
                return formValues;
              }, {} as { [input: string]: string });
              setLoading(true);
              authenticationFirebaseService
                .changePassword({
                  currentPassword: formValues.oldPassword,
                  newPassword: formValues.password,
                })
                .then((res: any) => {
                  setLoading(false);
                  if (res.error) {
                    setMode("error");
                  } else {
                    setMode("ok");
                  }
                });
            }
          }}
        >
          {strings.updatePassword}
        </div>
      </Modal>
    </>
  );
};

export default AuthenticationRestPassword;
