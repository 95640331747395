import {
  validName,
  validUserName,
} from "../../../../Authentication/authentication.helpers";
import Input from "../../../../../components/Input/input";
import { strings } from "../../../../../localizedStrings";
import FileInput from "../../../../../components/FileInput/fileInput";
import { useContext } from "react";
import {
  LocalizationContext,
  LocalizationTypes,
} from "../../../../../context/localization";
import classNames from "classnames";
import Select from "../../../../../components/Select/select";
import { Toggle } from "rsuite";
import SocialInputs from "../../../../../components/SocialInput/socialInput";
import { attachS3Url } from "../../../../../helpers/s3.utils";

export const TalentsInputs = ({
  form,
  setForm,
  avatar,
  localization,
  categoriesOptions,
  setAvatar,
}: {
  form: any;
  setForm: any;
  avatar: string;
  localization: string;
  categoriesOptions: string[];
  setAvatar: any;
}) => {
  const { isRTL } = useContext(LocalizationContext);

  return (
    <>
      <div className={classNames("profile-media-container", { ltr: !isRTL })}>
        <FileInput value={avatar} onChange={setAvatar} type="image" />
        <FileInput
          value={form?.videoPromo?.value || ""}
          onChange={(value) => {
            setForm({
              ...form,
              videoPromo: { value: value, valid: true },
            });
          }}
          type="video"
          examples={[
            attachS3Url(
              "video/16311937816458526/789d0693-97bb-4e45-b709-bd0611a856e0.mp4"
            ),
            attachS3Url(
              "video/16311945421160235/5d542531-d3bc-4c49-9131-fcc9ebf1cebe.mp4"
            ),
          ]}
        />
      </div>
      <Input
        customClass={"profile-input"}
        value={form.name.value}
        validate={validName}
        value_en={form.name.value_en}
        valid={form.name.valid}
        onChange={(value, valid, selectedLocalization) => {
          if (selectedLocalization === "en") {
            setForm({
              ...form,
              name: {
                value_en: value,
                value: localization === "en" ? value : form.name.value,
                valid,
              },
            });
          } else {
            setForm({
              ...form,
              name: {
                value_en: localization === "en" ? value : form.name.value_en,
                value,
                valid,
              },
            });
          }
        }}
        multiLanguage
        userLanguage={localization as LocalizationTypes}
        localizationDefault={localization === "en" ? "en" : "local"}
        type={"text"}
        label={strings.displayName}
      />
      <Input
        customClass={"profile-input"}
        value={form.username.value}
        validate={validUserName}
        valid={form.username.valid}
        onChange={(value, valid) => {
          setForm({
            ...form,
            username: { value: value, valid },
          });
        }}
        type={"url"}
        copy
        label={strings.url}
        preText={"letstok.com/talent/"}
      />
      <Input
        customClass={"profile-input"}
        value={""}
        valid={form.username.valid}
        validCheck={false}
        type={"url"}
        copy
        label={strings.ref}
        preText={`letstok.com/talentRegistration/referrer=${form.username.value}`}
      />
      <Input
        customClass={"profile-input limit-letters"}
        value={form.bioShort.value}
        value_en={form.bioShort.value_en}
        validate={validName}
        limitLetters
        limit={40}
        valid={form.bioShort.valid}
        onChange={(value, valid, selectedLocalization) => {
          if (selectedLocalization === "en") {
            setForm({
              ...form,
              bioShort: {
                value_en: value,
                value: localization === "en" ? value : form.bioShort.value,
                valid,
              },
            });
          } else {
            setForm({
              ...form,
              bioShort: {
                value_en:
                  localization === "en" ? value : form.bioShort.value_en,
                value,
                valid,
              },
            });
          }
        }}
        multiLanguage
        userLanguage={localization as LocalizationTypes}
        localizationDefault={localization === "en" ? "en" : "local"}
        type={"text"}
        label={strings.shortDescription}
      />
      <Input
        customClass={"profile-input multiline limit-letters"}
        value={form.bio.value}
        validate={validName}
        multiline={true}
        limitLetters
        limit={500}
        value_en={form.bio.value_en}
        valid={form.bio.valid}
        onChange={(value, valid, selectedLocalization) => {
          if (selectedLocalization === "en") {
            setForm({
              ...form,
              bio: {
                value_en: value,
                value: localization === "en" ? value : form.bio.value,
                valid,
              },
            });
          } else {
            setForm({
              ...form,
              bio: {
                value_en: localization === "en" ? value : form.bio.value_en,
                value,
                valid,
              },
            });
          }
        }}
        multiLanguage
        userLanguage={localization as LocalizationTypes}
        localizationDefault={localization === "en" ? "en" : "local"}
        type={"text"}
        label={strings.bioLabel}
      />

      <div className={"toggle-option-container "}>
        <div className={"toggle-text"}>{strings.hideProfile} </div>
        <div className={"toggle-container"}>
          <Toggle
            checked={form?.receiveEmail?.value}
            onChange={(value) => {
              setForm({
                ...form,
                receiveEmail: { value, valid: { valid: true, msg: "" } },
              });
            }}
          />
        </div>
      </div>
      <SocialInputs
        values={form?.socialMedias.value}
        onChange={(values) => {
          setForm({
            ...form,
            socialMedias: {
              value: values,
              valid: true,
            },
          });
        }}
      />
    </>
  );
};
