import "./checkbox.scss";
import React, { useContext } from "react";
import { Checkbox } from "rsuite";
import { LocalizationContext } from "../../context/localization";
import classNames from "classnames";

export interface ICheckboxWithLabel {
  label?: string;
  customLabel?: any;
  checked?: boolean;
  onChecked?: (state: boolean) => void;
}

const CheckboxWithLabel = ({
  label,
  customLabel,
  checked = false,
  onChecked = () => {},
}: ICheckboxWithLabel) => {
  const { isRTL } = useContext(LocalizationContext);
  return (
    <div className={classNames("check-box", { ltr: !isRTL })}>
      <Checkbox checked={checked} onChange={() => onChecked(!checked)} />
      {!customLabel ? (
        <div className="text-box">{label}</div>
      ) : (
        React.createElement(customLabel)
      )}
    </div>
  );
};

export default CheckboxWithLabel;
