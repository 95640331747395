import { strings } from "../../localizedStrings";
import { ITalentsSearch } from "../v2/search/search.interfaces";
import { Social } from "../../components/SocialButton/socialButton";
import { CountryCode } from "libphonenumber-js";

export const getCategoriesType = () => {
  return [
    strings.allTalents,
    strings.recommended,
    strings.influencers,
    strings.realityStars,
    strings.tikTok,
    strings.actors,
    strings.music,
    strings.beautyFashion,
    strings.sport,
    strings.comedians,
    strings.experts,
  ];
};

export const initTalentsCategories: ITalentsSearch[] = new Array(25).fill({
  id: "0",
  review: "",
  price: 0,
  donation: false,
  name: "",
  short_bio: "",
  avatar: "",
});

export const initTalentsFavoritesCategories = new Array(25).fill({
  id: "0",
  review: "",
  price: 0,
  donation: false,
  name: "",
  short_bio: "",
  avatar: "",
});

export const initTalents = () =>
  getCategoriesType().map((type, index) => {
    return {
      category_id: index,
      name: type,
      category_order: index,
      hasNext: true,
      talents: new Array(10).fill({
        id: "",
        name: "",
        avatar: "",
        short_bio: "",
        review: 0,
        price: 0,
        donation: false,
        is_favorite: false,
      }),
    };
  });

export interface ITalent {
  id: string;
  name: string;
  avatar: string;
  english_name?: string;
  categories: any[];

  reviews: {
    from: string;
    rating: number;
    comment: string;
  }[];

  percDonation?: number;
  donation?: boolean;
  shortBio: string;
  videoUrl: string;
  socialMedias: {
    url: string;
    platform: Social;
    followers: number;
  }[];
  bio: string;
  features: {
    donates: boolean;
    response: number;
  };
  country: CountryCode;
  username: string;
  products: {
    title: string;
    description: string;
    price: number;
    id: number;
    example: string;
    subService: string;
    features: {
      appointment: boolean;
      video: boolean;
      time: number;
      business: boolean;
    };
  }[];
  isFavorite: boolean;
  videosUrls: string[];
  hashtags: string[];
}

export interface ITalentsFilters {
  categories: string[];
  orderType: string[];
  price: { start: number; end: number };
  responseTime: string[];
  donates: boolean;
  name: string;
  hashtags: string[];
}
