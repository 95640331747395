import "./phoneInput.scss";
import classnames from "classnames";
import { useContext, useState } from "react";
import { LocalizationContext } from "../../context/localization";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumber } from "libphonenumber-js";
import { ReactComponent as IconAlert } from "../../assets/IconAlert.svg";
import ReactTooltip from "react-tooltip";
import { toLower, upperCase } from "lodash";
import { strings } from "../../localizedStrings";

export interface IPhoneInputProps {
  label: string;
  onChange: (value: string, countryCode: string, valid: boolean) => void;
  value: string;
  country: string;
  customClass: string;
  required?: boolean;
  valid?: { valid: boolean; msg: string };
  errorBorder?: boolean;
}

const InputPhone = ({
  label = "",
  onChange = () => {},
  value = "",
  required = false,
  country,
  customClass,
  valid,
  errorBorder,
}: IPhoneInputProps) => {
  const { isRTL } = useContext(LocalizationContext);
  const [validObj, setValid] = useState(true);

  return (
    <div
      className={classnames("input-container-phone-input", customClass, {
        ltr: !isRTL,
      })}
    >
      <div className="label-container">
        <label className="label">{label}</label>
        {required ? <div className="label-required">{"*"}</div> : <></>}
      </div>
      <div
        className={classnames("input", {
          "error-border": !validObj,
        })}
      >
        <ReactTooltip
          place="bottom"
          arrowColor={"transparent"}
          backgroundColor="#D1CECE"
          textColor="#222222"
          className="tooltip"
        ></ReactTooltip>
        <IconAlert
          className={classnames("alert", { show: !validObj })}
          data-tip={strings.notValidNumber}
          width="26px"
          height="26px"
          color="#d1cece"
        />
        <PhoneInput
          country={toLower(country)}
          value={value}
          preferredCountries={["il", "us", "hk", "ph"]}
          inputProps={{ autoFormat: false, enableSearch: true }}
          onChange={(value, data) => {
            try {
              const phoneParse = parsePhoneNumber(
                value,
                upperCase((data as any).countryCode || country) as any
              );
              setValid(phoneParse.isValid());
              onChange(
                value,
                (data as any).countryCode || country,
                !!phoneParse.isValid()
              );
            } catch (err) {
              setValid(false);
              onChange(value, (data as any).countryCode || country, false);
            }
          }}
        />
      </div>
      <div className="error-container">
        {!validObj ? (
          <label className="error-info">{`* ${strings.notValidNumber}`}</label>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default InputPhone;
