import { lazy, Suspense, useEffect } from "react";
import TagManager from "react-gtm-module";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";

import Loader from "./components/Loader/loader";
import { ApplicationProvider } from "./context/application";
import { AuthenticationProvider } from "./context/authentication";
import { LocalizationProvider } from "./context/localization";
import { ServicesProvider } from "./context/services";
import ReactGA from "react-ga";

const AppBusiness = lazy(() => import("./AppBusiness"));
const App = lazy(() => import("./App"));

const onPageChange = (pathname: string) => {
  setTimeout(() => {
    const title = document.getElementsByTagName("title")[0].innerHTML;
    (window as any).prerenderReady = true;
    ReactGA.pageview(pathname, ["tracker2"]);
    TagManager.dataLayer({
      dataLayer: {
        event: "ga_pageview",
        pagePath: pathname,
        pageTitle: title,
        ga_pageview_data: {
          page_path: pathname,
          page_title: title,
        },
      },
    });
  }, 50);
};

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.querySelector(".root")?.scrollTo(0, 0);
    (document.body.style as any).zoom = "100%";
    onPageChange(pathname);
  }, [pathname]);

  return null;
}

function Routes() {
  useEffect(() => {
    console.log(
      "The application running on version: ",
      process.env.REACT_APP_VERSION
    );
  }, []);
  return (
    <Suspense
      fallback={
        <div className="splash-continer">
          <Loader height={200} />
        </div>
      }
    >
      <ServicesProvider>
        <LocalizationProvider>
          <ApplicationProvider>
            <AuthenticationProvider>
              <BrowserRouter>
                <ParallaxProvider>
                  <ScrollToTop />
                  <Switch>
                    {/* <Route path="/business" component={AppBusiness} /> */}
                    <Route path="/" component={App} />
                  </Switch>
                </ParallaxProvider>
              </BrowserRouter>
            </AuthenticationProvider>
          </ApplicationProvider>
        </LocalizationProvider>
      </ServicesProvider>
    </Suspense>
  );
}
export default Routes;
