import "./chatView.scss";

import { FC, useEffect, useRef } from "react";
import Avatar from "../BusinessHeader/Avatar/avatar";
import { DateTime } from "luxon";
import { attachS3Url } from "../../helpers/s3.utils";

export interface IChatView {
  date: DateTime;
  content: string;
  username: string;
}

export interface IChatViewProps {
  chats: IChatView[];
}

const ChatView: FC<IChatViewProps> = ({ chats }: IChatViewProps) => {
  const refList = useRef<HTMLDivElement[]>([]);
  useEffect(() => {
    if (refList && refList.current && refList.current[chats.length - 1] && chats.length > 5) {
      refList.current[chats.length - 1].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [refList, chats]);
  return (
    <div className="chat-view-continer">
      {chats.map((chat: IChatView, index) => (
        <div
          ref={(ref) => ((refList as any).current[index] = ref)}
          className="chat-item-view"
        >
          <div>
            <Avatar url={attachS3Url("public/video/20220402/7d14bf1a-967a-4225-bc14-0f1692236a1b.png")} />
          </div>
          <div className="content">
            <div className="metadata">{`${chat.date.toFormat(
              "hh:mm dd/MM/yyyy"
            )} ${chat.username}`}</div>
            <div className="message">{chat.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatView;
