import { ReactComponent as UserSvg } from "../../../assets/IconUsers.svg";

import "react-lazy-load-image-component/src/effects/blur.css";
import "./avatar.scss";
import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { isEmpty } from "lodash";
import { LocalizationContext } from "../../../context/localization";
export interface IAvatar {
  url?: string;
  disable?: boolean;
  onClick?: () => void;
  online?: boolean;
  notification?: number;
  gray?: boolean;
}
const Avatar = ({
  url,
  disable = false,
  online = false,
  gray = false,
  onClick = () => {},
  notification = 0,
}: IAvatar) => {
  const [imageUrl, setImageUrl] = useState(url ? url : "");
  const { isRTL } = useContext(LocalizationContext);

  useEffect(() => {
    setImageUrl(url ? url : "");
  }, [url]);

  return (
    <>
      <div
        onClick={() => {
          if (!disable) {
            onClick();
          }
        }}
        className={classNames("avatar-business", {
          background: !isEmpty(imageUrl),
          login: !isEmpty(imageUrl),
          gray: gray,
        })}
      >
        {isEmpty(imageUrl) ? (
          <UserSvg className={"avatar-placeholder"} />
        ) : (
          <LazyLoadImage
            effect="blur"
            height={"100%"}
            width={"100%"}
            alt={"user"}
            style={{ width: "100%", height: "100%" }}
            placeholder={<UserSvg className={"avatar-placeholder"} />}
            src={imageUrl}
            className="avatar-image"
          />
        )}
        {online ? (
          <div className={classNames("online-continer", { ltr: !isRTL })} />
        ) : (
          <></>
        )}
        {notification ? (
          <div className={classNames("notification-continer", { ltr: !isRTL })}>
            {notification}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Avatar;
