import "./closeButton.scss";
import { ReactComponent as SvgClose } from "../../assets/IconClose.svg";

const CloseButton = ({
  onClick,
}: {
  onClick: (e: MouseEvent) => void;
}) => {
  return (
    <div onClick={onClick as any} className="close-button-container">
      <SvgClose color="#F24462" />
    </div>
  );
};

export default CloseButton;
