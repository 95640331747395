import { isEmpty, isNil, isString, last, omitBy, pickBy } from "lodash";
import moment from "moment";
import { v4 } from "uuid";
import Service from "../../service";
import { IServiceHeaders } from "../../service.interface";
import LoggerService from "../logger/logger.service";
import {
  IProfile,
  IProfileUpdate,
  IUser,
  IUserProfile,
  UserRole,
} from "./users.interface";
import { addPlusToNumber } from "../../../helpers/utils";
import { ISocialMedia, IUserResponse } from "../talents/talents.interface";
import { Social } from "../../../components/SocialButton/socialButton";

export default class UsersService extends Service {
  constructor(
    url: string,
    protected errorHandler: (title: string, msg: string, error: string) => void,
    protected LoggerService: LoggerService
  ) {
    super(url, errorHandler, LoggerService);
  }

  async sso(
    headers: IServiceHeaders,
    role: UserRole
  ): Promise<{
    ok: boolean;
    profile?: IProfile;
    user?: IUser;
    error?: Object;
  }> {
    const { data, ok, response } = (await this.get<{
      profile: IProfile;
      user: IUser;
    }>(
      `/users/sso?role=${role}`,
      {
        headers: this.attachHeaders(headers),
      },
      {}
    )) as any;
    if (!ok) {
      return { ok: false, error: response?.data };
    } else {
      await this.updateProfile(
        headers,
        {
          username: (data?.user?.email || "").split("@")[0],
        },
        data.profile
      );

      return {
        ok: true,
        profile: Object.assign(data.profile, {
          role: data.user.role,
          fullName: data?.user?.fullName,
          email: data?.user?.email,
          phoneNumber: data?.user?.phoneNumber,
          birthDate: data.user.birthDate,
          avatar: data?.profile?.avatar
            ? await this.getS3Url(data.profile.avatar)
            : null,
          favorites: [], //await this.getUserFavorites(headers),
        }),
        user: data.user,
      };
    }
  }

  async getAllCategory(headers: IServiceHeaders) {
    const categories = await this.get<any>(
      `/categories/all`,
      {
        headers: this.attachHeaders(headers),
      },
      []
    );
    return categories.data;
  }

  async getUser(
    headers: IServiceHeaders,
    userName: string
  ): Promise<IUserProfile | null> {
    if (!userName) {
      return null as any;
    }
    let { data: user } = await this.get<IUserResponse>(
      `/users/profile/${userName}`,
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
      },
      {}
    );
    if (isEmpty(user)) {
      return null as any;
    }
    return {
      id: user.profile.userId,
      name: user.profile.fullName,
      avatar: await this.getS3Url(user.profile.avatar),
      username: user.profile.username,
      social: {
        facebook: user.profile.facebook,
        instagram: user.profile.instagram,
        tiktok: user.profile.tiktok,
        youtube: user.profile.youtube,
        twitter: user.profile.twitter,
      },
    };
  }

  async deactivate(headers: IServiceHeaders): Promise<void> {
    await this.get<IUserResponse>(
      `/users/deactivate`,
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
      },
      {}
    );
  }

  async activate(headers: IServiceHeaders): Promise<void> {
    await this.get<IUserResponse>(
      `/users/activate`,
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
      },
      {}
    );
  }

  async getUserFavorites(headers: IServiceHeaders) {
    const users = await this.get<IProfile[]>(
      `/users/favorites`,
      {
        headers: this.attachHeaders(headers),
      },
      []
    );
    return users.data;
  }

  async visitorNotify(
    headers: IServiceHeaders,
    body: { name: string; email: string; phone: string; provider: string }
  ) {
    await this.post(`/users/contact`, body, {
      headers: this.attachHeaders(headers),
    });
  }

  async addFavorite(headers: IServiceHeaders, id: string): Promise<void> {
    await this.post<void>(
      `/users/favorites/${id}`,
      {},
      {
        headers: this.attachHeaders(headers),
      }
    );
  }

  async deleteUserFavorites(headers: IServiceHeaders, id: string) {
    await this.delete(
      `/users/favorites/${id}`,
      {
        headers: this.attachHeaders(headers),
      },
      []
    );
  }

  async updateProfileImage(headers: IServiceHeaders, id: string, image: any) {
    const path = `profiles/${id}/avatar/${v4()}.${image.name.split(".").pop()}`;
    const response = await this.fileUpload(path, image, false, (evt) => {
      console.log(`Uploaded: ${evt.loaded}/${evt.total}`);
    });
    if (response) {
      const { ok: okRes } = await this.patch(
        `/users/talents/update`,
        {
          profile: {
            avatar: path,
          },
        },

        {
          headers: this.attachHeaders(headers),
        }
      );
      return okRes;
    }
    return true;
  }

  async updateProfile(
    headers: IServiceHeaders,
    profile: IProfileUpdate,
    currProfile: IProfile,
    progressCallback: (progress: number) => void = () => {}
  ) {
    if (profile.video && !isString(profile.video)) {
      await this.uploadProfileVideo(
        headers,
        profile.video,
        currProfile.userId,
        progressCallback
      );
    } else if (!profile.video) {
      // await this.deleteProfileVideo(headers);
    }
    if (profile.avatar && !isString(profile.avatar)) {
      await this.updateProfileImage(
        headers,
        currProfile.userId,
        profile.avatar
      );
    }

    const data = await this.patch<any>(
      `/users/talents/update`,
      this.parseUpdate(profile, currProfile),
      {
        headers: this.attachHeaders(headers),
      }
    );
    if ((data as any)?.response?.data?.name === "DuplicateColumn") {
      return { ok: false, data: "Username Exists" };
    }

    return { ok: true, data: profile };
  }

  async talentsPromoteNotify(
    headers: IServiceHeaders,
    promoteDto: {
      phone: string;
      email: string;
      talentId: string;
      provider: string;
    }
  ) {
    const { ok: okRes } = await this.post(`/talents/promote`, promoteDto, {
      headers: this.attachHeaders(headers),
    });
    return okRes;
  }

  parseUpdate(profile: IProfileUpdate, currProfile: IProfile) {
    return {
      user: omitBy(
        {
          username:
            currProfile.username === profile.username ? null : profile.username,
          fullName: profile.fullName,
          email: profile.email,
          phoneNumber: profile.phone,
          birthDate: profile.birthDate,
          country: profile.country,
        },
        isNil
      ),
      profile: {
        bio: profile.bio?.slice(0, 500),
        shortBio: profile.shortBio?.slice(0, 500),
        ...this.socialMediaToKeys(profile.socialMedias || []),
      },
      categories: profile.category,
    };
  }

  async deleteProfileVideo(headers: IServiceHeaders) {
    const { ok: okRes } = await this.patch(
      `/users/talents/update`,
      {
        profile: {
          avatarVideo: "",
        },
      },
      {
        headers: this.attachHeaders(headers),
      }
    );
    return okRes;
  }

  async uploadProfileVideo(
    headers: IServiceHeaders,
    file: any,
    id: string,
    progressCallback: (progress: number) => void
  ) {
    const path = `profiles/${id}/video/${v4()}.mp4`;
    const response = await this.fileUpload(path, file, false, (evt) => {
      progressCallback(Number(((evt.loaded / evt.total) * 100).toFixed()));
    });

    if (response) {
      const { ok: okRes } = await this.patch(
        `/users/talents/update`,
        {
          profile: {
            avatarVideo: path,
          },
        },
        {
          headers: this.attachHeaders(headers),
        }
      );
      return okRes;
    }
    return false;
  }

  socialMediaToKeys(socials: ISocialMedia[]) {
    const socialObj = socials.reduce((socialObj, social) => {
      switch (social.platform) {
        case Social.facebook:
          socialObj = {
            ...socialObj,
            facebook: social.url,
          };
          break;
        case Social.instagram:
          socialObj = {
            ...socialObj,
            instagram: social.url,
          };
          break;
        case Social.tiktok:
          socialObj = {
            ...socialObj,
            tiktok: social.url,
          };
          break;
        case Social.twitter:
          socialObj = {
            ...socialObj,
            twitter: social.url,
          };
          break;
        case Social.youtube:
          socialObj = {
            ...socialObj,
            youtube: social.url,
          };
          break;
        case Social.discord:
          socialObj = {
            ...socialObj,
            discord: social.url,
          };
          break;
        case Social.twitch:
          socialObj = {
            ...socialObj,
            twitch: social.url,
          };
          break;
      }
      return socialObj;
    }, {}) as any;

    return Object.entries({
      facebook: socialObj.facebook,
      instagram: socialObj.instagram,
      tiktok: socialObj.tiktok,
      twitter: socialObj.twitter,
      youtube: socialObj.youtube,
      discord: socialObj.discord,
      twitch: socialObj.twitch,
    }).reduce((a: any, [k, v]) => (v ? ((a[k] = v), a) : a), {});
  }
}
