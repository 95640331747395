import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { isString, upperCase } from "lodash";
import { DateTime } from "luxon";
import { IServiceHeaders } from "../../../../services/service.interface";
import ConfigService from "../../../../services/v1/config";
import { ICategory } from "../../../../services/v2/newsFeed/newsFeed.interfaces";
import { IProfile } from "../../../../services/v2/users/users.interface";
import UsersService from "../../../../services/v2/users/users.service";

export type SettingsForms = {
  [key: string]: {
    value: any;
    value_en?: any;
    valid: any;
  };
};

export const initForms: SettingsForms = {
  businessName: { value: "", valid: { valid: true, msg: "" } },
  name: { value: "", valid: { valid: true, msg: "" } },
  mail: { value: "", valid: { valid: true, msg: "" } },
  avatar: {
    value: "",
    valid: { valid: true, msg: "" },
  },
  phone: {
    value: { value: { value: "", country: " " } },
    valid: { valid: true, msg: "" },
  },
  birthDate: {
    value: "",
    valid: { valid: true, msg: "" },
  },
  receiveEmail: {
    value: false,
    valid: { valid: true, msg: "" },
  },
  timeZone: {
    value: "",
    valid: { valid: true, msg: "" },
  },
};

export const initFormsTalents: SettingsForms = {
  name: { value: "", value_en: "", valid: { valid: true, msg: "" } },
  bio: {
    value: "",
    value_en: "",
    valid: { valid: true, msg: "" },
  },
  videoPromo: {
    value: "",
    value_en: "",
    valid: { valid: true, msg: "" },
  },
  bioShort: {
    value: "",
    value_en: "",
    valid: { valid: true, msg: "" },
  },
  username: {
    value: "",
    valid: { valid: true, msg: "" },
  },
  donationPercent: { value: "", valid: true },
  isDonation: { value: "", valid: true },
  category: { value: "", valid: true },
};

export const tryGetNumber = (
  configService: ConfigService,
  object: { phone: string },
  country: CountryCode
) => {
  let countryInput = "US";
  let phoneInput = "";
  try {
    phoneInput = object.phone
      ? `${
          parsePhoneNumber(
            configService.addPlusToNumber(object.phone),
            upperCase(country || "") as any
          ).countryCallingCode
        }${
          parsePhoneNumber(
            configService.addPlusToNumber(object.phone),
            upperCase(country || "") as any
          ).nationalNumber
        }`
      : phoneInput;
    countryInput = object.phone
      ? (parsePhoneNumber(
          configService.addPlusToNumber(object.phone),
          upperCase(country || "") as any
        ).country as string)
      : countryInput;
  } catch (err) {
    console.log(err);
  }
  return { phoneInput, countryInput };
};

export const categoriesToCategoriesOptions = (categoriesObj: ICategory[]) => {
  if (categoriesObj) {
    const categories = categoriesObj.map((item) => ({
      id: item.id,
      name: item.categoryName,
    }));
    const categoriesList = categories.slice(1);
    return categoriesList.map((item) => ({ id: item.id, value: item.name }));
  }
  return [];
};

export const updateUserProfile = async (
  headers: IServiceHeaders,
  formUser: SettingsForms,
  profile: IProfile,
  usersService: UsersService,
  configService: ConfigService
) => {
  const submitFormUser = Object.keys(formUser).reduce((values, key) => {
    values[key] = formUser[key].value;
    return values;
  }, {} as { [key: string]: string });
  const { phoneInput } = tryGetNumber(
    configService,
    { phone: (submitFormUser.phone as any).value },
    (submitFormUser.phone as any).country as any
  );
  const response = await usersService.updateProfile(
    headers,
    {
      fullName: submitFormUser.name,
      email: submitFormUser.mail,
      phone: phoneInput,
      birthDate:
        submitFormUser.birthDate &&
        DateTime.fromFormat(submitFormUser.birthDate, "dd/mm/yyyy").isValid
          ? DateTime.fromFormat(
              submitFormUser.birthDate,
              "dd/mm/yyyy"
            ).toFormat("mm/dd/yyyy")
          : null,
      receiveEmail: !!submitFormUser.receiveEmail as boolean,
      avatar: submitFormUser.avatar,
    },
    profile,
    () => {}
  );
  if (response.ok) {
    return {
      ok: true,
      updateProfile: {
        ...profile,
        email: submitFormUser.mail,
        avatar:
          submitFormUser.avatar && !isString(submitFormUser.avatar)
            ? URL.createObjectURL(submitFormUser.avatar as any)
            : profile.avatar,
        fullName: submitFormUser.name,
        phone: (submitFormUser.phone as any).value,
        canReceiveEmail: submitFormUser.receiveEmail,
        birthDate: submitFormUser.birthDate,
      },
    };
  }
  return { ok: false };
};

export const updateTalentProfile = async (
  headers: IServiceHeaders,
  formUser: SettingsForms,
  formTalent: SettingsForms,
  profile: IProfile,
  usersService: UsersService,
  configService: ConfigService
) => {
  const submitFormUser = Object.keys(formUser).reduce((values, key) => {
    values[key] = formUser[key].value;
    return values;
  }, {} as { [key: string]: string });
  const submitFormTalent = Object.keys(formTalent).reduce((values, key) => {
    values[key] = formTalent[key].value;
    return values;
  }, {} as { [key: string]: string });
  const submitFormTalentEn = Object.keys(formTalent).reduce((values, key) => {
    values[key] = formTalent[key].value_en;
    return values;
  }, {} as { [key: string]: string });
  const { phoneInput } = tryGetNumber(
    configService,
    { phone: (submitFormUser.phone as any).value },
    (submitFormUser.phone as any).country as any
  );
  const response = await usersService.updateProfile(
    headers,
    {
      englishName: submitFormTalentEn.name,
      fullName: submitFormTalent.name,
      bio: submitFormTalent.bio,
      bioEn: submitFormTalentEn.bio,
      email: submitFormUser.mail,
      username: submitFormTalent.username,
      shortBio: submitFormTalent.bioShort,
      shortBioEn: submitFormTalentEn.bioShort,
      birthDate:
        submitFormUser.birthDate &&
        DateTime.fromFormat(submitFormUser.birthDate, "dd/mm/yyyy").isValid
          ? DateTime.fromFormat(
              submitFormUser.birthDate,
              "dd/mm/yyyy"
            ).toFormat("mm/dd/yyyy")
          : null,
      video: submitFormTalent.videoPromo,
      avatar: submitFormUser.avatar,
      socialMedias: (submitFormTalent.socialMedias || []) as any,
      donationPercent: submitFormTalent.donationPercent,
      isDonation: submitFormTalent.isDonation,
      timezone: submitFormUser.timeZone,
      phone: phoneInput,
      category: ((submitFormTalent.category as any) || []).map(
        (category: { id: any }) => {
          return category.id;
        }
      ),
    },
    profile,
    () => {}
  );
  if (response.ok) {
    return {
      ok: true,
      updateProfile: {
        ...profile,
        email: submitFormUser.mail,
        avatar:
          submitFormUser.avatar && !isString(submitFormUser.avatar)
            ? URL.createObjectURL(submitFormUser.avatar as any)
            : profile.avatar,
        avatarVideo: !submitFormTalent.videoPromo
          ? ""
          : submitFormTalent.videoPromo &&
            !isString(submitFormTalent.videoPromo)
          ? URL.createObjectURL(submitFormTalent.videoPromo as any)
          : (profile as IProfile).avatarVideo,
        fullName: submitFormUser.name,
        phone: (submitFormUser.phone as any).value,
        timezone: submitFormUser.timeZone,
        canReceiveEmail: submitFormUser.receiveEmail,
        birthDate: submitFormUser.birthDate,
      },
    };
  }
  return { ok: false };
};
