import "./fileInput.scss";
import { ReactComponent as UserSvg } from "../../assets/IconUser.svg";
import { ReactComponent as SvgCamera } from "../../assets/IconCamera.svg";
import { ReactComponent as SvgVideo } from "../../assets/IconVideo.svg";
import { isEmpty, isString } from "lodash";
import FilesExamples from "./FilesExamples/filesExamples";
import { useContext, useState } from "react";
import { strings } from "../../localizedStrings";
import { LocalizationContext } from "../../context/localization";
import classNames from "classnames";
import CloseButton from "../CloseButton/closeButton";
import ReactPlayer from "react-player";
export interface IFileInput {
  type: "video" | "image";
  value: string;
  onChange: (file: any) => void;
  examples?: string[];
  required?: boolean;
}

const FileInput = ({
  type = "image",
  required = false,
  onChange = (file: any) => {},
  value,
  examples = [],
}: IFileInput) => {
  const [showExamples, setShowExamples] = useState(false);
  const { isRTL } = useContext(LocalizationContext);

  return (
    <>
      <FilesExamples
        show={showExamples}
        videosUrls={examples}
        dismiss={() => {
          setShowExamples(false);
        }}
      />
      <div className={classNames("file-input-wrapper", { ltr: !isRTL })}>
        <input
          onChange={(e) => {
            const files = e.target.files || [];
            if (files.length === 1) {
              const patternImage = /image*/;
              const patternVideo = /video*/;

              if (type === "image") {
                files[0].type.match(patternImage) && onChange(files[0]);
              } else if (type === "video") {
                files[0].type.match(patternVideo) && onChange(files[0]);
              }
            }
          }}
          name="video"
          accept={
            type === "video"
              ? "video/mp4,video/x-m4v,video/*;capture=camera"
              : "image/*;capture=camera"
          }
          type="file"
          className="video-input"
        />
        <div className="label-container">
          <div className="label">
            {type === "video" ? strings.videoLabel : strings.imageLabel}
            {required ? <span className="required"> *</span> : <></>}
          </div>
          {!isEmpty(examples) ? (
            <div
              className="example"
              onClick={() => {
                setShowExamples(true);
              }}
            >
              {strings.simples}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="file-input-container">
          {value && (
            <CloseButton
              onClick={() => {
                onChange(null);
              }}
            />
          )}
          {type === "image" ? (
            value ? (
              <img
                loading="lazy"
                alt="avatar"
                className="avatar-promo"
                src={
                  isString(value)
                    ? value
                    : value
                    ? URL.createObjectURL(value as any)
                    : ""
                }
              />
            ) : (
              <></>
            )
          ) : (
            <>
              {value ? (
                <ReactPlayer
                  playing={true}
                  muted={true}
                  loop={true}
                  width={"100%"}
                  height={"100%"}
                  className="video-player"
                  controls={false}
                  url={
                    isString(value)
                      ? value
                      : value
                      ? URL.createObjectURL(value as any)
                      : ""
                  }
                />
              ) : (
                <></>
              )}
            </>
          )}

          <UserSvg className={"avatar-placeholder"} />
          <div className="input-container">
            {type === "image" ? (
              <SvgCamera color={"#ffffff"} width={"24px"} height={"24px"} />
            ) : (
              <></>
            )}
            {type === "video" ? (
              <SvgVideo
                fill={"#ffffff"}
                color={"#ffffff"}
                width={"24px"}
                height={"24px"}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* {isEmpty(examples) ? (
          <></>
        ) : (
          <div
            onClick={() => {
              setShowExamples(true);
            }}
            className="example-text"
          >
            {strings.simples}
          </div>
        )} */}
      </div>
    </>
  );
};

export default FileInput;
