import Service from "../../service";
import { gql } from "@apollo/client";

export interface Article {
  id: string;
  slug: string;
  title: string;
  isLoading?: boolean;
  description: string;
  content: string;
  language: {
    languageId: string;
  };
  image: {
    name: string;
    url: string;
  };
}

export default class BlogService extends Service {
  async getAllArticles() {
    const query = gql`
      query {
        articles(sort: "id:DESC") {
          id
          slug
          title
          description
          language {
            languageId
          }
          image {
            name
            url
          }
        }
      }
    `;
    const response = await this.query<{ data: { articles: Article[] } }>(query);    
    return response!.data.articles as Article[];
  }
  async getArticleBySlug(slug: string) {
    const query = gql`
      query ($slug: String!) {
        articles(where: { slug: $slug }) {
          slug
          title
          description
          language {
            languageId
          }
          content
        }
      }
    `;
    const response = await this.query<{ data: { articles: Article[] } }>(
      query,
      { slug }
    );
    return response!.data.articles as Article[];
  }
}
