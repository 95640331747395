import classNames from "classnames";
import "./accordionOrders.scss";
import { ReactComponent as IconArrow } from "../../../../../assets/IconArrow.svg";
import { useCallback, useContext, useEffect, useState } from "react";
import classnames from "classnames";
import { strings } from "../../../../../localizedStrings";
import { TailSpin } from "react-loader-spinner";
import { LocalizationContext } from "../../../../../context/localization";
import {
  IOrder,
  IOrdersFilters,
  IStatus,
} from "../../../../../services/v2/search/search.interfaces";
import { IServiceTypes } from "../../../../../services/v2/orders/orders.interface";
import { ServicesContext } from "../../../../../context/services";
import { removeEmptyFilters } from "../../../../Talents/talents.utils";
import { uniqBy } from "lodash";
import axios from "axios";
import { DateTime } from "luxon";
import { ApplicationContext } from "../../../../../context/application";
import { IProfile } from "../../../../../services/v2/users/users.interface";

export interface IAccordionOrdersProps {
  title: string;
  status: IStatus[];
  search: string;
  serviceType?: IServiceTypes | null;
  onClickOrders?: (order: IOrder) => void;
  showCount?: boolean;
  reloadEvent: React.MutableRefObject<(() => void)[]>;
}

const AccordionOrders = ({
  title,
  status,
  onClickOrders,
  serviceType,
  reloadEvent,
  search,
  showCount = true,
}: IAccordionOrdersProps) => {
  const { searchService, configService } = useContext(ServicesContext);
  const { profile } = useContext(ApplicationContext);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [isOpen, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState<number[]>([]);

  const [endFetch, setEndFetch] = useState<boolean>(false);

  const { isRTL, localization, country } = useContext(LocalizationContext);
  const [loading, setLoading] = useState(true);

  const fetchOrder = useCallback(async () => {
    setLoading(true);
    const cancelToken = axios.CancelToken.source();

    const { orders, ok, next } = await searchService.searchTalentsOrdersByQuery(
      {
        language: localization,
        country,
        currency: (profile as IProfile).currency || "USD",
      },
      { page: page, size: 10 },
      {
        filters: removeEmptyFilters<IOrdersFilters>({
          status: status,
          // serviceType,
          //ToDo: add search
          // classification: "service",
          or: removeEmptyFilters<IOrdersFilters>({
            id: parseInt(search) ? Number(search) : undefined,
            receiverName: search,
            requesterName: search,
          } as IOrdersFilters),
        } as IOrdersFilters) as IOrdersFilters,
        relationToFollowingTypes: "and",
        orderBy: {
          field: "order.createdAt",
          method: "DESC",
        },
      },
      ["campaign"],
      cancelToken
    );

    if (ok) {
      setEndFetch(!next);
      setPages((prevPages) => [...prevPages, page]);
      setOrders((pervOrders) =>
        uniqBy([...pervOrders, ...orders], "id").sort((a, b) => {
          return (
            new Date(b.orderedAt).getTime() - new Date(a.orderedAt).getTime()
          );
        })
      );
    }
    setLoading(false);
    return () => {
      cancelToken.cancel();
    };
  }, [
    country,
    localization,
    page,
    profile,
    search,
    searchService,
    serviceType,
    status,
  ]);

  useEffect(() => {
    if (pages.includes(page)) {
      return;
    } else {
      fetchOrder();
    }
  }, [fetchOrder, page, pages]);

  const reload = useCallback(() => {
    setPage(1);
    setPages([]);
    setEndFetch(false);
    setOrders([]);
    fetchOrder();
  }, [fetchOrder]);

  useEffect(() => {
    if (reloadEvent.current) {
      reloadEvent.current.push(reload);
    }
  }, [reload, reloadEvent]);
  return (
    <>
      <div className={classnames("accordion-wrapper", { ltr: !isRTL })}>
        <div
          className="accordion-container"
          onClick={(e) => {
            setOpen(!isOpen);
            e.stopPropagation();
          }}
        >
          <div className="title">{title}</div>
          <div className="end-container">
            <div
              className={classnames("notification-round", {
                disable: !showCount || !orders.length,
              })}
            >
              <span>{Number(orders.length)}</span>
            </div>

            <div
              onClick={() => {
                setOpen(!isOpen);
              }}
              className={classNames("toggle", {
                open: isOpen,
              })}
            >
              <IconArrow
                transform="scale (-0.5, 0.5)"
                transform-origin="center"
              />
            </div>
          </div>
        </div>
        <div
          className={classNames("panel", {
            open: isOpen,
            ltr: !isRTL,
          })}
        >
          <div className="header-title">
            <div>{strings.date}</div>
            <div>{strings.orderName}</div>
            <div>{"Campaign goal"}</div>
            <div>{strings.price}</div>
          </div>
          {orders.map((item: IOrder) => {
            return (
              <div
                className="order-row"
                onClick={() => {
                  onClickOrders && onClickOrders(item);
                }}
              >
                <div>
                  {configService.formatTime(
                    DateTime.fromISO(item.orderedAt),
                    true
                  )}
                </div>
                <div>{(item as any)?.campaign?.brandName || ""}</div>
                <div>{(item as any)?.campaign?.campaignGoal || ""}</div>
                <div>{(item as any)?.talentPrice}</div>
              </div>
            );
          })}
          {loading ? (
            <div className="show-more">
              <TailSpin color="#F24462" height={40} width={40} />
            </div>
          ) : (
            <></>
          )}
          {!endFetch && !loading ? (
            <div
              className="show-more"
              onClick={() => {
                setPage((page) => page + 1);
              }}
            >
              {strings.showMore}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default AccordionOrders;
