import "./loader.scss";
import Loader1024 from "../../assets/loader/loader.webp";

const Loader = ({ height }: { height: number }) => {
  return (
    <div className="loader-continer">
      <img
        style={{ height: `${height}px` }}
        alt="loading..."
        src={Loader1024}
      />
    </div>
  );
};

export default Loader;
