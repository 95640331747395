/* eslint-disable react-hooks/rules-of-hooks */
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./orderContent.scss";
import "react-lazy-load-image-component/src/effects/blur.css";
import VideoCard from "../../VideoCarousel/VideoCard/videoCard";
import parseMs from "parse-ms";
import useCountdown from "react-use-countdown";
import {
  IOrderStatus,
  IServiceTypes,
} from "../../../services/v2/orders/orders.interface";
import { DateTime } from "luxon";
import { attachS3Url } from "../../../helpers/s3.utils";
export interface IOrderContent {
  talentImage: string;
  content?: { type: IServiceTypes; content: any };
  type: IOrderStatus | "loading";
  meetingDate?: DateTime;
  talentName: string;
}

const OrderContent = ({
  talentImage,
  content,
  meetingDate,
  type,
  talentName,
}: IOrderContent) => {
  let count = 0;
  if (meetingDate) {
    count = useCountdown(() => meetingDate.toJSDate());
  }
  const { days, hours, minutes, seconds } = parseMs(count);

  return (
    <div className={"order-talent-image"}>
      {content?.type === "video" ? (
        <div className={"video order"}>
          <VideoCard
            controls={true}
            url={
              !(content.content || "").includes("public")
                ? attachS3Url(`public/${content.content}`)
                : attachS3Url(content.content)
            }
          />
        </div>
      ) : (
        <>
          <LazyLoadImage
            alt={talentName}
            effect="blur"
            height={"90%"}
            className={"talent"}
            src={talentImage}
          />
          {content?.type === "meeting" &&
          (type === "accepted" || type === "pending") ? (
            <div
              className={"countdown-text"}
            >{`${days} d: ${hours}h: ${minutes}m: ${seconds}s`}</div>
      ) : (
        <></>
      )}
    </>
  )
}
    </div >
  );
};

export default OrderContent;
