import "./popUpMessage.scss";
import { Modal } from "rsuite";
import CloseButton from "../../components/CloseButton/closeButton";
import { strings } from "../../localizedStrings";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";

export interface IPopupMessageProps {
  title: string;
  message: string;
  dismiss: () => void;
  show: boolean;
  showError?: boolean;
  error?: string;
}

const PopupMessage = ({
  title,
  message,
  dismiss,
  show,
  showError = false,
  error = "",
}: IPopupMessageProps) => {
  const [, setMode] = useState<"close" | "error">("error");
  const mobileView = useMediaQuery("(max-width: 500px)") as boolean;

  useEffect(() => {
    setMode(showError ? "error" : "close");
  }, [showError]);
  return (
    <Modal
      size="xs"
      style={{ margin: mobileView ? 0 : "150px auto 0 auto" }}
      dialogClassName="popup-message-modal"
      backdropClassName={"popup-message-backdrop"}
      backdrop={true}
      show={show}
      onHide={() => {}}
    >
      <CloseButton
        onClick={() => {
          setMode("close");
          dismiss();
        }}
      />
      <div className="title">{title}</div>
      {false ? (
        <>
          <div className="error-border">{error}</div>
          <div
            className="close"
            onClick={() => {
              setMode("close");
              dismiss();
            }}
          >
            {strings.close}
          </div>
        </>
      ) : (
        <>
          <div className="message">{message}</div>

          <div
            className="close"
            onClick={() => {
              if (showError) {
                setMode("error");
              } else {
                setMode("close");
                dismiss();
              }
            }}
          >
            {showError ? strings.showError : strings.close}
          </div>
        </>
      )}
    </Modal>
  );
};

export default PopupMessage;
