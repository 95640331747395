
import classNames from 'classnames';
import { useState } from 'react';
import { ReactComponent as IconArrow } from "../../assets/IconArrow.svg";
import './accordionInputs.scss'

const AccordionInputs = ({ children }: { children: any }) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <div className="advance-container">
            <div
                className="label-advance"
                onClick={() => {
                    setOpen(!isOpen);
                }}
            >
                <div className="text">Advanced options</div>
                <div
                    className={classNames("toggle", {
                        open: isOpen,
                    })}
                >
                    <IconArrow transform="scale (-0.5, 0.5)" transform-origin="center" />
                </div>
            </div>
            <div
                className={classNames("panel-advance", {
                    open: isOpen,
                })}
            >
                {children}
            </div>
        </div>
    );
};
export default AccordionInputs;
