import classnames from "classnames";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../context/localization";
import "./inputWithSubmit.scss";

export interface IInputWithSubmitProps {
  placeholder: string;
  submitText: string;
  error?: string;
  initValue: string;
  onChange?: (str: string) => void;
  onSubmit?: (str: string) => Promise<void>;
}

const InputWithSubmit = ({
  placeholder,
  submitText,
  initValue,
  error = "",
  onSubmit = async () => {},
  onChange = () => {},
}: IInputWithSubmitProps) => {
  const { isRTL } = useContext(LocalizationContext);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(error);
  const [value, setValue] = useState(initValue);
  useEffect(() => {
    setErrorMsg(error);
  }, [error]);
  useEffect(() => {
    setErrorMsg("");
  }, [value]);
  return (
    <div className={classnames("input-container", { ltr: !isRTL })}>
      <div className={classNames("submit-input-container", { ltr: !isRTL })}>
        <input
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          value={value}
          placeholder={placeholder}
          className={"input"}
        ></input>
        <div
          className={classnames("input-submit", { disable: loading })}
          onClick={async () => {
            if (!loading) {
              setLoading(true);
              await onSubmit(value);
              setLoading(false);
            }
          }}
        >
          <div>{submitText}</div>
        </div>
      </div>
      {!isEmpty(errorMsg) ? (
        <div className="error-container">
          <label className="error-info">{`* ${errorMsg}`}</label>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default InputWithSubmit;
