import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Modal } from "rsuite";
import CloseButton from "../../../CloseButton/closeButton";

import { ReactComponent as UserSvg } from "../../../../assets/IconUser.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./avatar.scss";
import { useMediaQuery } from "@react-hook/media-query";
import classNames from "classnames";
export interface IAvatar {
  url?: string;
  disable?: boolean;
  onClick?: () => void;
  border?: boolean;
  shouldShowPopup?: boolean;
}
const Avatar = ({
  url,
  disable = false,
  onClick = () => {},
  border = true,
  shouldShowPopup = false,
}: IAvatar) => {
  const [imageUrl, setImageUrl] = useState(url ? url : "");
  const [showPopup, setShowPopup] = useState(false);
  const mobileView = useMediaQuery("(max-width: 550px)") as boolean;

  useEffect(() => {
    setImageUrl(url ? url : "");
  }, [url]);

  return (
    <>
      <Modal
        size="xs"
        dialogClassName="avatar-modal"
        backdropClassName={"avatar-backdrop"}
        backdrop={true}
        style={{
          margin: mobileView ? "40px auto 0 auto" : "50px auto 0 auto",
          maxWidth: mobileView ? "90%" : "400px",
          minWidth: mobileView ? "90%" : "400px",
        }}
        show={showPopup}
        onHide={() => {
          setShowPopup(false);
        }}
      >
        <CloseButton
          onClick={() => {
            setShowPopup(false);
          }}
        />
        <LazyLoadImage
          effect="blur"
          height={"500px"}
          alt={"user"}
          style={{ width: "100%", height: "500px", objectFit: "cover" }}
          placeholder={<UserSvg className={"avatar-placeholder"} />}
          src={imageUrl}
          className="avatar-image"
        />
      </Modal>
      <div
        onClick={() => {
          if (shouldShowPopup) {
            setShowPopup(true);
          }
          if (!disable) {
            onClick();
          }
        }}
        className={classNames("avatar", { "with-out-border": !border })}
      >
        <div className={"avatar-inner"}>
          {isEmpty(imageUrl) ? (
            <UserSvg className={"avatar-placeholder"} />
          ) : (
            <LazyLoadImage
              effect="blur"
              height={"100%"}
              alt={"user"}
              style={{ width: "100%", height: "100%" }}
              placeholder={<UserSvg className={"avatar-placeholder"} />}
              src={imageUrl}
              className="avatar-image"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Avatar;
