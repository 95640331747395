import { isArray, isBoolean, isEmpty, pickBy } from "lodash";
import { ModeType } from "./talents.interface";

export const removeEmptyFilters = <T extends object>(filters: T) => {
  return pickBy(filters, (item, key) => {
    if (key === "orderType") {
      return false;
    }
    if (isArray(item) && item[0] === "0") {
      return false;
    }

    return (
      (!isEmpty(item) && item) ||
      (isBoolean(item) && item) ||
      parseInt(item as unknown as string)
    );
  });
};

export const getFiltersBySearchParams = (location: any, categoryId: number) => {
  const query = new URLSearchParams(location.search);
  if (query.has("name")) {
    return { mode: ModeType.search, name: query.get("name") };
  } else if (categoryId) {
    const categories = [categoryId];
    return {
      mode: ModeType.filters,
      categories,
      hashtag: query.get("hashtag"),
    };
  } else if (query.has("hashtag")) {
    return {
      mode: ModeType.filters,
      categories: [],
      hashtag: query.get("hashtag"),
    };
  } else if (query.has("category")) {
    const categories = (query.get("category") || "").split(";") || [];
    return {
      mode: ModeType.filters,
      categories,
      hashtag: query.get("hashtag"),
    };
  }
};

export const getQueryForNameSearch = (name: string, orderBy: any) => {
  return {
    filters: {
      name: name || "",
      englishName: name || "",
    },
    relationToFollowingTypes: "or",
    orderBy: orderBy as any,
  };
};

export const getQueryByFilters = (filters: any, orderBy: any) => {
  return {
    filters: removeEmptyFilters(filters),
    relationToFollowingTypes: "and",
    orderBy: orderBy as any,
  };
};

export const isNotEnd = (endFetch: boolean, favorites: any) => {
  return !endFetch && isArray(favorites);
};
