import moment from "moment";
import { useEffect } from "react";
import { LocalizationTypes } from "../context/localization";
import { strings } from "../localizedStrings";

const HOURS_DAY = 24;

export const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};



export const downloadFile = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const urlToFile = async (url: string, fileName: string) => {
  try {
    const blob = await fetch(url).then((r) => r.blob());

    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type: blob.type,
    });
  }
  catch (e) {
    return new File([], fileName, {
      lastModified: new Date().getTime(),
      type: 'application/octet-stream',
    });
  }
};

export const viewportToPixels = (value: string) => {
  var parts: any = value.match(/([0-9.]+)(vh|vw)/)
  var q = Number(parts[1])
  var side = (window as any)[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
  return side * (q / 100)
}

export const numberWithCommas = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatNumber = (n: number) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const formatNumberObject = (n: number) => {
  if (n < 1e3) return { number: n, suffix: "" };
  if (n >= 1e3 && n < 1e6)
    return { number: +(n / 1e3).toFixed(1), suffix: "K" };
  if (n >= 1e6 && n < 1e9)
    return { number: +(n / 1e6).toFixed(1), suffix: "M" };
  if (n >= 1e9 && n < 1e12)
    return { number: +(n / 1e9).toFixed(1), suffix: "B" };
  if (n >= 1e12) return { number: +(n / 1e12).toFixed(1), suffix: "T" };
  return { number: n, suffix: "" };
};

export const splitByUrl = (string: string): Element[] => {
  return string.split(/\b(https?:\/\/\S*\b)/g).map((item) => {
    if (/\b(https?:\/\/\S*\b)/g.test(item)) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={item}>
          {item}
        </a>
      );
    }
    return <span>{item}</span>;
  }) as any;
};

export const getDays = (localizationType: LocalizationTypes) => {
  const days = [
    strings.sunday2,
    strings.monday2,
    strings.tuesday2,
    strings.wednesday2,
    strings.thursday2,
    strings.friday2,
    strings.saturday2,
  ];
  let firstDayIndex = localizationType === "he" ? 0 : 1;
  return days.reduce((daysArray, day, index) => {
    daysArray[index] = days[firstDayIndex % 7];
    firstDayIndex += 1;
    return daysArray;
  }, [] as string[]);
};

export const ImageFromUrl = (url: string) => {
  return fetch(url).then((response) => {
    return response.blob();
  });
};

export const copyToClipboard = (content: any) => {
  const el = document.createElement("textarea");
  el.value = content;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const delay = async (ms: number) => {
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(ms);
    }, ms);
  });
};

/* eslint-disable no-invalid-regexp */
export const stringToHours = (string: string) => {
  switch (string) {
    case "12H":
      return 12;
    case "1DAY":
      return 24;
    case "2AY":
      return 48;
    case "3DAY":
      return 72;
    case "1WEEK":
      return 168;
    default:
      return 168;
  }
};

export const intToString = (value: number) => {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(value);
};

export const IsValidEmail = (string: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(string).toLowerCase());
};

export const timeStringToFloat = (time: string) => {
  var hoursMinutes = time.split(/[.:]/);
  var hours = parseInt(hoursMinutes[0], 10);
  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  return hours + minutes / 60;
};

export const floatToTimeString = (time: number) => {
  return moment
    .utc(moment.duration(time, "h").asMilliseconds())
    .format("HH:mm");
};

export const iOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const timeSlots = (
  startTime: string,
  endTime: string,
  slotInterval: number
) => {
  const startTimeDate = moment(startTime, "HH:mm:ss");
  const endTimeDate = moment(endTime, "HH:mm:ss");
  if (endTimeDate.isBefore(startTimeDate)) {
    endTimeDate.add(1, "day");
  }
  const allTimes = [];
  while (startTimeDate < endTimeDate) {
    allTimes.push(startTimeDate.format("HH:mm"));
    startTimeDate.add(slotInterval, "minutes");
  }
  return allTimes;
};

export const getTimeWithTimeZoneToLocal = (time: string, gmt: number) => {
  return moment(
    moment().format(`YYYY-MM-DDT`) +
    time +
    ".000" +
    padWithZero(gmt, 2) +
    ":00",
    "YYYY-MM-DDTHH:mm:ssZ"
  )
    .local(true)
    .format("HH:mm");
};

export const getUTCtoTimeByGMT = (time: string, gmt: number) => {
  const currentTime = moment
    .utc(
      moment(
        moment().format(`YYYY-MM-DDT`) + time + ".000" + padWithZero(0, 2),
        "YYYY-MM-DDTHH:mm:ssZ"
      )
        .toDate()
        .getTime()
    )
    .toDate()
    .getTime();
  const totalSeconds = Math.floor(currentTime / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = ("0" + (totalMinutes % 60)).slice(-2);
  const totalHours = Math.floor(totalMinutes / 60);
  const hours = ("0" + ((totalHours + gmt) % 24)).slice(-2);

  const timeDisplay = hours + ":" + minutes + ":00";
  return timeDisplay;
};

export const localTimeToUTCTime = (time: string) => {
  return moment
    .utc(
      moment(
        moment().format(`YYYY-MM-DDT`) + time + ".000" + getClientGmt(),
        "YYYY-MM-DDTHH:mm:ssZ"
      )
        .toDate()
        .getTime()
    )
    .format("HH:mm");
};

export const getClientGmt = () => {
  return moment().format("Z");
};

export const negativeTimeOffset = (from: string, to: string) => {
  const startTime = moment(from, "HH:mm");
  const endTime = moment(to, "HH:mm");
  if (startTime.diff(endTime) > 0) {
    const [hours, minutes, seconds] = to.split(":");
    const fixHours = padWithZero(HOURS_DAY - parseInt(hours), 2, false);
    return seconds ? `${fixHours}:${minutes}` : `${fixHours}:${minutes}`;
  }
  return to;
};

export const padWithZero = (
  number: number,
  count: number,
  withSign: boolean = true
) => {
  const sign = Math.sign(number) === -1 ? "-" : "+";
  return (
    (withSign ? sign : "") +
    new Array(count)
      .concat([Math.abs(number)])
      .join("0")
      .slice(-count)
  );
};

export const dateByDay = (
  startTime: moment.Moment,
  endTime: moment.Moment,
  day: number
) => {
  var result: moment.Moment[] = [];
  // Copy start date
  var current = new Date(startTime.toDate());
  // Shift to next of required days
  current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));
  // While less than end date, add dates to result array
  while (current < endTime.toDate()) {
    result.push(moment(new Date(+current)));
    current.setDate(current.getDate() + 7);
  }
  return result;
};

export const getKeyByValue = (obj: { [x: string]: any }, value: any) =>
  Object.keys(obj).find((key) => obj[key] === value);

export const getGmtFromTz = (timezone: string) => {
  return parseInt(
    new Date()
      .toLocaleString("en", { timeZone: timezone, timeStyle: "long" })
      .split(" ")
      .slice(-1)[0]
      .slice(3)
  );
};

export const addPlusToNumber = (number: any) => {
  if (!number) {
    return number;
  }
  return number[0] === "+" ? number : "+" + number;
};



export const importBot = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `window.addEventListener("mouseover", initLandbot, { once: true });
    window.addEventListener("touchstart", initLandbot, { once: true });
    var myLandbot;
    function initLandbot() {
        if (!myLandbot) {
            var s = document.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.addEventListener("load", function () {
                var myLandbot = new Landbot.Livechat({
                    configUrl:
                        "https://chats.landbot.io/v3/H-1216809-JKG71L7I3713R4KJ/index.json",
                });
            });
            s.src = "https://cdn.landbot.io/landbot-3/landbot-3.0.0.js";
            var x = document.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
        }
    }`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
};
