import { CancelTokenSource } from "axios";
import { isEmpty } from "lodash";
import Service from "../../service";
import { IServiceHeaders, IServicePagination } from "../../service.interface";
import {
  IBanners,
  IBusinessHomePage,
  ICategory,
  IHomeResponse,
  INewsFeedPost,
  INewsFeedPromterd,
  INewsFeedTalent,
} from "./newsFeed.interfaces";

export default class NewsFeedService extends Service {
  
  async feedPosts(
    headers: IServiceHeaders,
    pagination: IServicePagination
  ): Promise<{
    results: INewsFeedPost[];
    hasNext: boolean;
  }> {
    const { data } = await this.get<{
      results: INewsFeedPost[];
      hasNext: boolean;
    }>(`/feed?page=${pagination.page}&size=${pagination.size}`, {
      headers: this.attachHeaders(headers),
    });
    return data;
  }

  async businessHomePage(headers: IServiceHeaders, categoriesIds: string[]) {
    const { data } = await this.post<IBusinessHomePage[]>(
      `/business/home`,
      categoriesIds,
      {
        headers: this.attachHeaders(headers),
      }
    );

    return Promise.all(
      data.map(async (categories) => {
        return Object.assign(categories, {
          talentsAvatars: await Promise.all(
            categories.talentsAvatars.map(this.getS3Url)
          ),
        });
      })
    );
  }

  async newTalents(headers: IServiceHeaders) {
    const { data } = await this.get<{
      results: INewsFeedTalent[];
      hasNext: boolean;
    }>(`/talents/updated?page=${1}&size=10`, {
      headers: this.attachHeaders(headers),
    });
    return data as {
      results: INewsFeedTalent[];
      hasNext: boolean;
    };
  }

  async searchHashtag(headers: IServiceHeaders, hashtag: string) {
    const { data } = await this.get<string[]>(
      `/home/hashtags/${encodeURIComponent(hashtag)}`,
      {
        headers: this.attachHeaders(headers),
      },
      []
    );
    return data;
  }

  //TODO: Temp
  async feedPostsInstagram() {
    const { data } = await this.get<{ feed: string[] }>(
      `https://api.letstok.com/feed/api/v1/letstok-instagram/`,
      {},
      { feed: [] }
    );
    return data.feed;
  }

  //TODO: Temp
  async talentYouTubeVideo(userName: string) {
    if (isEmpty(userName)) {
      return [];
    }
    const regExpYouTube =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\\&v=)([^#\\&\\?]*).*/;

    const { data } = await this.get<{
      youtube: {
        thumbnail: string;
        title: string;
        url: string;
      }[];
    }>(`https://api.letstok.com/youtube/${userName}/`, {}, { youtube: [] });
    return data.youtube.map((video) => ({
      ...video,
      id: video.url.match(regExpYouTube)![2] || "",
    }));
  }

  async homePage(
    headers: IServiceHeaders,
    categoriesPaging: { [key: string]: { size: number; page: number } },
    categories: ICategory[],
    cancelToken: CancelTokenSource
  ) {
    const { data: talents } = await this.post<{
      [key: number]: { hasNext: boolean; results: INewsFeedTalent[] };
    }>(
      `/categories/talents`,
      categoriesPaging,
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
        cancelToken: cancelToken ? cancelToken.token : undefined,
      },
      {}
    );
    if (!isEmpty(talents)) {
      return (
        categories.map((category) => {
          const talentOfCategory = (talents[category.id]?.results || []).map(
            (item) => {
              return { ...item };
            }
          );
          const hasNext = talents[category.id]?.hasNext || false;
          return {
            name: category.categoryName,
            category_order: Number(category.order),
            category_id: category.id,
            talents: talentOfCategory,
            hasNext: hasNext,
          };
        }) as IHomeResponse[]
      ).filter((category) => {
        return !isEmpty(category.talents);
      });
    }
    return [];
  }

  async orderHomePage(home: IHomeResponse[], favorites: string[] = []) {
    return Promise.all(
      home.map(async (category) => ({
        ...category,
        name: category.name,
        talents: await Promise.all(
          category.talents.map(async (talent) => ({
            ...talent,
            social: this.attachSocialMedia(talent),
            is_favorite: favorites.includes(talent.userId),
            avatar: (await this.getS3Url(talent.avatar)) || "",
          }))
        ).then((talents) => {
          return talents.sort((a, b) => {
            return (
              (parseInt(a.displayorder as any) || 999) -
              (parseInt(b.displayorder as any) || 999)
            );
          });
        }),
      }))
    );
  }

  async banners(headers: IServiceHeaders) {
    const { data } = await this.get<IBanners[]>(
      `/banners`,
      {
        headers: this.attachHeaders(headers),
      },
      []
    );
    return data;
  }

  async promotedProduct(
    headers: IServiceHeaders,
    pagination: IServicePagination
  ) {
    const { data } = await this.get<{
      hasNext: boolean;
      results: INewsFeedPromterd[];
    }>(
      `/promoted?page=${pagination.page}&size=${pagination.size}`,
      {
        headers: this.attachHeaders(headers),
      },
      { hasNext: false, results: [] }
    );
    return {
      ...data,
      results: await Promise.all(
        data.results.map(async (item) => ({
          ...item,
          talent: Object.assign(item.talent, {
            avatar: item.talent.avatar
              ? await this.getS3Url(item.talent.avatar)
              : null,
          }),
        }))
      ),
    };
  }

  async getCategory(headers: IServiceHeaders, cancelToken: CancelTokenSource) {
    const { data } = await this.get<ICategory[]>(
      "/categories",
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
        cancelToken: cancelToken ? cancelToken.token : undefined,
      },
      []
    );
    return data.sort((categoryA, categoryB) => {
      return parseInt(categoryA.order) - parseInt(categoryB.order);
    });
  }

  async getAllCategory(headers: IServiceHeaders, cancelToken: CancelTokenSource) {
    const { data } = await this.get<ICategory[]>(
      "/categories/all",
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
        cancelToken: cancelToken ? cancelToken.token : undefined,
      },
      []
    );
    return data.sort((categoryA, categoryB) => {
      return parseInt(categoryA.order) - parseInt(categoryB.order);
    });
  }
}
