import React from "react";
import { memo, useState } from "react";
import './inputWithLabel.scss'

const InputWithLabel = memo(
    ({
        input,
        otherInput,
        label,
        formik,
        inputKey,
        inputTransform,
        preLabel,
        required = false,
    }: {
        input: React.ReactElement;
        label: string;
        inputKey: string;
        formik: any;
        otherInput?: React.ReactElement | undefined;
        inputTransform?: (val: any) => any;
        preLabel?: string;
        required?: boolean;
    }) => {
        const [showOtherInput, setOtherInput] = useState(false);
        return (
            <div className="input-label-continer">
                {preLabel ? <div className="input-pre-label">{preLabel}</div> : <></>}
                <div className="input-label-container">
                    <div className="label">{label}</div>
                    {otherInput ? (
                        <div className="custom-label">
                            <span
                                className="other-label"
                                onClick={() => {
                                    setOtherInput(!showOtherInput);
                                }}
                            >
                                Custom
                            </span>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="input">
                    {React.cloneElement(
                        showOtherInput && otherInput ? otherInput : input,
                        {
                            value: formik.values[inputKey],
                            onChange: (value: any) => {
                                if (inputKey === "budget") {
                                    if (formik.values[inputKey][0] <= 5000) {
                                        formik.setFieldValue("influencerLevel", [1]);
                                    }
                                }
                                if (value.length) {
                                    formik.setFieldTouched(inputKey, true);
                                }
                                if (inputTransform) {
                                    return formik.setFieldValue(inputKey, inputTransform(value));
                                } else {
                                    formik.setFieldValue(inputKey, value);
                                }
                            },
                        }
                    )}
                </div>
                <div className="error-container">
                    {formik.errors[inputKey] && formik.touched[inputKey]
                        ? `* ${formik.errors[inputKey]}`
                        : null}
                </div>
            </div>
        );
    }
);

export default InputWithLabel;