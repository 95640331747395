import { parsePhoneNumber } from "libphonenumber-js";
import { camelCase, isBoolean, isEmpty, startCase, upperCase } from "lodash";
import { Social } from "../../../../components/SocialButton/socialButton";
import { IServiceHeaders } from "../../../../services/service.interface";
import ConfigService from "../../../../services/v1/config";
import {
  IProfile,
  IProfileUpdate,
} from "../../../../services/v2/users/users.interface";
import UsersService from "../../../../services/v2/users/users.service";
import { IFormTalent, IFormValue } from "../../talentWizard.interface";

export const keyByValue = (obj: any, value: any) => {
  return Object.keys(obj).find((k) => obj[k] === value);
};

export const checkValidDisplayStep = (form: IFormTalent) => {
  const allValid = Object.keys(form).every((key) => {
    if (
      isBoolean(((form as any)[key] as IFormValue).value) &&
      ((form as any)[key] as IFormValue).value
    ) {
      return true;
    }
    if (!((form as any)[key] as IFormValue).valid?.valid) {
      return false;
    }
    if (
      isEmpty(((form as any)[key] as IFormValue).value) &&
      isEmpty(((form as any)[key] as IFormValue).value_en)
    ) {
      return false;
    }
    if (key === "socialMedias") {
      return !!((form as any)[key] as IFormValue).value.filter(
        (item: any) => item.platform && item.url
      ).length;
    }
    return true;
  });

  return allValid;
};

export const attachErrorDisplayStep = (
  form: IFormTalent,
  showError: boolean
) => {
  if (!showError) {
    return form;
  }
  return Object.keys(form).reduce((newForm, key) => {
    switch (key) {
      case "fullName":
        newForm[key] = {
          ...(form as any)[key],
          valid: {
            valid:
              !isEmpty(((form as any)[key] as IFormValue).value) ||
              !isEmpty(((form as any)[key] as IFormValue).value_en),
            msg: "",
          },
        };
        break;
      case "username":
        newForm[key] = {
          ...(form as any)[key],
          valid: {
            valid: !isEmpty(((form as any)[key] as IFormValue).value),
            msg: "",
          },
        };
        break;
      case "category":
        newForm[key] = {
          ...(form as any)[key],
          valid: {
            valid: !isEmpty(((form as any)[key] as IFormValue).value),
            msg: "",
          },
        };
        break;
      case "socialMedias":
        const valid = !!((form as any)[key] as IFormValue).value.filter(
          (item: any) => item.platform && item.url
        ).length;
        newForm[key] = {
          ...(form as any)[key],
          valid: {
            valid: valid,
            msg: valid
              ? "Required at least one social network"
              : "Required at least one social network",
          },
        };
        break;
      case "shortBio":
        newForm[key] = {
          ...(form as any)[key],
          valid: {
            valid:
              !isEmpty(((form as any)[key] as IFormValue).value) ||
              !isEmpty(((form as any)[key] as IFormValue).value_en),
            msg: "",
          },
        };
        break;
      case "bio":
        newForm[key] = {
          ...(form as any)[key],
          valid: {
            valid:
              !isEmpty(((form as any)[key] as IFormValue).value) ||
              !isEmpty(((form as any)[key] as IFormValue).value_en),
            msg: "",
          },
        };
        break;

      case "phone":
        newForm[key] = {
          ...(form as any)[key],
          valid: {
            valid:
              !isEmpty(((form as any)[key] as IFormValue).value.value) &&
              ((form as any)[key] as IFormValue).valid,
            msg: "",
          },
        };
        break;
      default:
        newForm[key] = {
          ...(form as any)[key],
          valid: { valid: true, msg: "" },
        };
        break;
    }

    return newForm;
  }, {} as any);
};

export const updateProfile = async (
  email: string,
  form: IFormTalent,
  usersService: UsersService,
  configService: ConfigService,
  profile: IProfile,
  headers: IServiceHeaders,
  progressCallback: (progress: number) => void
) => {
  const submitFormTalent = Object.keys(form).reduce((values, key) => {
    values[key] = (form as any)[key].value;
    return values;
  }, {} as { [key: string]: string });
  const submitFormTalentEn = Object.keys(form).reduce((values, key) => {
    values[key] = (form as any)[key].value_en;
    return values;
  }, {} as { [key: string]: string });
  let phoneInput: any = "";
  try {
    phoneInput = parsePhoneNumber(
      (submitFormTalent.phone as any).value,
      upperCase(((submitFormTalent.phone as any).country as any) || "") as any
    )!.number
      ? `${
          parsePhoneNumber(
            (submitFormTalent.phone as any).value,
            upperCase(
              ((submitFormTalent.phone as any).country as any) || ""
            ) as any
          )!.number
        }`
      : null;
  } catch {}
  const language = upperCase(
    configService.getLanguageByCountry(submitFormTalent.country)
  );
  const req: IProfileUpdate = {
    fullName: submitFormTalent.fullName || submitFormTalentEn.fullName,
    englishName: submitFormTalentEn.fullName || submitFormTalent.fullName,
    username: submitFormTalent.username,
    referrer: submitFormTalent.referrer || "",
    email: email,
    shortBio: submitFormTalent.shortBio || submitFormTalentEn.shortBio,
    shortBioEn: submitFormTalentEn.shortBio || submitFormTalent.shortBio,
    bio: submitFormTalent.bio || submitFormTalentEn.bio,
    bioEn: submitFormTalentEn.bio || submitFormTalent.bio,
    category: ((submitFormTalent.category as any) || []).map(
      (category: { id: any }) => {
        return category.id;
      }
    ),
    country: keyByValue(
      configService.countryCode(),
      keyByValue(configService.getCountryMapping(), submitFormTalent.country)
    ),
    language: configService.getSupportedLanguage().includes(language)
      ? startCase(camelCase(language))
      : "En",
    socialMedias: [...((submitFormTalent.socialMedias || []) as any)],
    phone: phoneInput,
  };
  const response = await usersService.updateProfile(
    headers,
    req,
    profile,
    progressCallback
  );
  return response;
};

export const initFormDisplayForm = {
  fullName: { value: "", value_en: "", valid: { valid: true, msg: "" } },
  username: { value: "", valid: { valid: true, msg: "" } },
  referrer: { value: "none", valid: { valid: true, msg: "" } },
  shortBio: {
    value: "",
    value_en: "",
    valid: { valid: true, msg: "" },
  },
  bio: {
    value: "",
    value_en: "",
    valid: { valid: true, msg: "" },
  },

  country: {
    value: "",
    valid: { valid: true, msg: "" },
  },
  category: {
    value: [],
    valid: { valid: true, msg: "" },
  },
  phone: {
    value: { value: "", country: "en" },
    valid: { valid: true, msg: "" },
  },
  socialMedias: {
    value: [{ platform: Social.instagram, url: "", followers: null }],
    valid: { valid: true, msg: "" },
  },
  termsAndConditionsTalents: {
    value: false,
    valid: { valid: true, msg: "" },
  },
  activityAccept: {
    value: false,
    valid: { valid: true, msg: "" },
  },
};
