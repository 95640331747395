import "./chatList.scss";
import classNames from "classnames";
import { AutoSizer, List, ListRowProps } from "react-virtualized";

import { FC, useEffect, useState } from "react";
import Avatar from "../BusinessHeader/Avatar/avatar";
import { DateTime } from "luxon";

export interface IChat {
  username: string;
  date: DateTime;
  message: string;
  avatar: string;
  online: boolean;
  unread: boolean;
  nonfiction: number;
}

export interface IChatListProps {
  chats: IChat[];
  onIndexChange: (index: number) => void;
}

const ChatList: FC<IChatListProps> = ({ chats, onIndexChange }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    onIndexChange(activeIndex);
  }, [activeIndex, onIndexChange]);
  
  return (
    <>
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            rowCount={chats.length}
            rowHeight={80}
            rowRenderer={rowRenderer}
            width={width}
            data={chats}
            activeRow={activeIndex}
            onRowClick={(index: number) => {
              setActiveIndex(index);
            }}
          />
        )}
      </AutoSizer>
    </>
  );
};

const rowRenderer = ({ index, parent }: ListRowProps) => {
  const chat: IChat = parent.props.data[index];

  return (
    <div
      key={index}
      className={classNames("chat-item", {
        active: index === parent.props.activeRow,
        read: !chat.unread,
      })}
      onClick={() => {
        parent.props.onRowClick(index);
      }}
    >
      <div className="avatar-continer">
        <Avatar
          online={chat.online}
          disable
          gray={!chat.unread}
          url={chat.avatar}
        />
      </div>
      <div className="details-continer">
        <div className="details-top-row">
          <span className="name">{chat.username}</span>
          <span className="date">{chat.date.toFormat("HH:mm dd/MM/yyyy")}</span>
        </div>
        <div className="details-botton-row">
          <div className="message">
            {chat.message.length > (chat.nonfiction ? 60 : 65)
              ? chat.message.slice(0, chat.nonfiction ? 60 : 65) + "..."
              : chat.message}
          </div>
          {chat.nonfiction ? (
            <div className="nonfiction-container">
              <div className="nonfiction">{chat.nonfiction}</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatList;
