import classnames from "classnames";
import { ReactComponent as HeartSvg } from "../../../assets/IconHeart.svg";
import "./heart.scss";

export interface IHeartProps {
  checked: boolean;
  onCheck: (state:boolean) => void;
}

const Heart = ({ checked, onCheck }: IHeartProps) => {
  return (
    <div
      className="heart-container"
      onClick={(e) => {
        onCheck(!checked);
        e.stopPropagation();
        return false;
      }}
    >
      <div
        className={classnames("heart-icon-container", { selected: checked })}
      >
        <HeartSvg />
      </div>
    </div>
  );
};

export default Heart;
