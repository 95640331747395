import { ApolloClient, InMemoryCache } from "@apollo/client";
import { Storage } from "aws-amplify";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { setupCache } from "axios-cache-adapter";
import {
  camelCase,
  isArray,
  isBoolean,
  isEmpty,
  isObject,
  isString,
  pickBy,
  startCase,
  toLower,
} from "lodash";
import { Social } from "../components/SocialButton/socialButton";
import { PrivateBucket, PublicBucket } from "../config/s3.constants";
import { countriesKeys } from "../helpers/countries";
import {
  ISearchQuery,
  IServiceHeaders,
  OpTypes,
  RelationToFollowingTypes,
} from "./service.interface";
import LoggerService from "./v2/logger/logger.service";
import { INewsFeedTalent } from "./v2/newsFeed/newsFeed.interfaces";
import { ISocialMedia } from "./v2/talents/talents.interface";

const cache = setupCache({
  maxAge: 25 * 60 * 1000,
});
export default abstract class Service {
  rest: AxiosInstance;
  graphql: any;

  constructor(
    protected url: string,
    protected errorHandler: (title: string, msg: string, error: any) => void,
    protected loggerService: LoggerService
  ) {
    this.graphql = new ApolloClient({
      uri: url,
      cache: new InMemoryCache(),
    });
    this.rest = axios.create({
      baseURL: url,
      timeout: 12000,
      // adapter: cache.adapter,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public setToken(token: string) {
    this.rest = axios.create({
      baseURL: this.url,
      timeout: 12000,
      adapter: cache.adapter,
      headers: {
        "Content-Type": "application/json",
        //FIX THIS
        "x-access-token": token,
        Authorization: token,
      },
    });
    localStorage.setItem("token", token);
  }

  public getToken() {
    return localStorage.getItem("token");
  }

  private generateHeaders(options: AxiosRequestConfig["headers"] | {}) {
    return {
      ...(options || {}),
      ...{
        baseURL: this.url,
        timeout: 12000,
        headers: {
          "Content-Type": "application/json",
          platform: "WebApp",
          "x-access-token": this.getToken() ? `${this.getToken()}` : "",
          Authorization: this.getToken() ? `${this.getToken()}` : "",
          ...((options as any)?.headers || ({} as {})),
        },
      },
    };
  }
  protected attachSocialMedia(talent: INewsFeedTalent | any): ISocialMedia[] {
    return [
      {
        platform: Social.facebook,
        url: talent?.facebook || "",
        followers: talent?.facebookFollowers,
      },
      {
        platform: Social.instagram,
        url: talent?.instagram || "",

        followers: talent?.instagramFollowers,
      },
      {
        platform: Social.tiktok,
        url: talent?.tiktok || "",

        followers: talent?.tiktokFollowers,
      },
      {
        platform: Social.twitter,
        url: talent?.twitter || "",

        followers: talent?.twitterFollowers,
      },
      {
        platform: Social.youtube,
        url: talent?.youtube || "",
        followers: talent?.youtubeFollowers,
      },
    ].filter((item) => item.url);
  }

  private handleResponse<T>(
    path: string,
    request: object,
    response: AxiosResponse<T>,
    withoutRequest: boolean
  ) {
    const isError =
      response.status !== 200 &&
      response.status !== 201 &&
      response.status !== 404;
    const isWarn = response.status === 404;
    this.loggerService.logger(isError ? "error" : isWarn ? "warn" : "info", {
      path,
      method: response.config.method,
      request: withoutRequest ? "" : request,
      statusCode: response.status,
      statusText: response.statusText,
      response: isError || isWarn ? response.data : undefined,
    });

    return { ...response, ok: true };
  }

  private handleErrorResponse<T>(
    path: string,
    request: object,
    response: AxiosError,
    headers: object,
    defaultValue: any,
    withoutRequest: boolean
  ) {
    if (!axios.isCancel(response)) {
      const level = response.response ? "error" : "warn";
      this.loggerService.logger(level, {
        path,
        method: response?.config?.method,
        request: withoutRequest ? "" : request,
        headers: headers,
        statusText: response.response?.statusText,
        statusCode: response.response?.status,
        response: response.response?.data
          ? isString(response.response?.data) &&
            response.response?.data.length > 1000
            ? response.response?.data.slice(0, 1000)
            : response.response?.data
          : response.response?.data,
      });
    }
    return {
      ...response,
      data: defaultValue,
      ok: false,
    } as unknown as AxiosResponse<T> & { ok: boolean };
  }

  protected async query<T>(query: any, variables?: any) {
    return this.graphql.query({
      query,
      variables,
    }) as T;
  }

  protected async get<T>(
    resource: string,
    options?: AxiosRequestConfig,
    defaultValue?: any,
    withoutRequest: boolean = false
  ) {
    return this.rest
      .get<T>(resource, this.generateHeaders(options || {}))
      .then((res) =>
        this.handleResponse<T>(this.url + resource, {}, res, withoutRequest)
      )
      .catch((res) =>
        this.handleErrorResponse<T>(
          this.url + resource,
          {},
          res,
          options?.headers as object,
          defaultValue,
          withoutRequest
        )
      );
  }

  protected async patch<T>(
    resource: string,
    data: object,
    options?: AxiosRequestConfig,
    defaultValue?: any,
    withoutRequest: boolean = false
  ) {
    return this.rest
      .patch<T>(resource, data, this.generateHeaders(options))
      .then((res) =>
        this.handleResponse<T>(this.url + resource, data, res, withoutRequest)
      )
      .catch((res) =>
        this.handleErrorResponse<T>(
          this.url + resource,
          data,
          res,
          options?.headers as object,
          defaultValue,
          withoutRequest
        )
      );
  }

  protected async put<T>(
    resource: string,
    data: object,
    options?: AxiosRequestConfig,
    defaultValue?: any,
    withoutRequest: boolean = false
  ) {
    return this.rest
      .put<T>(resource, data, this.generateHeaders(options))
      .then((res) =>
        this.handleResponse<T>(this.url + resource, data, res, withoutRequest)
      )
      .catch((res) =>
        this.handleErrorResponse<T>(
          this.url + resource,
          data,
          res,
          options?.headers as object,
          defaultValue,
          withoutRequest
        )
      );
  }

  protected async delete<T>(
    resource: string,
    options?: AxiosRequestConfig,
    defaultValue?: any,
    withoutRequest: boolean = false
  ) {
    return axios
      .delete<T>(resource, this.generateHeaders(options))
      .then((res) =>
        this.handleResponse<T>(this.url + resource, {}, res, withoutRequest)
      )
      .catch((res) =>
        this.handleErrorResponse<T>(
          this.url + resource,
          {},
          res,
          options?.headers as object,
          defaultValue,
          withoutRequest
        )
      );
  }

  protected async post<T>(
    resource: string,
    data: object,
    options?: AxiosRequestConfig,
    defaultValue?: any,
    withoutRequest: boolean = false
  ) {
    return this.rest
      .post<T>(resource, data, this.generateHeaders(options))
      .then((res) =>
        this.handleResponse<T>(this.url + resource, data, res, withoutRequest)
      )
      .catch((res) =>
        this.handleErrorResponse<T>(
          this.url + resource,
          data,
          res,
          options?.headers as object,
          defaultValue,
          withoutRequest
        )
      );
  }

  protected async fileUpload(
    path: string,
    file: File,
    privateBucket: boolean,
    progressCallback: (evt: {
      total: number;
      loaded: number;
    }) => void = () => {}
  ): Promise<boolean> {
    const response = await Storage.put(path, file, {
      bucket: privateBucket ? PrivateBucket : PublicBucket,
      customPrefix: {
        public: "",
      },
      progressCallback(progress: any) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
    });
    if (response && (response as any).key) {
      return true;
    } else {
      return false;
    }
  }

  async getS3Url(path: string): Promise<any> {
    return `https://ik.imagekit.io/raqwvm0o0/` + path;
    // const bucket = new AWS.S3({
    //   params: { Bucket: "letstok-social-media-dev" },
    //   region: "eu-west-1",
    // });
    // return bucket.getSignedUrlPromise("getObject", {
    //   Bucket: "letstok-social-media-dev",
    //   Key: path,
    // });
    // let pathKey = path.replaceAll("public/", "");
    // const params = [
    //   {
    //     Bucket: "letstok-social-media-dev",
    //     Key: pathKey,
    //   },
    //   {
    //     Bucket: "letstok-social-media-dev",
    //     Key: "public/" + pathKey,
    //   },
    // ];
    // try {
    //   const response = await Promise.any(
    //     params.map((param) =>
    //       bucket
    //         .headObject(param)
    //         .promise()
    //         .then((res) => ({ ...res, params: param }))
    //     )
    //   );
    //   return bucket.getSignedUrlPromise("getObject", response.params);
    // } catch (e) {
    //   return "";
    // }
  }

  protected buildQuery<T>(
    queryFilter: { [key: string]: any },
    relationToFollowing: RelationToFollowingTypes,
    resource: T
  ) {
    return Object.keys(queryFilter).reduce((query, key) => {
      if (this.checkForRangeOp(queryFilter[key])) {
        query = [
          ...query,
          ...this.addRangeQuery(
            queryFilter[key],
            this.fieldMapping<T>(key, resource)
          ),
        ];
      } else if (this.checkForArrayOp(queryFilter[key])) {
        query = [
          ...query,
          this.addSomeInArrayQuery(
            queryFilter[key],
            this.fieldMapping<T>(key, resource),
            this.getOpByType<T>(queryFilter[key], key, resource)
          ) as any,
        ];
      } else if (["or", "and"].includes(key)) {
        query = [
          ...query,
          ...this.buildQuery(
            queryFilter[key],
            key as RelationToFollowingTypes,
            resource
          ),
        ];
      } else {
        query = [
          ...query,
          {
            relationToFollowing: relationToFollowing,
            query: {
              field: this.fieldMapping<T>(key, resource),
              op: this.getOpByType<T>(queryFilter[key], key, resource),
              value: queryFilter[key],
            },
          },
        ];
      }
      return query;
    }, [] as ISearchQuery[]);
  }

  protected attachHeaders(headers: IServiceHeaders) {
    let currency = headers.currency;
    let country = headers.country;
    let language = toLower(headers.language);
    const currencies = ["USD", "ILS", "HKD"];

    const languages = ["en", "he", "ch"];
    if (!currencies.includes(currency) || isEmpty(currency)) {
      currency = "USD";
    }
    if (!countriesKeys.includes(country) || isEmpty(country)) {
      country = "US";
    }
    if (isEmpty(language)) {
      language = "en";
    }
    if (language === "zh") {
      language = "ch";
    }
    if (!languages.includes(language)) {
      language = "en";
    }
    return {
      language: startCase(camelCase(language)),
      country: country,
      currency: currency,
    };
  }

  fieldMapping<T>(key: string, resource: T): string {
    return "";
  }

  opMapping<T>(key: string, resource: T): OpTypes | null {
    return null;
  }

  private checkForRangeOp(value: any) {
    return (
      value && isObject(value) && (value as any).start && (value as any).end
    );
  }

  private checkForArrayOp(value: any) {
    return value && isArray(value);
  }

  private getOpByType<T>(value: unknown, key: string, resource: T): OpTypes {
    if (this.opMapping(key, resource)) {
      return this.opMapping(key, resource) as OpTypes;
    }
    if (isArray(value)) {
      return "eq";
    }
    if (isString(value)) {
      return "ilike";
    }
    if (value && (value as any)?.start) {
      return "ilike";
    }
    return "eq";
  }

  private addRangeQuery(
    range: { start: number; end: number },
    fieldKey: string
  ): ISearchQuery[] {
    return [
      {
        relationToFollowing: "and",
        query: {
          field: fieldKey,
          op: "gte",
          value: range.start,
        },
        and: {
          relationToFollowing: "and",
          query: {
            field: fieldKey,
            op: "lte",
            value: range.end,
          },
        },
      },
    ];
  }

  removeEmptyFilters(filters: object) {
    return pickBy(
      filters,
      (item) => isBoolean(item) || (!isEmpty(item) && item)
    );
  }

  private addSomeInArrayQuery(
    array: string[],
    fieldKey: string,
    op: OpTypes = "eq"
  ): ISearchQuery | null {
    if (isEmpty(array)) {
      return null;
    }
    return pickBy({
      query: {
        field: fieldKey,
        op: op,
        value: op === "arrIncludes" ? array : array[0],
      },
      or: this.addSomeInArrayQuery(array.slice(1), fieldKey, op),
      relationToFollowing: "and",
    }) as any;
  }
}
