import { Modal } from "rsuite";
import "./talentPreview.scss";
import CloseButton from "../CloseButton/closeButton";
import classNames from "classnames";
import { useMediaQuery } from "@react-hook/media-query";
import { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../context/localization";
import Avatar from "../BusinessHeader/Avatar/avatar";
import SocialTag from "../SocialTag/socialTag";
import { Social } from "../SocialButton/socialButton";
import { isEmpty, keyBy } from "lodash";
import { ITalentsSearch } from "../../services/v2/search/search.interfaces";
import Button from "../Button/button";
import Input from "../Input/input";
import { attachS3Url } from "../../helpers/s3.utils";
import { ApplicationContext } from "../../context/application";

export interface ITalentPreviewModalProps {
  dismiss: () => void;
  show: boolean;
  talent: ITalentsSearch;
}

const TalentPreviewModal = ({
  dismiss,
  show,
  talent,
}: ITalentPreviewModalProps) => {
  const mobileView = useMediaQuery("(max-width: 600px)") as boolean;
  const { categoriesAll } = useContext(ApplicationContext)
  const [categoriesName, setCategoriesName] = useState<string[]>([]);
  const [message, setMessage] = useState("");
  const { isRTL } = useContext(LocalizationContext);

  useEffect(() => {
    setMessage("");
  }, [show]);

  useEffect(() => {
    const categoriesById = keyBy(categoriesAll, "id");
    if (!isEmpty(talent.talentCategories)) {
      setCategoriesName(
        talent.talentCategories.map((item) => {
          if (categoriesById[item.categoriesId]) {
            return categoriesById[item.categoriesId].categoryName;
          }
          return "";
        })
      );
    }

  }, [categoriesAll, talent.talentCategories]);

  return (
    <Modal
      size="xs"
      style={{
        margin: mobileView ? 0 : "70px auto 0 auto",
        maxWidth: mobileView ? "100vw" : "600px",
        minWidth: mobileView ? "100vw" : "600px",
      }}
      dialogClassName={classNames("talent-preview-popup-modal", {
        ltr: !isRTL,
      })}
      backdropClassName={"campaign-popup-backdrop"}
      backdrop={true}
      show={show}
      onHide={() => { }}
    >
      <CloseButton
        onClick={() => {
          dismiss();
        }}
      />
      <div className="body">
        <Avatar url={attachS3Url(talent.avatar)} />
        <div className="title">{talent.fullName}</div>
        <div className="tags-continer">
          {categoriesName.map((item) => {
            return <div className="tag">{item}</div>;
          })}
        </div>
        <div className="description">{talent.shortBio} </div>
        <div className="social-tags">
          {[
            {
              platform: Social.twitter,
              followers: talent.twitterFollowers,
              url: talent.twitter,
            },
            {
              platform: Social.tiktok,
              followers: talent.tiktokFollowers,
              url: talent.tiktok,
            },
            {
              platform: Social.facebook,
              followers: talent.facebookFollowers,
              url: talent.facebook,
            },
            {
              platform: Social.youtube,
              followers: talent.youtubeFollowers,
              url: talent.youtube,
            },
            {
              platform: Social.instagram,
              followers: talent.instagramFollowers,
              url: talent.instagram,
            },
          ]
            .filter((item) => !isEmpty(item.url))
            .map((item) => {
              return (
                <SocialTag
                  url={item.url}
                  platform={item.platform}
                  followers={item.followers}
                />
              );
            })}
        </div>
        <Input
          type={"text"}
          multiline={true}
          value={message}
          onChange={(val, valid) => {
            setMessage(val);
          }}
          validCheck={false}
          placeholder=""
          customClass={"product-input multiline"}
          label={`Message ${talent.fullName}`}
        />
        <Button onClick={() => { }}>Send message</Button>
      </div>
    </Modal>
  );
};

export default TalentPreviewModal;
