import Input from "../../../../../components/Input/input";
import InputWithSubmit from "../../../../../components/InputWithSubmit/inputWithSubmit";
import { strings } from "../../../../../localizedStrings";
import "./accountInput.scss";

export const AccountInputs = () => {
  return (
    <div className="account-input">
      <Input
        customClass={"profile-input"}
        value={""}
        onChange={
          () => {} // setForm({ ...form, name: { value, valid } })
        }
        type={"text"}
        validCheck={false}
        label={strings.owner}
      />
      <Input
        customClass={"profile-input"}
        value={""}
        onChange={
          () => {} // setForm({ ...form, name: { value, valid } })
        }
        type={"text"}
        validCheck={false}
        label={strings.manager}
      />
      <div className="input-continer">
        <div className="label-container">Invite users to this account</div>
        <InputWithSubmit
          initValue={""}
          placeholder={"Type email address"}
          submitText={strings.invite}
          error={""}
          onSubmit={async () => {}}
          onChange={async () => {}}
        />
      </div>
    </div>
  );
};
