import Service from "../service";
import firebase from "firebase/app";
import "firebase/auth";
import { CognitoHostedProvider } from "../../pages/Authentication/authentication.const";
import LoggerService from "../v2/logger/logger.service";
import TagManager from "react-gtm-module";

export default class AuthenticationFirebaseService extends Service {
  onAuthChanged: (payload: firebase.User | null) => Promise<void> =
    async () => {};
  googleAuthProvider?: firebase.auth.GoogleAuthProvider;
  facebookAuthProvider?: firebase.auth.FacebookAuthProvider;

  constructor(
    url: string,
    protected firebaseConfig: any,
    protected errorHandler: (title: string, msg: string, error: any) => void,
    LoggerService: LoggerService
  ) {
    super(url, errorHandler, LoggerService);
  }

  async init() {
    this.googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    this.facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().onIdTokenChanged(async (data) => {
      if (data) {
        await this.onAuthChanged(data);
      }
    });
    firebase.auth().onAuthStateChanged(async (data: firebase.User | null) => {
      if (data) {
        await this.onAuthChanged(data);
      }
    });
  }

  async socialSignUp(provider: any) {
    if (CognitoHostedProvider.Google === provider && this.googleAuthProvider) {
      return firebase
        .auth()
        .signInWithPopup(this.googleAuthProvider)
        .then((res) => {
          TagManager.dataLayer({
            dataLayer: {
              event: "registration_complete",
            },
          });
          return res;
        })
        .catch((err: any) => {
          return err;
        });
    } else if (
      CognitoHostedProvider.Facebook === provider &&
      this.facebookAuthProvider
    ) {
      return firebase
        .auth()
        .signInWithPopup(this.facebookAuthProvider)
        .then((res) => {
          TagManager.dataLayer({
            dataLayer: {
              event: "registration_complete",
            },
          });
          return res;
        })
        .catch((err: any) => {
          return err;
        });
    }
  }

  async changePassword({
    currentPassword,
    newPassword,
  }: {
    newPassword: string;
    currentPassword: string;
  }) {
    try {
      if (firebase.auth() && firebase.auth().currentUser) {
        const emailCred = firebase.auth.EmailAuthProvider.credential(
          (firebase.auth().currentUser as firebase.User).email || "",
          currentPassword
        );
        const user = await (
          firebase.auth().currentUser as firebase.User
        ).reauthenticateWithCredential(emailCred);
        if (user) {
          await (firebase.auth().currentUser as firebase.User).updatePassword(
            newPassword
          );
          return { error: false };
        } else {
          return { error: true };
        }
      }
      return { error: true };
    } catch (error) {
      return { error: true };
    }
  }

  async setAuthChanged(
    onAuthChanged: (payload: firebase.User | null) => Promise<void>
  ) {
    this.onAuthChanged = onAuthChanged;
  }

  auth() {
    return firebase.auth();
  }

  async sendRecoveryCode(email: string) {
    return firebase
      .auth()
      .sendPasswordResetEmail(email.toLowerCase())
      .catch((err: any) => {
        return err;
      });
  }

  async signUp({
    email,
    password,
    name,
  }: {
    email: string;
    password: string;
    name?: string;
  }) {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email.toLowerCase(), password)
      .then(async (user: firebase.auth.UserCredential) => {
        TagManager.dataLayer({
          dataLayer: {
            event: "registration_complete",
          },
        });
        await user!.user!.updateProfile({
          displayName: name || email,
        });
        return user;
      })
      .catch((err: any) => {
        return err;
      });
  }

  async signIn({ email, password }: { email: string; password: string }) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email.trim(), password)
      .catch((err: any) => {
        return err;
      });
  }
}
