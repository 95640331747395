import { ReactComponent as FacebookSvg } from "../../assets/IconFacebookFill.svg";
// import { ReactComponent as AppleSvg } from "../../assets/IconAppleFill.svg";
import { ReactComponent as GoogleSvg } from "../../assets/IconGoogle.svg";
import { ReactComponent as MailSvg } from "../../assets/IconMail.svg";

import Input from "../../components/Input/input";
import InputPhone from "../../components/PhoneInput/phoneInput";
import { strings } from "../../localizedStrings";
import {
  validBusinessEmail,
  validEmail,
  validName,
  validPassword,
} from "./authentication.helpers";
import { FormType, ModeType } from "./authentication.interface";

export const CognitoHostedProvider = {
  Cognito: "COGNITO",
  Google: "Google",
  Facebook: "Facebook",
  Amazon: "LoginWithAmazon",
  Apple: "SignInWithApple",
};

export const providers = [
  // {
  //   icon: <AppleSvg />,
  //   providerName: CognitoHostedProvider.Apple,
  // },
  {
    icon: <FacebookSvg />,
    providerName: CognitoHostedProvider.Facebook,
  },
  {
    icon: <GoogleSvg />,
    providerName: CognitoHostedProvider.Google,
  },
];

export const providersBusiness = [
  // {
  //   icon: <GoogleSvg />,
  //   providerName: CognitoHostedProvider.Google,
  // },
  // {
  //   icon: <FacebookSvg />,
  //   providerName: CognitoHostedProvider.Facebook,
  // },
  {
    icon: <MailSvg />,
    providerName: "email",
  },
];

export const initForm = {
  email: { value: "", valid: { valid: true, msg: "" } },
  password: { value: "", valid: { valid: true, msg: "" } },
  name: { value: "", valid: { valid: true, msg: "" } },
 
};

export const initBusinessForm = {
  email: { value: "", valid: { valid: true, msg: "" } },
  password: { value: "", valid: { valid: true, msg: "" } },
  businessName: { value: "", valid: { valid: true, msg: "" } },
  name: { value: "", valid: { valid: true, msg: "" } },
  phone: {
    value: '',
    country: 'US',
    valid:  { valid: true, msg: "" } 
  }
};


export const getPreInputs = (
  mode: ModeType,
  form: FormType,
  setForm: (from: FormType) => void
) => {
  if (mode === "signIn" || mode === "signUpTalent") {
    return [];
  } else {
    return [
      // {
      //   input: (
      //     <Input
      //       value={form.businessName.value}
      //       validate={validName}
      //       autoFocus
      //       valid={form.businessName.valid}
      //       errorBorder
      //       errorType="message"
      //       onChange={(value, valid) =>
      //         setForm({ ...form, businessName: { value, valid } })
      //       }
      //       type={"text"}
      //       label={"Business name"}
      //     />
      //   ),
      // },
    ];
  }
};

export const getInputs = (
  mode: ModeType,
  form: FormType,
  setForm: (from: FormType) => void
) => {
  if (mode === "signUpBusiness") {
    return [
      {
        input: (
          <Input
            value={form.businessName.value}
            validate={validName}
            autoFocus
            required
            valid={form.businessName.valid}
            errorBorder
            errorType="message"
            onChange={(value, valid) =>
              setForm({ ...form, businessName: { value, valid } })
            }
            type={"text"}
            label={"Business name"}
          />
        ),
      },
      {
        input: (
          <Input
            value={form.email.value}
            valid={form.email.valid}
            validate={
              mode === "signUpBusiness" ? validBusinessEmail : validEmail
            }
            required
            autoFocus
            onChange={(value, valid) =>
              setForm({ ...form, email: { value, valid } })
            }
            type={"email"}
            errorType="message"
            errorBorder
            autoComplete="username"
            label={strings.email}
            clean
          />
        ),
      },
      {
        input: (
          <InputPhone
            value={form.phone.value}

            onChange={(val, countryCode, valid) => {
              setForm({ ...form, phone: { value: val, country: countryCode, valid: valid as any } });
            }}
            customClass={""}
            country={form.phone.country || 'US'}
            label={strings.phone}
          />
        ),
      },

      {
        input: (
          <Input
            value={form.password.value}
            validate={validPassword}
            required
            valid={form.password.valid}
            onChange={(value, valid) =>
              setForm({ ...form, password: { value, valid } })
            }
            type={"password"}
            errorType="message"
            errorBorder
            autoComplete="current-password"
            label={strings.password}
          />
        ),
      },
    ];
  }
  if (
    mode === "signIn" ||
    mode === "signUpTalent"
  ) {
    return [

      {
        input: (
          <Input
            value={form.email.value}
            valid={form.email.valid}
            validate={
              validEmail
            }
            autoFocus
            onChange={(value, valid) =>
              setForm({ ...form, email: { value, valid } })
            }
            type={"email"}
            errorType="message"
            errorBorder
            autoComplete="username"
            label={strings.email}
            clean
          />
        ),
      },
      {
        input: (
          <Input
            value={form.password.value}
            validate={validPassword}
            valid={form.password.valid}
            onChange={(value, valid) =>
              setForm({ ...form, password: { value, valid } })
            }
            type={"password"}
            errorType="message"
            errorBorder
            autoComplete="current-password"
            label={strings.password}
          />
        ),
      },
    ];
  } else {
    return [
      {
        input: (
          <Input
            value={form.name.value}
            validate={validName}
            autoFocus
            valid={form.name.valid}
            errorBorder
            errorType="message"
            onChange={(value, valid) =>
              setForm({ ...form, name: { value, valid } })
            }
            type={"text"}
            label={strings.fullName}
          />
        ),
      },
      {
        input: (
          <Input
            value={form.email.value}
            validate={validEmail}
            valid={form.email.valid}
            errorBorder
            errorType="message"
            onChange={(value, valid) =>
              setForm({ ...form, email: { value, valid } })
            }
            type={"email"}
            autoComplete="username"
            label={strings.email}
            clean
          />
        ),
      },
      {
        input: (
          <Input
            value={form.password.value}
            validate={validPassword}
            valid={form.password.valid}
            errorBorder
            errorType="message"
            onChange={(value, valid) =>
              setForm({ ...form, password: { value, valid } })
            }
            type={"password"}
            autoComplete="new-password"
            label={strings.password}
          />
        ),
      },
    ];
  }
};
