import "./order.scss";
import classnames from "classnames";
import { ReactComponent as SvgFacebook } from "../../assets/IconFacebook.svg";
import { ReactComponent as SvgTwitter } from "../../assets/IconTwitter.svg";
import { ReactComponent as SvgMail } from "../../assets/IconMail.svg";
import { ReactComponent as SvgWhatsapp } from "../../assets/IconWhatsapp.svg";
import ContentLoader from "react-content-loader";

import { isEmpty } from "lodash";
import OrderContent from "./Content/orderContent";
import { useMediaQuery } from "@react-hook/media-query";
import Stars from "../TalentPage/Stars/stars";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share";
import { strings } from "../../localizedStrings";
import { LocalizationContext } from "../../context/localization";
import { useContext } from "react";
import {
  IOrderStatus,
  IServiceTypes,
} from "../../services/v2/orders/orders.interface";
import { getUrls, ServicesContext } from "../../context/services";
import { DateTime } from "luxon";
export interface IOrderProps {
  type: IOrderStatus | "loading";
  talentName: string;
  productName: string;
  talentImage: string;
  approveMsg?: string;
  rejectMsg?: string;
  onReview?: () => void;
  onMeeting?: () => void;
  orderDate?: DateTime;
  receivedDate?: DateTime;
  meetingDate?: DateTime;
  content?: { type: IServiceTypes; content: any; link?: string };
  social?: {
    facebook: string;
    instagram: string;
    twitter: string;
    mail: string;
    whatsapp: string;
  };
  review?: { msg: string; rate: number };
}

export const getStatusByType = (
  type: IOrderStatus | "loading",
  serviceType: IServiceTypes = "video"
) => {
  if (type === "offered") {
    return strings.orderPadding;
  }
  if (serviceType === "video" && type === "accepted") {
    return strings.orderApproved;
  }
  if (serviceType === "meeting" && type === "accepted") {
    return strings.orderPlaced;
  }
  if (type === "rejected") {
    return strings.orderReject;
  }
  if (type === "submitted") {
    return strings.orderEnd;
  }
};

export const OrderLoading = () => {
  const mobileView = useMediaQuery("(max-width: 570px)") as boolean;

  return mobileView ? (
    <div className={"order-container"}>
      <ContentLoader
        speed={1}
        width={"100%"}
        height={300}
        viewBox={`0 0 100% 300`}
        backgroundColor="#222222"
        foregroundColor="#1B1B1B"
      >
        <rect x="220" y="15" rx="2" ry="2" width="120" height="30" />
        <rect x="190" y="60" rx="2" ry="2" width="150" height="30" />
        <rect x="260" y="110" rx="2" ry="2" width="80" height="20" />
        <rect x="220" y="140" rx="2" ry="2" width="120" height="20" />
        <rect x="260" y="175" rx="2" ry="2" width="80" height="20" />
        <rect x="220" y="205" rx="2" ry="2" width="120" height="20" />
        <rect x="15" y="5" rx="20" ry="20" width="140" height="230" />
        <rect x="30" y="260" rx="6" ry="6" width="300" height="35" />
      </ContentLoader>
    </div>
  ) : (
    <div className={"order-container"}>
      <ContentLoader
        speed={1}
        width={552}
        height={448}
        viewBox={`0 0 552 448`}
        backgroundColor="#222222"
        foregroundColor="#1B1B1B"
      >
        <rect x="380" y="35" rx="2" ry="2" width="150" height="30" />
        <rect x="330" y="80" rx="2" ry="2" width="200" height="30" />
        <rect x="430" y="135" rx="2" ry="2" width="100" height="20" />
        <rect x="380" y="165" rx="2" ry="2" width="150" height="20" />
        <rect x="430" y="200" rx="2" ry="2" width="100" height="20" />
        <rect x="380" y="230" rx="2" ry="2" width="150" height="20" />
        <rect x="15" y="25" rx="20" ry="20" width="250" height="400" />
        <rect x="290" y="385" rx="6" ry="6" width="235" height="40" />
      </ContentLoader>
    </div>
  );
};

const Order = ({
  type,
  talentName,
  productName,
  talentImage,
  orderDate,
  approveMsg,
  rejectMsg,
  receivedDate,
  review,
  onMeeting,
  meetingDate,
  onReview,
  content,
  social,
}: IOrderProps) => {
  const mobileView = useMediaQuery("(max-width: 570px)") as boolean;
  const { isRTL } = useContext(LocalizationContext);
  const { configService } = useContext(ServicesContext);
  const videoUrl =
    content?.type === "video"
      ? getUrls().ui + `/player?path=${content.content}`
      : "";
  if (type === "loading") {
    return <OrderLoading />;
  }

  return (
    <div className={classnames("order-container", { ltr: !isRTL })}>
      <div
        className={classnames("order-status", {
          reject: type === "rejected",
          placed: content?.type === "meeting" && type === "accepted",
        })}
      >
        {getStatusByType(
          type,
          content?.type === "meeting" ? "meeting" : "video"
        )}
      </div>
      <div className={"order-image-details"}>
        <OrderContent
          type={type}
          talentName={talentName}
          meetingDate={meetingDate}
          content={content}
          talentImage={talentImage}
        />
        <div className={"order-details"}>
          <div className={"order-details-container"}>
            <div className={"order-talent-name"}>{talentName}</div>
            <div className={"order-service-name"}>{productName}</div>
            <div className={"detail-container"}>
              <div className={"label"}>{strings.orderRequestDate}</div>
              {orderDate && (
                <div className={"value"}>
                  {configService.formatTime(orderDate, true)}
                </div>
              )}
            </div>
            {receivedDate && type === "delivered" ? (
              <div className={"detail-container"}>
                <div className={"label"}>{strings.accepted}</div>
                <div className={"value"}>
                  {configService.formatTime(receivedDate, true)}
                </div>
              </div>
            ) : (
              <></>
            )}
            {content?.type === "video" &&
            type === "delivered" &&
            !isEmpty(content.link) ? (
              <div className={"detail-container"}>
                <div className={"label"}>{strings.orderLink}</div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"value"}
                  href={content.link}
                >
                  {content.link}
                </a>
              </div>
            ) : (
              <></>
            )}
            {meetingDate &&
            content?.type === "meeting" &&
            (type === "rejected" || type === "pending") ? (
              <div className={"detail-container"}>
                <div className={"label"}>{strings.setDate}</div>
                <div className={"value"}>
                  {configService.formatTime(
                    meetingDate,
                    true,
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {meetingDate &&
            content?.type === "meeting" &&
            (type === "accepted" || type === "delivered") ? (
              <div className={"detail-container"}>
                <div className={"label"}>{strings.setDate}</div>
                <div className={"value"}>
                  {configService.formatTime(meetingDate, true)}
                </div>
              </div>
            ) : (
              <></>
            )}
            {approveMsg ? (
              <div className={"label approve"}>{approveMsg}</div>
            ) : (
              <></>
            )}

            {rejectMsg ? (
              <div className={"detail-container"}>
                <div className={"label"}>{strings.rejectedReason}</div>
                <div className={"value"}>{rejectMsg}</div>
              </div>
            ) : (
              <></>
            )}

            {content?.type === "video" && !isEmpty(social) ? (
              <div className={"detail-container"}>
                <div className={"label"}>{strings.share}</div>
                <div className={"social-container"}>
                  <FacebookShareButton url={videoUrl} quote={talentName}>
                    <SvgFacebook />
                  </FacebookShareButton>
                  <TwitterShareButton url={videoUrl} via={talentName}>
                    <SvgTwitter />
                  </TwitterShareButton>
                  <EmailShareButton url={videoUrl} subject={talentName}>
                    <SvgMail />
                  </EmailShareButton>

                  <WhatsappShareButton title={talentName} url={videoUrl}>
                    <SvgWhatsapp />
                  </WhatsappShareButton>
                </div>
              </div>
            ) : (
              <></>
            )}
            {review ? (
              <div className={"detail-container"}>
                <div className={"label"}>{strings.review}</div>
                <Stars starts={review.rate} />
                <div className={"value"}>{review.msg}</div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {mobileView ? (
            <></>
          ) : (
            <div className={"order-bottom-container"}>
              <>
                {type === "delivered" && !review && (
                  <div
                    onClick={() => {
                      if (onReview) {
                        onReview();
                      }
                    }}
                    className={"submit-button"}
                  >
                    <div>{strings.review}</div>
                  </div>
                )}
                {type !== "pending" && content?.type === "meeting" && (
                  <div
                    onClick={() => {
                      if (onMeeting) {
                        onMeeting();
                      }
                    }}
                    className={"button-small"}
                  >
                    {strings.enterMeeting}
                  </div>
                )}
              </>
            </div>
          )}
        </div>
      </div>
      {mobileView ? (
        <div className={"order-bottom-container"}>
          <>
            {type === "delivered" && !review && (
              <div
                onClick={() => {
                  if (onReview) {
                    onReview();
                  }
                }}
                className={"submit-button"}
              >
                <div>{strings.review}</div>
              </div>
            )}
            {type !== "pending" && content?.type === "meeting" && (
              <div
                onClick={() => {
                  if (onMeeting) {
                    onMeeting();
                  }
                }}
                className={"button-small"}
              >
                {strings.enterMeeting}
              </div>
            )}
          </>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Order;
