import Service from "../../service";
import {
  IOrder,
  IOrderResponse,
  IOrderStatus,
  IServiceTypes,
  IStripePaymentIntent,
} from "./orders.interface";
import { strings } from "../../../localizedStrings";
import { IServiceHeaders } from "../../service.interface";
import AnalyticsServices from "../analytics/analytics.service";
import LoggerService from "../logger/logger.service";
import moment from "moment";
import { v4 } from "uuid";
import { ICampaignObject } from "../../../pages/Business/CampaignGenerator/CampaignForm/campaignForm";

export default class OrdersServiceV2 extends Service {
  constructor(
    url: string,
    protected errorHandler: (title: string, msg: string, error: string) => void,
    protected LoggerService: LoggerService,
    protected analyticsServices: AnalyticsServices
  ) {
    super(url, errorHandler, LoggerService);
  }

  async createPaymentIntent(headers: IServiceHeaders, orderId: string) {
    const paymentIntent = await this.get<IStripePaymentIntent>(
      `orders/payment/order/create/${orderId}/stripe`,
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
      }
    );
    if (!paymentIntent.ok) {
      this.errorHandler(
        strings.errorTitle,
        strings.orderErrorMsg,
        (paymentIntent as any).response && (paymentIntent as any).response.data
          ? JSON.stringify((paymentIntent as any).response.data)
          : ""
      );
      return false;
    }

    return paymentIntent.data;
  }

  async confirmPaymentIntent(headers: IServiceHeaders, orderId: string) {
    const paymentConfirm = await this.get<IStripePaymentIntent>(
      `orders/payment/order/confirm/${orderId}/stripe`,
      {
        headers: this.attachHeaders(headers),
      }
    );
    if (!paymentConfirm.ok) {
      this.errorHandler(
        strings.errorTitle,
        strings.orderErrorMsg,
        (paymentConfirm as any).response &&
          (paymentConfirm as any).response.data
          ? JSON.stringify((paymentConfirm as any).response.data)
          : ""
      );
      return false;
    }
    return true;
  }

  async deleteOrder(headers: IServiceHeaders, orderId: string) {
    const order = await this.delete<IOrderResponse>(
      `orders/delete/${orderId}`,
      {
        headers: this.attachHeaders(headers),
      }
    );

    return order.ok;
  }

  async saveOrder(
    headers: IServiceHeaders,
    request: {
      serviceId: string;
      serviceType: IServiceTypes;
      payload: IOrder;
    }
  ) {
    const order = await this.post<IOrderResponse>(
      `orders/create/transactional/${request.serviceId}`,
      {
        order: this.buildOrderRequest(request.payload),
        coupon: request.payload.coupon,
      } as IOrder,
      {
        headers: this.attachHeaders(headers),
      }
    );

    if (!order.ok) {
      this.errorHandler(
        strings.errorTitle,
        strings.orderErrorMsg,
        (order as any).response && (order as any).response.data
          ? JSON.stringify((order as any).response.data)
          : ""
      );
      return false;
    }

    return order.data;
  }

  async meetingToken(headers: IServiceHeaders, orderId: number) {
    const { data } = await this.get<any>(`/orders/meeting/token/${orderId}/`, {
      headers: this.attachHeaders(headers),
      cache: {
        maxAge: 0,
      },
    }).catch((err) => {
      return { data: { error: err.response.data } };
    });
    return data;
  }

  async orderValidation(
    headers: IServiceHeaders,
    request: {
      serviceId: string;
      serviceType: IServiceTypes;
      payload: IOrder;
    }
  ) {
    const order = await this.post(
      `orders/validate/${request.serviceId}`,
      this.buildOrderRequest(request.payload),
      {
        headers: this.attachHeaders(headers),
      },
      false,
      true
    );
    if (!order.ok) {
      this.errorHandler(
        strings.errorTitle,
        strings.orderErrorMsg,
        (order as any).response && (order as any).response.data
          ? JSON.stringify((order as any).response.data)
          : ""
      );
      return false;
    }

    if (order.ok && order.status === 200) {
      return true;
    }
  }

  async changeOrder(
    headers: IServiceHeaders,
    orderId: number,
    status: IOrderStatus
  ) {
    const { data } = await this.patch<any>(
      `/orders/respond/${orderId}/${status}`,
      {},
      {
        headers: this.attachHeaders(headers),
      }
    );
    return data;
  }

  async declineOrder(headers: IServiceHeaders, orderId: number) {
    const { data } = await this.get<any>(`/orders/decline/${orderId}`, {
      headers: this.attachHeaders(headers),
    });
    return data;
  }

  async deliveredMeeting(headers: IServiceHeaders, orderId: number) {
    const { data } = await this.patch<any>(
      `/orders/meeting/${orderId}/delivered`,
      {},
      {
        headers: this.attachHeaders(headers),
      }
    );
    return data;
  }

  async submitVideo(
    headers: IServiceHeaders,
    orderId: number,
    path: string,
    url: string
  ) {
    const res = await this.post(
      "/orders/video/product",
      {
        video: path,
        orderId: Number(orderId),
        link: url,
      },
      {
        headers: this.attachHeaders(headers),
      }
    );
    if (!res.ok) {
      this.errorHandler(
        strings.errorTitle,
        strings.error,
        (res as any).response && (res as any).response.data
          ? JSON.stringify((res as any).response.data)
          : ""
      );
      return false;
    }
    return true;
  }

  async uploadOrderVideo(
    headers: IServiceHeaders,
    orderId: number,
    file: File,
    url: string
  ) {
    const path = `orders/${orderId}/${v4()}.mp4`;
    const response = await this.fileUpload(path, file, true, (evt) => {
      console.log(`Uploaded: ${evt.loaded}/${evt.total}`);
    });

    if (response as any) {
      await this.post(
        "/orders/video/product",
        {
          video: path,
          orderId: orderId,
          link: url,
        },
        {
          headers: this.attachHeaders(headers),
        }
      );
    }
  }

  async postReview(
    headers: IServiceHeaders,
    orderId: number,
    rate: number,
    comment: string
  ): Promise<any> {
    const { data } = await this.post<any>(
      `/reviews`,
      {
        orderId,
        review: rate,
        comment: comment,
      },
      {
        headers: this.attachHeaders(headers),
      }
    );

    return data;
  }

  async getOrder(headers: IServiceHeaders, orderId: string) {
    const order = await this.get<IOrderResponse>(
      `/orders/order/${orderId}`,
      {
        headers: this.attachHeaders(headers),
      },
      {}
    );
    return {
      ...order.data,
      talentProfile: {
        ...(order?.data?.talentProfile || {}),
        avatar: order?.data?.talentProfile?.avatar
          ? await this.getS3Url(order?.data?.talentProfile.avatar)
          : "",
      },
      userProfile: {
        ...(order?.data?.userProfile || {}),
        avatar: order?.data?.userProfile?.avatar
          ? await this.getS3Url(order.data.userProfile.avatar)
          : "",
      },
    };
  }

  async getCalculatedPrice(
    headers: IServiceHeaders,
    serviceId: string,
    coupon: string
  ) {
    const order = await this.get<{
      original: number;
      modified: number;
    }>(`/orders/price?serviceId=${serviceId}&coupon=${coupon}`, {
      headers: this.attachHeaders(headers),
      cache: {
        maxAge: 0,
      },
    });

    return {
      ok: order.ok,
      error: (order as any).response && (order as any).response.data,
      data: order.data,
    };
  }

  async createCampaign(headers: IServiceHeaders, values: ICampaignObject) {
    const request = {
      title: values.title,
      companyDescription: values.companyDescription,
      companyAttachments: values.companyAttachments,
      link: values.link,
      utmLink: values.utmLink,
      campaignGoal: values.campaignGoal,
      budget: values.budget,
      influencersSensitive: 0, //??
      interests: values.interests,
      locations: values.locations,
      gender: values.gender,
      audience: values.audience,
      influencersLevel: values.influencersLevel,
      campaignInstructions: values.campaignInstructions,
      campaignLogo: values.campaignLogo,
      influencersVideo: values.influencersVideo,
      postTypes: values.postTypes,
      promoCode: values.promoCode
        ? {
            code: values.promoCode.code,
            value: values.promoCode.discount,
            type: values.promoCode.type,
          }
        : {},
      socialNetworks: values.socialNetworks,
      tags: values.tags,
    };
    await this.post(`/campaigns/campaign`, request, {
      headers: this.attachHeaders(headers),
    });
  }

  buildOrderRequest(payload: IOrder) {
    return {
      ...payload.order,
    };
  }
}
