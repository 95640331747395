import Service from "../service";
import { strings } from "../../localizedStrings";
import { CountryCode } from "libphonenumber-js";
import { countryNames, isoCountry } from "../../helpers/constant";
import timezones from "timezones-list";
import { CurrencyTypes, LocalizationTypes } from "../../context/localization";
import { keyByValue } from "../../pages/TalentWizard/Steps/DisplayStep/displayStep.utils";
import ct from "countries-and-timezones";
import { countries, states } from "../../helpers/countries";
import { currencys } from "../../helpers/currency";
import { languages } from "../../helpers/languages";
import { compact, toLower } from "lodash";
import { ISortBy } from "../service.interface";
import { Social } from "../../components/SocialButton/socialButton";
import { DateTime } from "luxon";
import { ReactComponent as Icon129 } from "../../assets/landing/categories/129.svg";
import { ReactComponent as Icon55 } from "../../assets/landing/categories/55.svg";
import { ReactComponent as Icon116 } from "../../assets/landing/categories/116.svg";
import { ReactComponent as Icon120 } from "../../assets/landing/categories/120.svg";
import { ReactComponent as Icon54 } from "../../assets/landing/categories/54.svg";
import { ReactComponent as Icon118 } from "../../assets/landing/categories/118.svg";
import { ReactComponent as Icon131 } from "../../assets/landing/categories/131.svg";
import { ReactComponent as Icon155 } from "../../assets/landing/categories/155.svg";
import { ReactComponent as Icon119 } from "../../assets/landing/categories/119.svg";
import { ReactComponent as Icon121 } from "../../assets/landing/categories/121.svg";
import { ReactComponent as Icon117 } from "../../assets/landing/categories/117.svg";
import { UserRole } from "../v2/users/users.interface";
import moment from "moment";
import { v4 } from "uuid";
import SettingsPage from "../../pages/Profile/pages/SettingsPage/settingsPage";
import FavoritesPage from "../../pages/Profile/pages/FavoritesPage/favoritesPage";
import OrdersPage from "../../pages/Profile/pages/OrdersPage/ordersPage";
import ProductEditPage from "../../pages/Profile/pages/ProductEditPage/productEditPage";
import WalletPage from "../../pages/Profile/pages/WalletPage/walletPage";
import AvailabilityPage from "../../pages/Profile/pages/AvailabilityPage/availabilityPage";
import TalentOrdersPage from "../../pages/Profile/pages/TalentOrdersPage/talentOrdersPage";
import BillingPage from "../../pages/Profile/pages/BillingPage/billingPage";
import MessagesPage from "../../pages/Profile/pages/MessagesPage/messagesPage";
import PostsPage from "../../pages/Profile/pages/PostsPage/postsPage";
import BrandPage from "../../pages/Profile/pages/BrandPage/brandPage";
// import MessagesPage from "../../pages/Profile/pages/MessagesPage/messagesPage";
// import BillingPage from "../../pages/Profile/pages/BillingPage/billingPage";
// import CampaignPage from "../../pages/Profile/pages/CampaignPage/campaignPage";

export interface IRouteOption {
  routeName: string;
  routeNotification?: number;
  routeNotificationDistance?: string;
  routes: { id: number; name: string }[];
  route: string;
}

export default class ConfigService extends Service {
  getRoutesOption(
    talentsCategory: { id: number; name: string }[],
    country: CountryCode
  ): IRouteOption[] {
    //   const routesOptions = [
    //     {
    //       routeName: strings.home,
    //       routes: [],
    //       route: "/",
    //     },
    //     country === "IL"
    //       ? {
    //           routeName: strings.experiences,
    //           routes: [],
    //           route: "/experiences",
    //         }
    //       : null,
    //     {
    //       routeName: strings.talents,
    //       routes: talentsCategory,
    //       route: "/talents",
    //     },
    //     {
    //       routeName: strings.newsfeed,
    //       routes: [],
    //       route: "/newsfeed",
    //     },
    //   ];
    //
    //   return compact(routesOptions);
    // }
    const routesOptions = [
      {
        routeName: strings.home,
        routes: [],
        route: "",
      },
      // {
      //   routeName: strings.talents,
      //   routes: talentsCategory,
      //   route: "/talents",
      // },
      // {
      //   routeName: strings.caseStudies,
      //   routes: [],
      //   route: "/case-studies",
      // },

    ];

    return compact(routesOptions);
  }

  getSocialNetworkOptions() {
    return [
      Social.instagram,
      Social.youtube,
      Social.tiktok,
      Social.facebook,
      Social.twitter,
      Social.discord,
      Social.twitch,
      Social.linkedIn
    ].map((item, index) => {
      return {
        id: index,
        value: item,
      };
    });
  }

  profileDropdownOptions(
    mode: UserRole,
    history: any,
    logout: () => void
  ): any {
    const options = {
      business: [
        {
          label: "My Campaigns",
          onClick: () => {
            history.push("/business/dashboard");
          },
          type: "link",
        },
        ...this.getSettingsRoutes("business", 0, 0).map((item) => {
          return {
            label: item.routeName,
            onClick: () => {
              history.push(`${item.prefix}${item.route}`);
            },
            type: "link",
          };
        }),
        { type: "separator", label: "", onClick: () => { } },
        {
          label: "Switch to personal account",
          onClick: () => {
            history.push("/");
          },
          color: "#F24462",
          type: "link",
        },
        {
          label: "Logout",
          onClick: () => {
            logout();
            // history.push("/business");
          },
          color: "#F24462",
          type: "link",
        },
      ],
      smb: [
        ...this.getSettingsRoutes("smb", 0, 0).map((item) => {
          return {
            label: item.routeName,
            onClick: () => {
              history.push(`${item.prefix}${item.route}`);
            },
            type: "link",
          };
        }),
        { type: "separator", label: "", onClick: () => { } },
        {
          label: "Switch to personal account",
          onClick: () => {
            history.push("/");
          },
          color: "#F24462",
          type: "link",
        },
        {
          label: "Logout",
          onClick: () => {
            logout();
            // history.push("/business");
          },
          color: "#F24462",
          type: "link",
        },
      ],
      user: [
        ...this.getSettingsRoutes("user", 0, 0).map((item) => {
          return {
            label: item.routeName,
            onClick: () => {
              history.push(`${item.prefix}${item.route}`);
            },
            type: "link",
          };
        }),
        { type: "separator", label: "", onClick: () => { } },
        {
          label: "Switch to business account",
          onClick: () => {
            history.push("/business");
          },
          color: "#F24462",
          type: "link",
        },
        {
          label: "Logout",
          onClick: () => {
            logout();
            // history.push("/business");
          },
          color: "#F24462",
          type: "link",
        },
      ],
      talent: [
        ...this.getSettingsRoutes("talent", 0, 0).map((item) => {
          return {
            label: item.routeName,
            onClick: () => {
              history.push(`${item.prefix}${item.route}`);
            },
            type: "link",
          };
        }),
        { type: "separator", label: "", onClick: () => { } },
        // {
        //   label: "Switch to business account",
        //   onClick: () => {
        //     history.push("/business");
        //   },
        //   color: "#F24462",
        //   type: "link",
        // },
        {
          label: "Logout",
          onClick: () => {
            logout();
            // history.push("/business");
          },
          color: "#F24462",
          type: "link",
        },
      ],
    };
    return options[mode];
  }

  async uploadFileToS3(
    file: File,
    fileType: string,
    path:string,
  ): Promise<{ key: string }> {
    const pathFile = `${path}/${v4()}.${fileType}`;
    const response = await this.fileUpload(path, file,false, (evt) => {
      console.log(`Uploaded: ${evt.loaded}/${evt.total}`);
    });
    if (response) {
      return { key: pathFile };
    }
    return { key: "" };
  }

  getTimeZonesLabel = () => {
    return timezones.map((timezone: { label: any }) => timezone.label);
  };

  getStatesOptions = () => {
    const stateIncludes: string[] = ["United States"];
    const favoriteCountry: string[] = [
      "Worldwide",
      "United States",
      "United Kingdom",
      "Germany",
      "Italy",
      "Hong Kong",
      "Israel",
    ];
    const stateArray = states.reduce((countryOptions, country) => {
      if (stateIncludes.includes(country.country)) {
        const states = country.states.map((state) => {
          return `${country.country} - ${state}`;
        });
        countryOptions.push(...states);
      } else {
        countryOptions.push(country.country);
      }
      return countryOptions;
    }, [] as string[]);
    return [
      ...favoriteCountry,
      ...stateArray.filter((state) => !favoriteCountry.includes(state)),
    ];
  };

  getUserLanguageByCountry(country: string) {
    return (countries as any)[country]?.languages[0] || "en";
  }

  async getGmtByTz(timezone: string) {
    const data = await fetch(
      `https://worldtimeapi.org/api/timezone/${timezone}`
    ).then((response) => response.json());

    return Number(data.utc_offset.split(":")[0]);
  }

  getCurrTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  formatTime = (date: DateTime, withTime: boolean, timezone?: string) => {
    const dateTime = !timezone ? date : date.setZone(timezone);
    return withTime
      ? `${dateTime.toFormat("dd/MM/yyyy t")}`
      : dateTime.toFormat("dd/MM/yyyy");
  };

  getTZByLabel = (label: string) => {
    return timezones.find(
      (timezone: { label: string }) => timezone.label === label
    );
  };

  getLabelByTZ = (tz: string) => {
    return timezones.find(
      (timezone: { tzCode: string }) => timezone.tzCode === tz
    );
  };

  getCurrencyLabels() {
    return Object.values([
      currencys["USD"],
      // currencys["ILS"],
      currencys["HKD"],
    ]).map((currency) => currency);
  }

  getCurrencyLabel(currency: any) {
    return `${currency.name} (${currency.symbol})`;
  }

  avgResponsesText(responsesAvg: number, language: LocalizationTypes) {
    if (responsesAvg > 48) {
      if (language === "ch") {
        return `${strings.avgResponses}${(responsesAvg / 24).toFixed()} ${strings.day
          }`;
      }
      return `${(responsesAvg / 24).toFixed()} ${strings.day} ${strings.avgResponses
        }`;
    }
    if (language === "ch") {
      return `${strings.avgResponses} ${responsesAvg} ${strings.hours}`;
    }
    return `${responsesAvg} ${strings.hours} ${strings.avgResponses}`;
  }

  getLabelCurrencyCode(currencyCode: string) {
    return this.getCurrencyLabel(
      currencys[currencyCode] ? currencys[currencyCode] : currencys["USD"]
    );
  }

  getSupportedCountry() {
    return ["IL", "US", "HK", "PH"];
  }

  getSupportedLanguage() {
    return ["HE", "EN", "CH"];
  }

  getShareText(language: LocalizationTypes, talentName: string) {
    switch (language) {
      case "he":
        return `רוצה להזמין סרטון או פגישה עם ${talentName}?\n עם לטסטוק זה אפשרי!\n הזמינו עכשיו את ${talentName}!\n\n`;
      case "en":
        return `Want to do a video chat with ${talentName} ?\n Want to order a personalized video for someone you love?\n Need a Music/lifestyle/ business tip?\n All of these and more on LetsTok website\n\n`;
      case "ch":
        return `想訂購短片定約 ${talentName} 見面?\n 依家嚮LetsTok 就可以!\n 快啲訂購!\n\n`;

      default:
        return `Want to do a video chat with ${talentName} ?\n Want to order a personalized video for someone you love?\n Need a Music/lifestyle/ business tip?\n All of these and more on LetsTok website\n\n`;
    }
  }

  getSocialNetwork() {
    return {
      [Social.facebook]: strings.facebook,
      [Social.instagram]: strings.instagram,
      [Social.tiktok]: strings.tikTok,
      [Social.twitter]: strings.twitter,
      [Social.youtube]: strings.youtube,
      [Social.twitch]: strings.twitch,
      [Social.discord]: strings.discord,
      [Social.linkedIn]: strings.linkedIn,

    };
  }

  getSocialNetworkURL() {
    return {
      [Social.facebook]: "facebook.com/",
      [Social.instagram]: "instagram.com/",
      [Social.tiktok]: "tiktok.com/",
      [Social.twitter]: "twitter.com/",
      [Social.youtube]: "youtube.com/c/",
      [Social.twitch]: "twitch.tv/",
      [Social.discord]: "discord.gg/",
      [Social.linkedIn]: "linkedin.com/in/",
    };
  }

  getSocialNetworkName() {
    return {
      [Social.facebook]: "facebook",
      [Social.instagram]: "instagram",
      [Social.tiktok]: "tiktok",
      [Social.twitter]: "twitter",
      [Social.youtube]: "youtube",
      [Social.discord]: "discord",
      [Social.twitch]: "twitch",
      [Social.linkedIn]: "linkedIn",
    };
  }

  extractSocialMedia(link: string) {
    const social = this.getSocialNetworkName();

    return Object.entries(social).reduce((social, [key, value]) => {
      if (toLower(link).includes(toLower(value))) {
        return key;
      }
      return social
    }, 'Letstok');
  }

  getCurrencyCodeByLabel(currencyLabel: string) {
    return (
      Object.values(currencys).find((currCurrency: any) => {
        return this.getCurrencyLabel(currCurrency) === currencyLabel;
      }) as any
    ).code;
  }

  getSettingsRoutes = (
    mode: UserRole,
    campaignCount: number,
    servicesCount: number
  ) => {
    const modes = {
      talent: [
        {
          routeName: strings.settings,
          route: "#settings",
          prefix: "/profile",
          routes: [],
          page: <SettingsPage show={false} />,
        },
        // {
        //   routeName: strings.myWallet,
        //   route: "#wallet",
        //   prefix: "/profile",
        //   routes: [],
        //   page: <WalletPage show={false} />,
        // },
        {
          routeName: strings.orders,
          route: "#orders",
          prefix: "/profile",
          routes: [],
          routeNotification: servicesCount,
          routeNotificationDistance: "18%",
          page: <TalentOrdersPage show={false} />,
        },
        // {
        //   routeName: strings.availability,
        //   route: "#availability",
        //   prefix: "/profile",
        //   routes: [],
        //   page: <AvailabilityPage show={false} />,
        // },
        // {
        //   routeName: strings.product,
        //   route: "#products",
        //   prefix: "/profile",
        //   routes: [],
        //   page: <ProductEditPage show={false} />,
        // },

        // {
        //   routeName: strings.campaigns,
        //   route: "#campaigns",
        //   prefix: "/profile",
        //   routes: [],
        //   routeNotificationDistance: "10%",
        //   page: <CampaignPage show={false} />,
        //   routeNotification: campaignCount,
        // },
      ],
      user: [
        {
          routeName: strings.myOrders,
          route: "#orders",
          prefix: "/profile",
          routes: [],
          page: <OrdersPage show={false} />,
        },
        {
          routeName: strings.settings,
          route: "#settings",
          prefix: "/profile",
          routes: [],
          page: <SettingsPage show={false} />,
        },
        {
          routeName: strings.myFavorite,
          route: "#favorite",
          prefix: "/profile",
          routes: [],
          page: <FavoritesPage show={false} />,
        },
      ],
      business: [
        {
          routeName: strings.messages,
          route: "#messaging",
          prefix: "/business/profile",
          routes: [],
          page: <MessagesPage show={false} />,
        },
        {
          routeName: strings.billing,
          route: "#billing",
          prefix: "/business/profile",
          routes: [],
          page: <BillingPage show={false} />,
        },
        {
          routeName: strings.accountDetails,
          route: "#settings",
          prefix: "/business/profile",
          routes: [],
          page: <SettingsPage show={false} />,
        },
      ],
      smb: [
        {
          routeName: strings.dashboard,
          route: "#dashboard",
          prefix: "/business/smb/profile",
          routes: [],
          page: <></>,
        },
        {
          routeName: strings.myPosts,
          route: "#posts",
          prefix: "/business/smb/profile",
          routes: [],
          page: <PostsPage show={true} />,
        },
        {
          routeName: strings.brandSetting,
          route: "#brand",
          prefix: "/business/smb/profile",
          routes: [],
          page: <BrandPage show={false} />,
        },
        {
          routeName: strings.accountSetting,
          route: "#settings",
          prefix: "/business/smb/profile",
          routes: [],
          page: <SettingsPage show={false} />,
        },
        {
          routeName: strings.billing,
          route: "#billing",
          prefix: "/business/smb/profile",
          routes: [],
          page: <BillingPage show={false} />,
        },
      ]
    };

    return modes[mode];
  };

  getLanguagesByCountry(code: CountryCode | string) {
    let countryCode = code;
    if (countryCode === "ch") {
      countryCode = "HK";
    }
    const country = (countries as any)[countryCode];
    if (!country) {
      return null;
    }
    const languageCode = country.languages[0];
    if (!languageCode) {
      return null;
    }
    return (languages as any)[languageCode].native;
  }

  getCountryMapping() {
    return countryNames;
  }
  addPlusToNumber(number: string) {
    if (number && number[0] !== "+") {
      return "+" + number;
    }
    return number;
  }

  getCountryByTzCode(tzCode: string) {
    return ct.getCountryForTimezone(tzCode);
  }

  getCurrencyByCountry(country: string): CurrencyTypes {
    const code = keyByValue(
      this.countryCode(),
      keyByValue(this.getCountryMapping(), country)
    ) as string;
    let currency = "USD";
    if (code) {
      const countryInfo = (countries as any)[code];
      currency = countryInfo.currency.split(",")[0] || "USD";
    } else if ((countries as any)[country]) {
      const countryInfo = (countries as any)[country];
      currency = countryInfo.currency.split(",")[0] || "USD";
    }
    if (["USD", "HKD"].includes(currency)) {
      return currency as CurrencyTypes;
    }
    return "USD";
  }

  getLanguageByCountry(country: string) {
    const code = keyByValue(
      this.countryCode(),
      keyByValue(this.getCountryMapping(), country)
    ) as string;
    let language = "";
    if (code) {
      const countryInfo = (countries as any)[code];
      language = countryInfo.languages[0];
    } else if ((countries as any)[country]) {
      const countryInfo = (countries as any)[country];
      language = countryInfo.languages[0];
    }
    if (language === "zh") {
      return "ch";
    }

    return language ? language : "en";
  }

  getCountryDisplayMapping() {
    return {
      usa: strings.usa,
      israel: strings.israel,
      hongkong: strings.hongkong,
      philipines: strings.philipines,
    };
  }

  getCountryFlags() {
    return {};
  }

  getLanguages() {
    return ["English", "עברית", "中文"];
  }

  countryCodeToName = (code: CountryCode) => {
    return (isoCountry as any)[code] || "usa";
  };

  countryCode = () => {
    return isoCountry;
  };

  countryCodeDisplay = () => {
    return {
      IL: "israel",
      US: "usa",
      PH: "philipines",
      HK: "hongkong",
    };
  };

  getCurrencyName = (currency: CurrencyTypes) => {
    return this.getCurrencyMapping()[currency];
  };

  getPriceByCurrency = (value: number, currency: CurrencyTypes) => {
    return value;
  };

  getCurrencySign = (currency: CurrencyTypes) => {
    return {
      USD: "$US",
      HKD: "$HK",
      ILS: "₪",
    }[currency];
  };

  getMinMaxOfPrice = (currency: CurrencyTypes) => {
    return {
      USD: { low: 10, high: 1500, step: 10 },
      HKD: { low: 10, high: 1500, step: 10 },
      ILS: { low: 50, high: 5000, step: 50 },
    }[currency];
  };

  countryCodeDisplayToName = (code: CountryCode) => {
    const codesDic = {
      IL: "israel",
      US: "usa",
      PH: "philipines",
      HK: "hongkong",
    };
    return (codesDic as any)[code] || "usa";
  };

  getSortMethod(): {
    title: string;
    resource: string;
    order: ISortBy;
  }[] {
    return [
      { title: strings.sortByNew, resource: "profile.updatedAt", order: "ASC" },
      {
        title: strings.sortByLowPrice,
        resource: "talentServicePrices.price",
        order: "ASC",
      },
      {
        title: strings.sortByHighPrice,
        resource: "talentServicePrices.price",
        order: "DESC",
      },
    ];
  }

  getLanguagesMapping(): any {
    return {
      en: "English",
      he: "עברית",
      ch: "中文",
    };
  }

  getLocalLanguage(code: CountryCode) {
    const language =
      (window.navigator as any).userLanguage || window.navigator.language;
    return (
      this.getLanguagesByCountry(code) ||
      this.getLanguagesByCountry(language.split("-")[1]) ||
      "Local"
    );
  }

  getInfluencersCategories() {
    return {
      129: Icon129,
      55: Icon55,
      116: Icon116,
      120: Icon120,
      54: Icon54,
      118: Icon118,
      131: Icon131,
      155: Icon155,
      119: Icon119,
      121: Icon121,
      117: Icon117,
    };
  }

  getRoutesBusiness = () => {
    return [
      { route: "#platform", routeName: "Platform", routes: [] },
      { route: "#ourInfluencers", routeName: "Our influencers", routes: [] },
      { route: "#howItsWork", routeName: "How it works", routes: [] },
      { route: "#caseStudies", routeName: "Case studies", routes: [] },
    ];
  };


  getRoutesBusinessSMB = () => {
    return [
      { route: "#platform", routeName: "Platform", routes: [] },
      { route: "#howItsWork", routeName: "How it works", routes: [] },
      { route: "#price", routeName: "Pricing", routes: [] },
    ];
  };


  getLanguageNativeName(languageCode: string) {
    const language =
      (window.navigator as any).userLanguage || window.navigator.language;
    if (languageCode.toLowerCase() === "ch") {
      return (languages as any)["zh"]?.native;
    }
    return (
      (languages as any)[languageCode.toLowerCase()]?.native ||
      this.getLanguagesByCountry(language.split("-")[1])
    );
  }

  getLocalLanguageByLS(country: CountryCode) {
    const language = window.localStorage.localization;
    const languages = this.getLanguagesMapping();
    return languages[language] || "Local";
  }

  isEnglishLanguage(code: CountryCode): boolean {
    return this.getLanguagesByCountry(code) === "English";
  }

  isEnglish(code: string): boolean {
    return (code || "").toLowerCase() === "en";
  }

  getCurrencyMapping() {
    return {
      ILS: strings.shekelILS,
      USD: strings.dollarUSD,
      HKD: strings.dollarHKD,
    };
  }

  getDefaultsLocalization(code: CountryCode) {
    switch (code) {
      case "US": {
        return {
          language: this.getLanguagesMapping()["en" as LocalizationTypes],
          currency: this.getCurrencyName("USD"),
        };
      }
      case "IL": {
        return {
          language: this.getLanguagesMapping()["he" as LocalizationTypes],
          currency: this.getCurrencyName("ILS"),
        };
      }
      case "HK": {
        return {
          language: this.getLanguagesMapping()["ch" as LocalizationTypes],
          currency: this.getCurrencyName("HKD"),
        };
      }
      case "PH": {
        return {
          language: this.getLanguagesMapping()["en" as LocalizationTypes],
          currency: this.getCurrencyName("USD"),
        };
      }
      default: {
        return null;
      }
    }
  }

  async getLocalization(): Promise<CountryCode> {
    const { data } = await this.get<{ country: CountryCode }>("/localization");
    return data?.country || "US";
  }

  async getTimeOptionsView() {
    const timeOptions = [
      strings.hour4,
      strings.hour8,
      strings.hour12,
      strings.day1,
      strings.day2,
      strings.day3,
      strings.week,
    ];

    return timeOptions;
  }

  async getTimeOptions() {
    const timeOptions = ["4H", "8H", "12H", "1DAY", "2DAY", "3DAY", "1WEEK"];

    return timeOptions;
  }

  getSocialUrl(country: CountryCode) {
    if (country === "HK") {
      return {
        facebook: "https://www.facebook.com/letstok.hongkong/",
        instagram: "https://www.instagram.com/letstok.hongkong/",
        twitter: "",
        mail: "mailto:info@letstok.com",
        linkedIn: "https://www.linkedin.com/company/letstok.com/",
      };
    }
    return {
      facebook: "https://www.facebook.com/LetsTok.USA",
      instagram:
        "https://www.instagram.com/letstokofficial/?utm_medium=copy_link",
      twitter: "",
      mail: "mailto:info@letstok.com",
      linkedIn: "https://www.linkedin.com/company/letstok.com/",
    };
  }
  getOrderOptions() {
    const orderOptions = [
      strings.personalVideo,
      strings.personalMeetingType,
      // strings.businessMeetingType,
      // strings.businessVideoType,
    ];
    return orderOptions;
  }
}
