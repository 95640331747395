const firebaseConfig = {
    apiKey: "AIzaSyCKaWve82PNYsH98S1tUjExzNCsGQcvxzo",
    authDomain: "letstok-292414.firebaseapp.com",
    projectId: "letstok-292414",
    storageBucket: "letstok-292414.appspot.com",
    messagingSenderId: "87311796634",
    appId: "1:87311796634:web:96ba69683c631799169223",
    measurementId: "G-YXTPEQ57SN",
  };
  
  export default firebaseConfig;