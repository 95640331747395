import classNames from "classnames";
import { compact, isEmpty, omit, uniqBy } from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../context/localization";
import { ServicesContext } from "../../context/services";
import { strings } from "../../localizedStrings";
import { validUrl } from "../../pages/Authentication/authentication.helpers";
import { keyByValue } from "../../pages/TalentWizard/Steps/DisplayStep/displayStep.utils";
import CloseButton from "../CloseButton/closeButton";
import Input from "../Input/input";
import Select from "../Select/select";
import { Social } from "../SocialButton/socialButton";
import "./socialInput.scss";

export interface SocialInputsProps {
  values: { platform: Social | null; url: string }[];
  requiredPlatform?: Social[];
  valid?: { valid: boolean; msg: string };
  onChange: (
    value: {
      platform: Social | null;
      url: string;
    }[]
  ) => void;
}

const SocialInputs = ({
  onChange,
  values,
  valid,
  requiredPlatform,
}: SocialInputsProps) => {
  const { isRTL } = useContext(LocalizationContext);
  const [socialForm, setSocialForm] = useState<
    { platform: Social | null; url: string }[]
  >(isEmpty(values) ? [{ platform: null, url: "" }] : values);

  return (
    <div className={classNames("social-inputs", { ltr: !isRTL })}>
      {socialForm.map((socialType, index) => (
        <SocialInput
          platform={socialType.platform}
          url={socialType.url}
          requiredPlatform={requiredPlatform || []}
          types={compact(socialForm.map((item) => item.platform))}
          onRemove={() => {
            const tmpSocialForm = socialForm;
            tmpSocialForm[index] = {
              platform: null,
              url: "",
            };
            const newForm = uniqBy([...tmpSocialForm], "platform") as {
              platform: Social | null;
              url: string;
              followers: number | null;
            }[];
            onChange(
              newForm.filter((social) => {
                return (
                  requiredPlatform?.includes(social.platform as any) ||
                  (social.platform && social.url)
                );
              })
            );
            setSocialForm(
              newForm.filter((social) => {
                return (
                  requiredPlatform?.includes(social.platform as any) ||
                  (social.platform && social.url)
                );
              }).length > 0
                ? newForm.filter((social) => {
                    return (
                      requiredPlatform?.includes(social.platform as any) ||
                      (social.platform && social.url)
                    );
                  })
                : newForm
            );
          }}
          onChange={({ platform, url }) => {
            const tmpSocialForm = socialForm;
            tmpSocialForm[index] = {
              platform,
              url,
            };
            const newForm = uniqBy([...tmpSocialForm], "platform") as {
              platform: Social | null;
              url: string;
              followers: number | null;
            }[];
            onChange(
              newForm.filter((social) => {
                return social.platform && social.url;
              })
            );
            setSocialForm(newForm);
          }}
        />
      ))}
      <div className={classNames("error-continer", { ltr: !isRTL })}>
        {!valid?.valid && valid?.msg ? (
          <div className="error-info">{`* ${valid?.msg}`}</div>
        ) : (
          <> </>
        )}
      </div>

      {compact(socialForm.map((item) => item.platform)).length < 8 ? (
        <div
          className="add-label"
          onClick={() => {
            setSocialForm((form) => form.concat([{ platform: null, url: "" }]));
          }}
        >
          {strings.newSocial}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const SocialInput = ({
  platform,
  url,
  types,
  onChange,
  onRemove,
  requiredPlatform,
}: {
  types: Social[];
  onRemove: () => void;
  url: string;
  platform: Social | null;
  requiredPlatform: Social[];

  onChange: ({ platform, url }: { url: string; platform: Social }) => void;
}) => {
  const { isRTL, localization } = useContext(LocalizationContext);
  const { configService } = useContext(ServicesContext);
  const [socialOptions, setSocialOptions] = useState<string[]>([]);
  const [socialValue, setSocialValue] = useState<string>("");

  const getSocialNetwork = useCallback(() => {
    return omit(configService.getSocialNetwork(), types) as any;
  }, [configService, types]);

  useEffect(() => {
    setSocialOptions(Object.values(getSocialNetwork()));
  }, [localization, configService, getSocialNetwork]);

  useEffect(() => {
    setSocialValue(configService.getSocialNetwork()[platform as Social]);
  }, [configService, getSocialNetwork, localization, setSocialValue, platform]);

  return (
    <div className={classNames("social-inputs-container", { ltr: !isRTL })}>
      <div className="social-network">
        {!requiredPlatform.includes(
          keyByValue(configService.getSocialNetwork(), socialValue) as Social
        ) ? (
          <CloseButton
            onClick={() => {
              onRemove();
            }}
          />
        ) : (
          <></>
        )}
        <Select
          value={socialValue}
          maxHeight={"140px"}
          options={compact([socialValue, ...socialOptions])}
          parseText={(val: any) => val}
          onChange={(val) => {
            onChange({
              platform: keyByValue(
                configService.getSocialNetwork(),
                val
              ) as Social,
              url: url,
            });
          }}
          customClass={"profile-input"}
          label={strings.social}
        />
      </div>
      <div className="social-link">
        <Input
          customClass={"profile-input"}
          value={url}
          validate={validUrl}
          validCheck={false}
          preText={""}
          valid={{ valid: true, msg: "" }}
          onChange={(value, valid) => {
            onChange({
              platform: keyByValue(
                configService.getSocialNetwork(),
                socialValue
              ) as Social,
              url: value,
            });
          }}
          type={"url"}
          required={false}
          label={strings.link}
        />
      </div>
    </div>
  );
};

export default SocialInputs;
