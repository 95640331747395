/* eslint-disable jsx-a11y/alt-text */
import "./select.scss";
import classnames from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import { LocalizationContext } from "../../context/localization";
import classNames from "classnames";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as IconArrow } from "../../assets/IconArrow.svg";

import { toLower, uniqBy } from "lodash";
import { useOnClickOutside } from "../../helpers/clickOutside";
import { strings } from "../../localizedStrings";

export interface ISelectProps {
  customClass?: string;
  options?: any[];
  value?: any;
  values?: { value: string; id: string }[];
  onValueChange?: (val: { value: string; id: string }[]) => void;
  onChange?: (val: string, index: number) => void;
  parseText?: (text: string) => string;
  assets?: { [key: string]: any };
  label: string;
  required?: boolean;
  chips?: boolean;
  placeholder?: string;
  maxHeight?: string;
  valid?: { valid: boolean; msg: string };
  errorBorder?: boolean;
}

const Select = ({
  label = "",
  value = "",
  values = [],
  chips,
  onValueChange = () => {},
  options = [],
  onChange = () => {},
  assets = {},
  parseText = (text: string) => text,
  customClass,
  placeholder = "",
  required = false,
  maxHeight,
  valid,
  errorBorder,
}: ISelectProps) => {
  const { isRTL } = useContext(LocalizationContext);
  const [isOpen, setOpen] = useState(false);
  const [filter, setFilter] = useState(value);
  const [fillerValue, setFillerValue] = useState<any[]>([]);
  const dropDownRef = useRef(null);
  const inputRef = useRef(null);
  const inputTextRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFilter(value);
  }, [value]);

  useEffect(() => {
    if (isOpen && inputTextRef.current) {
      inputTextRef.current.focus();
      setFilter("");
    }
  }, [isOpen]);

  useEffect(() => {
    setFillerValue(
      options.filter((option) => {
        if (filter.length > 1) {
          return toLower(option).includes(toLower(filter));
        }
        return true;
      })
    );
  }, [options, filter]);

  useOnClickOutside([inputRef, dropDownRef], () => {
    if (isOpen) {
      setOpen(false);
    }
  });
  return (
    <div
      ref={inputRef}
      className={classnames("select-container", customClass, { ltr: !isRTL })}
    >
      <div className="label-container">
        <label className="label">{label}</label>
        {required ? <div className="label-required">{"*"}</div> : <></>}
      </div>

      <div
        onClick={() => {
          setOpen(!isOpen);
        }}
        className={classnames("select", {
          "error-border": valid && !valid.valid && errorBorder,
        })}
      >
        <div className="input-text-container">
          {chips ? (
            <></>
          ) : assets[value] ? (
            <div className="input-asset">
              <img src={assets[value]} />
            </div>
          ) : (
            <></>
          )}
          {chips ? (
            <>
              {values.map((option) => {
                return (
                  <div className="chips-container">
                    <div className="chips-text">{option.value}</div>
                    <div
                      className="chips-close"
                      onClick={(e) => {
                        onValueChange(
                          values.filter(
                            (arrayValue) => arrayValue.id !== option.id
                          )
                        );
                        e.stopPropagation();
                      }}
                    >
                      <CloseSvg />
                    </div>
                  </div>
                );
              })}
            </>
          ) : !isOpen ? (
            <div className={classnames("text", { placeholder: !value })}>
              {value ? parseText(value) : placeholder}
            </div>
          ) : (
            <input
              onChange={(event) => {
                setFilter(event.target.value);
              }}
              ref={inputTextRef}
              value={filter}
              placeholder={placeholder}
              className="input"
            ></input>
          )}
        </div>

        <div
          onClick={() => {}}
          className={classNames("toggle", {
            open: isOpen,
          })}
        >
          <IconArrow transform="scale (-0.5, 0.5)" transform-origin="center" />
        </div>
      </div>
      {isOpen && fillerValue.length ? (
        <div className={classnames("select-panel", { open: isOpen })}>
          <div
            ref={dropDownRef}
            className="select-options"
            style={{ maxHeight: maxHeight ? maxHeight : "150px" }}
          >
            {fillerValue.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    onChange(item, index);
                    onValueChange(uniqBy([...(values || []), item], "id"));
                    setOpen(!isOpen);
                  }}
                  className="option"
                >
                  {chips ? parseText(item.value) : parseText(item)}
                  {assets[item] ? (
                    <div className="input-asset">
                      <img src={assets[item]} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>
          {/* <div className="scroll-down">
            <div
              onClick={() => {
                if (dropDownRef.current) {
                  (dropDownRef as any).current.scrollBy({
                    top: 50,
                    left: 0,
                    behavior: "smooth",
                  });
                }
              }}
              className={classNames("scroll")}
            >
              <IconArrow
                transform="scale (-0.5, 0.5)"
                transform-origin="center"
              />
            </div>
          </div> */}
        </div>
      ) : isOpen ? (
        <div className={classnames("select-panel", { open: isOpen })}>
          <div
            ref={dropDownRef}
            className="select-options"
            style={{ maxHeight: maxHeight ? maxHeight : "150px" }}
          >
            <div className="option">{strings.notFound}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Select;
