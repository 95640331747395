import "./filesExamples.scss";
import { Modal } from "rsuite";
import CloseButton from "../../CloseButton/closeButton";
import { useMediaQuery } from "@react-hook/media-query";
import { strings } from "../../../localizedStrings";
import VideoCard from "../../VideoCarousel/VideoCard/videoCard";

export interface IFilesExamplesProps {
  dismiss: () => void;
  show: boolean;
  videosUrls: string[];
}

const FilesExamples = ({ dismiss, show, videosUrls }: IFilesExamplesProps) => {
  const mobileView = useMediaQuery("(max-width: 600px)") as boolean;

  return (
    <Modal
      size="xs"
      style={{
        margin: mobileView ? 0 : "30px auto 0 auto",
        maxWidth: mobileView ? "100vw" : "600px",
        minWidth: mobileView ? "100vw" : "600px",
      }}
      dialogClassName="file-examples-modal"
      backdropClassName={"file-examples-backdrop"}
      backdrop={true}
      show={show}
      onHide={() => {}}
    >
      <CloseButton
        onClick={() => {
          dismiss();
        }}
      />
      <div className="title">{strings.fileExamples}</div>
      <div className="examples-continer">
        {videosUrls.map((url, index) => {
          return <VideoCard url={url} key={index} />;
        })}
      </div>

      <div className="close" onClick={dismiss}>
        {strings.close}
      </div>
    </Modal>
  );
};

export default FilesExamples;
