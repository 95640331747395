import { flatten, isEmpty, toLower } from "lodash";
import { ICampaignObject } from "../../../pages/Business/CampaignGenerator/CampaignForm/campaignForm";
import Service from "../../service";
import { IServiceHeaders } from "../../service.interface";
import { IOrder, ITalentsSearch } from "../search/search.interfaces";
import { IProfile } from "../users/users.interface";

export interface IOrganization {
  id?: number;
  name?: string;
  description?: string;
  logo?: string;
  website?: string;
}

export interface IExposureInformation {
  budget: number;
  influencerLevel: number;
  socialNetworks: string[];
  type: string;
  audience: [number, number];
  gender: string;
  interests: string[];
}

export interface ICaseStudies {
  id: number;
  description: string;
  bgImage: string;
  logo: string;
  title: string;
}

export interface ICaseStudy {
  id: number;
  description: string;
  bgImage: string;
  logo: string;
  title: string;
  samples: {
    id: number;
    talentName: string;
    country: string;
    uploadDate: string;
    views: number;
    likes: number;
    comments: number;
    postLink: string;
    videoLink: string;
    caseStudyId: number;
  }[];
}

export interface ICampaignResponse {
  campaign: ICampaignObject & { id: string; organizationId: number };
  accepted: number | IOrder[];
  exposure: {
    engagements: number[];
    impressions: number[];
  };
  offered: number | IOrder[];
  submitted: number | IOrder[];
}

export default class OrganizationsService extends Service {
  async getOwnOrganizations(headers: IServiceHeaders) {
    const organizations = await this.get<IOrganization[]>(
      "/organizations/own",
      {
        headers: this.attachHeaders(headers),
      },
      {}
    );
    return organizations.data;
  }

  async submitCampaign(
    headers: IServiceHeaders,
    campaignId: string,
    organizationId: string
  ) {
    const campaign = await this.get<ICampaignObject>(
      `/campaigns/submit/${organizationId}/${campaignId}`,
      {
        headers: this.attachHeaders(headers),
      }
    );
    return campaign.data;
  }

  async getTalentCampaignById(headers: IServiceHeaders, campaignId: string) {
    const campaign = await this.get<
      ICampaignObject & {
        id: string;
        organizationId: number;
      }
    >(`/campaigns/offered/${campaignId}`, {
      headers: this.attachHeaders(headers),
      cache: {
        maxAge: 0,
      },
    });
    return campaign.data;
  }

  async getCampaignById(
    headers: IServiceHeaders,
    campaignId: string,
    organizationId: string
  ) {
    const campaign = await this.get<ICampaignResponse>(
      `/campaigns/single/${organizationId}/${campaignId}`,
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
      },
      {}
    );
    if (isEmpty(campaign.data)) {
      return {} as ICampaignResponse;
    }
    return Object.assign(campaign.data, {
      campaign: {
        ...campaign.data.campaign,
        companyAttachments: !isEmpty(campaign.data.campaign?.companyAttachments)
          ? await Promise.all(
              campaign.data.campaign.companyAttachments.map(this.getS3Url)
            )
          : [],
        campaignLogo: campaign.data.campaign?.campaignLogo
          ? await this.getS3Url(campaign.data.campaign?.campaignLogo)
          : "",
        influencersVideo: campaign.data.campaign?.influencersVideo
          ? await this.getS3Url(campaign.data.campaign?.influencersVideo)
          : "",
      },
    });
  }

  async getOwnCampaigns(headers: IServiceHeaders) {
    const organizations = await this.get<any>(
      "/organizations/own/campaigns",
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
      },
      {}
    );
    return flatten(
      Object.keys(organizations.data).map((key) => organizations.data[key])
    ) as ICampaignResponse[];
  }

  async getCaseStudies(headers: IServiceHeaders) {
    const caseStudies = await this.get<ICaseStudies[]>(
      "/campaigns/case-studies",
      {
        headers: this.attachHeaders(headers),
      },
      []
    );
    return Promise.all(
      caseStudies.data.map(async (item) =>
        Object.assign(item, {
          bgImage: await this.getS3Url(item.bgImage),
          logo: await this.getS3Url(item.logo),
        })
      )
    );
  }

  async getCaseStudy(
    headers: IServiceHeaders,
    id: string
  ): Promise<ICaseStudy> {
    const caseStudy = await this.get<ICaseStudy>(
      `/campaigns/case-study/${id}`,
      {
        headers: this.attachHeaders(headers),
      },
      {}
    );
    return Object.assign(caseStudy.data, {
      samples: await Promise.all(
        caseStudy.data.samples.map(async (item) => {
          return Object.assign(item, {
            videoLink: await this.getS3Url(item.videoLink),
          });
        })
      ),
    });
  }

  async createOrganization(headers: IServiceHeaders, organizationName: string) {
    const organizations = await this.post<IOrganization>(
      "/organizations/create",
      {
        name: organizationName,
        description: "",
        logo: "",
        website: "",
      },
      {
        headers: this.attachHeaders(headers),
      }
    );
    return organizations.data;
  }

  async updateOrganization(
    headers: IServiceHeaders,
    organizationId: number,
    organizationName: string
  ) {
    const organizations = await this.patch<IOrganization>(
      `/organizations/patch/${organizationId}`,
      {
        name: organizationName,
      },
      {
        headers: this.attachHeaders(headers),
      }
    );
    return organizations.data;
  }

  async getStatistics(
    headers: IServiceHeaders,
    exposure: IExposureInformation
  ) {
    const statisticsResponse = await this.post<{
      exposure: {
        views: number;
        likes: number;
        posts: number;
      };
      talents: ITalentsSearch[];
    }>(
      "/campaigns/exposure",
      {
        budget: exposure.budget,
        influencerLevel: exposure.influencerLevel,
        socialNetworks: (exposure.socialNetworks || []).map(toLower),
        type: exposure.type,
        audience: exposure.audience,
        gender: exposure.gender,
        interests: exposure.interests || [],
      },
      {
        headers: this.attachHeaders(headers),
        cache: {
          maxAge: 0,
        },
      },
      { exposure: { views: 0, likes: 0, budget: 0, posts: 0 }, talents: [] }
    );

    const likes = Math.round(
      (statisticsResponse.data.exposure.likes / 50000) * 100
    );
    const views = Math.round(
      (statisticsResponse.data.exposure.views / 200000) * 100
    );
    return {
      ...statisticsResponse.data.exposure,
      views: views > 100 ? 100 : views,
      likes: likes > 100 ? 100 : likes,
      budget: exposure.budget,
      talents: statisticsResponse.data.talents,
    };
  }

  async editCampaign(
    headers: IServiceHeaders,
    campaign: ICampaignObject,
    campaignId: string,
    organizationId: string
  ) {
    const campaignEdited = await this.patch<ICampaignObject>(
      `campaigns/patch/${organizationId}/${campaignId}`,
      campaign,
      {
        headers: this.attachHeaders(headers),
      }
    );
    return campaignEdited.data;
  }

  async createCampaign(
    headers: IServiceHeaders,
    campaign: ICampaignObject,
    profile: IProfile
  ) {
    const organizations = await this.getOwnOrganizations(headers);
    let organization: IOrganization | null = null;
    if (isEmpty(organizations)) {
      organization = await this.createOrganization(headers, profile.fullName);
    } else {
      organization = organizations[0];
    }
    if (isEmpty(organizations)) {
      return null;
    }
    const campaignCreated = await this.post<
      ICampaignObject & { id: string; organizationId: number }
    >(`campaigns/create/${organization.id}`, campaign, {
      headers: this.attachHeaders(headers),
    });
    return campaignCreated.data;
  }

  async getInterests(headers: IServiceHeaders) {
    return (
      await this.get<string[]>(
        "/campaigns/interests",
        {
          headers: this.attachHeaders(headers),
        },
        []
      )
    ).data.map((item, index) => {
      return {
        id: String(index),
        value: item,
      };
    });
  }
}
