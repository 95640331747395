import * as React from "react";

export const useLazyEffect: typeof React.useEffect = (cb, dep) => {
  const initializeRef = React.useRef<boolean>(false);

  React.useEffect((...args) => {
    if (initializeRef.current) {
      cb(...args);
    } else {
      initializeRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dep);
};

export const useOneEffect: typeof React.useEffect = (cb, dep) => {
  const allReadyCall = React.useRef<boolean>(true);

  React.useEffect((...args) => {
    if (allReadyCall.current) {
      cb(...args);
    } else {
      allReadyCall.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dep);
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}


export  function useWindowPosition() {
  const [scrollPosition, setPosition] = React.useState(0);
  React.useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);
  return scrollPosition;
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
