import React from "react";
import "./index.scss";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import Amplify, { Storage } from "aws-amplify";
import { Credentials } from "@aws-amplify/core";
import { StorageChunkUpload } from "amplify-s3-chunk-upload";
import { awsConfig } from "./config/config.prod";
import TagManager from "react-gtm-module";
import { getFirebaseConfig } from "./context/services";
import firebase from "firebase/app";
import Routes from "./routes";
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';

const tagManagerArgs = {
  gtmId: "GTM-574ZPVK",
};
TagManager.initialize(tagManagerArgs);
ReactGA.initialize('UA-200655158-1');
Amplify.configure(awsConfig);
firebase.initializeApp(getFirebaseConfig());

const storagePlugin = new StorageChunkUpload(awsConfig, Credentials as any);
Storage.addPluggable(storagePlugin);
storagePlugin.configure(awsConfig);
// eslint-disable-next-line no-undef

ReactDOM.render(
  <React.StrictMode>
    <CookieConsent
      style={{ background: "#000", height: "92px", alignItems: "center", justifyContent: 'center' }}
      contentStyle={{
        fontSize: "18px",
        color: "#fff",
        fontWeight: "300",
        textAlign: "center",
        flex: 'none'
      }}
      buttonText='I Accept'
    >
      By using this website, You automatically accept the usage of cookies.  <a href="https://app.letstok.com/PrivacyPolicy">Learn more</a>
    </CookieConsent>
    <Routes />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
